import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";

import Button from "../Button";
import { useCartLimitContext } from "../../state/cart-limit-context";
import { usePaymentPlanContext } from "../../state/payment-plan-context";
import { useCartContext } from "../../state/cart-context";
import { useManageCart } from "../../hooks/useManageCart";

import { useUserUUIDContext } from "../../state/user-id-context";
import { v4 as uuidv4 } from "uuid";
import { formatAmount, calculateCartQuantity } from "../../helpers";

const ProductItem = ({ product, setOpenDialog }) => {
  const { paymentPlan, setPaymentPlan } = usePaymentPlanContext();
  const { itemLimit, setItemLimit } = useCartLimitContext();
  const {
    addToCart,
    loading: addToCartLoading,
    error: addToCartError,
  } = useManageCart();

  const { cart } = useCartContext();

  const { userUUID, setUserUUID } = useUserUUIDContext();

  const [quantity, setQuantity] = useState(1);
  const [addedCartItem, setAddedCartItem] = useState(null);
  const [hovered, setHovered] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (userUUID) return;

    const newUserID = uuidv4();
    const newUserIDStr = JSON.stringify(newUserID);

    setUserUUID(newUserID);

    window.localStorage.setItem("UserID", newUserIDStr);
  }, [setUserUUID, userUUID]);

  return (
    <>
      <div
        className="product-item-container"
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <Link to={`/products/${product.id}`}>
          <div className="product-item">
            <div className="product-image-container">
            <img
              src={
                product.images ? product.images[0].imageUrl : product.imageUrl
              }
              alt={product.title}
              className="product-item-image"
            />
            </div>
          </div>
        </Link>
        <div className="product-overlay-btn">
          {hovered ? (
            paymentPlan && paymentPlan !== "ONETIME" ? (
              <Button
                width={"200px"}
                height={"100%"}
                style={{ bottom: "40px" }}
                disabled={
                  !cart
                    ? false
                    : (calculateCartQuantity(cart) === itemLimit &&
                        itemLimit !== 0) ||
                      product.inventory === 0 ||
                      addToCartLoading
                }
                loading={addToCartLoading}
                className="product-item-cta"
                onClick={async () => {
                  // if (calculateCartQuantity(cart) >= itemLimit) {
                  //   history.push("/buy/my-cart");
                  // }

                  const foundItem = cart
                    ? cart.find((item) => item.product === product.id)
                    : undefined;

                  if (
                    foundItem &&
                    foundItem.quantity + quantity > product.inventory
                  ) {
                    const allowedQty = product.inventory - foundItem.quantity;
                    setQuantity(allowedQty === 0 ? 1 : allowedQty);
                    alert(
                      `You already have "${foundItem.quantity} pcs" of this item in your cart, so maximum quantity allowed for this item is "${allowedQty} pcs".`
                    );
                    return;
                  }

                  const finished = await addToCart(
                    product.id,
                    quantity,
                    userUUID,
                    product.inventory
                  );

                  if (finished) {
                    setAddedCartItem({ product, quantity });
                    setQuantity(1);
                  }
                }}
              >
                + add to cart
              </Button>
            ) : (
              <button
                onClick={
                  paymentPlan === "ONETIME" && cart && cart.length > 0
                    ? () => setOpenDialog(true)
                    : () => history.push("/welcome")
                }
                className="product-item-cta"
              >
                get started >
              </button>
            )
          ) : (
            <>
              <div className="product-item-detail">{product.title}</div>
              <div className="product-item-price">
                ${formatAmount(product.price)}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ProductItem;
