import React, { useEffect, createContext, useContext, useState } from "react";

import { useAuthContext, openUserDropdown } from "../state/auth-context";

const UserUUIDStateContext = createContext(undefined);
const UserUUIDDispatchContext = createContext(undefined);

const UserUUIDContextProvider = ({ children }) => {

  const {
    authState: { userInfo },
  } = useAuthContext();


  const [userUUID, setUserUUID] = useState(
    JSON.parse(window.localStorage.getItem("UserID"))
  );


  useEffect(() => {


    if(userInfo) {
      const UIDStr = JSON.stringify(userInfo.id)
      window.localStorage.setItem("UserID", UIDStr)
      setUserUUID(userInfo.id)
    }

  }, [userInfo])

  return (
    <UserUUIDStateContext.Provider value={userUUID}>
      <UserUUIDDispatchContext.Provider value={setUserUUID}>
        {children}
      </UserUUIDDispatchContext.Provider>
    </UserUUIDStateContext.Provider>
  );
};

export default UserUUIDContextProvider;

export const useUserUUIDContext = () => {
  const userUUID = useContext(UserUUIDStateContext);
  const setUserUUID = useContext(UserUUIDDispatchContext);

  if (userUUID === undefined || setUserUUID === undefined)
    throw new Error(
      "useUserUUIDContext must be used within the UserUUIDContextProvider"
    );

  return { userUUID, setUserUUID };
};
