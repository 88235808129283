import React, { useRef, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

import PageHeader from "../components/PageHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Spinner from "../components/Spinner";
import Button from "../components/Button";
import PageNotFound from "./PageNotFound";
import ShipmentStatusControl from "../components/manage-orders/ShipmentStatusControl";
import { useQueryOrder } from "../hooks/useQueryOrder";
import { formatAmount } from "../helpers";

const ManageOrderDetail = () => {
  const params = useParams();
  const { order, loading, error } = useQueryOrder(params.id);

  const labelRef = useRef(null);
  const invoiceRef = useRef(null);

  const printShippingLabel = useReactToPrint({
    content: () => labelRef.current,
    documentTitle: `Shipping label - ${params.id}`,
  });

  const printInvoice = useReactToPrint({
    content: () => invoiceRef.current,
    documentTitle: `Invoice - ${params.id}`,
  });
  useEffect(() => {
    if(!order || !order.shippingAddress) return
    console.log("shippingAddress", order.shippingAddress);
  }, [order]);

  if (loading) return <Spinner color="grey" height={50} width={50} />;

  if (error) return <h2 className="header--center">{error}</h2>;

  if (!order) return <PageNotFound />;


  const {
    id,
    amount,
    items,
    shippingAddress: {
      firstname,
      lastname,
      address1,
      address2,
      city,
      zipcode,
      phone,
      state
    },
    paymentStatus,
    shipmentStatus,
    createdAt,
  } = order;

  return (
    <>
      <PageHeader pageTitle={"Manage Orders"} />
      <div className="head-page-route">
        <Link to="/">
          <span>Home</span>
        </Link>{" "}
        <FontAwesomeIcon
          className="fa-icons"
          icon={["fas", "chevron-right"]}
          size="xs"
        />{" "}
        <Link to="/admin/manage-orders">
          <span>Manage Orders</span>
        </Link>{" "}
        <FontAwesomeIcon
          className="fa-icons"
          icon={["fas", "chevron-right"]}
          size="xs"
        />{" "}
        Orders Detail{" "}
      </div>

      <div className="page--order-details">
        <div className="order-printing">
          {shipmentStatus === "Upcoming" ? (
            <>
              {/* Shipping label */}
              <div className="order-printing__section">
                <Button
                  width="100%"
                  height="35px"
                  className="btn--orange"
                  onClick={printShippingLabel}
                >
                  Print shipping label
                </Button>

                <div className="print-component">
                  <div className="page shipping-label" ref={labelRef}>
                    <div className="label">
                      <h4 className="header">
                        Recipient: {firstname} {lastname}
                      </h4>
                      <p className="paragraph">{address1},</p>
                      {address2 && <p className="paragraph">{address2}</p>}
                      <p className="paragraph">
                        {city}, {zipcode}
                      </p>
                      <p className="paragraph">Tel: {phone}</p>

                      <p className="paragraph">
                        Order number:{" "}
                        <span className="paragraph--focus">{id}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Invoice */}
              <div className="order-printing__section">
                <Button
                  width="100%"
                  height="35px"
                  className="btn--orange"
                  onClick={printInvoice}
                >
                  Print invoice
                </Button>

                <div className="print-component">
                  <div className="page invoice" ref={invoiceRef}>
                    <h1 className="header--center">NutsInBox</h1>

                    <div className="invoice__head">
                      <h3 className="header">Seller:</h3>
                      <h4 className="header">NutsInBox</h4>
                      <p className="paragraph">3900 W Alameda Ave #1200</p>
                      <p className="paragraph">Burbank</p>
                      <p className="paragraph">CA 91505</p>
                      <p className="paragraph">Tel: 747-201-7711</p>
                    </div>

                    <div className="invoice__head">
                      <h3 className="header">Buyer:</h3>
                      <h4 className="header">
                        {firstname} {lastname}
                      </h4>
                      <p className="paragraph">{address1}</p>
                      {address2 && <p className="paragraph">{address2}</p>}
                      <p className="paragraph">
                        {city} {zipcode}
                      </p>
                      <p className="paragraph">Tel: {phone}</p>
                    </div>

                    <div className="invoice__head">
                      <p className="paragraph">
                        Invoice no:{" "}
                        <span className="paragraph--focus">{id}</span>
                      </p>
                      <p className="paragraph">
                        Invoice date:{" "}
                        <span className="paragraph--focus">
                          {createdAt.toDate().toDateString()}
                        </span>
                      </p>
                    </div>

                    <table className="table invoice__items">
                      <thead className="invoice__header-row">
                        <tr>
                          <th className="table-cell">Item</th>
                          <th className="table-cell" style={{ width: "40%" }}>
                            Description
                          </th>
                          <th className="table-cell">Qty</th>
                          <th className="table-cell">Price</th>
                          <th className="table-cell">Amount</th>
                        </tr>
                      </thead>

                      <tbody>
                        {order.items.map(
                          ({ quantity, item: { title, price } }, i) => (
                            <tr key={i}>
                              <td className="table-cell paragraph--center">
                                {i + 1}
                              </td>
                              <td className="table-cell paragraph--center">
                                {title}
                              </td>
                              <td className="table-cell paragraph--center">
                                {quantity}
                              </td>
                              <td className="table-cell paragraph--center">
                                {formatAmount(price)}
                              </td>
                              <td className="table-cell paragraph--center">
                                {formatAmount(quantity * price)}
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>

                    <h3 className="header">
                      Total amount: ${formatAmount(amount)}
                    </h3>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <h3
              className="header paragraph--focus"
              style={{
                color:
                  shipmentStatus === "Preparing"
                    ? "#f2827f"
                    : shipmentStatus === "Shipped"
                    ? "green"
                    : shipmentStatus === "Delivered"
                    ? "grey"
                    : shipmentStatus === "Canceled"
                    ? "red"
                    : undefined,
              }}
            >
              {shipmentStatus}
            </h3>
          )}
        </div>

        <div className="order-section">
          <h4 className="header">Order ID:</h4>
          <div className="order-section__content">
            <p className="paragraph paragraph--focus">{id}</p>
          </div>
        </div>

        <div className="order-section">
          <h4 className="header">Purchased Items:</h4>
          <div className="order-detail-items-mobile">
            {items.map(({ quantity, item: { id, title, price } }, i) => (
              <div key={id} className="order-section__content">
                <div className="order-item">
                  <div className="order-item-top">
                    <p className="paragraph paragraph--focus odi-number">
                      {i + 1}
                    </p>
                    <p className="paragraph paragraph--focus odi-title">
                      {title}
                    </p>
                    <p className="paragraph paragraph--focus odi-qty-price">
                      {quantity} x {formatAmount(price)}
                    </p>
                  </div>
                  <div className="order-item-bottom">
                    <p className="paragraph paragraph--focus odi-equal">=</p>
                    <p className="paragraph paragraph--focus odi-total">
                      ${formatAmount(quantity * price)}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="order-detail-items-desktop">
            {items.map(({ quantity, item: { id, title, price } }, i) => (
              <div key={id} className="order-section__content">
                <div className="order-item">
                  <p className="paragraph paragraph--focus odi-number">
                    {i + 1}
                  </p>
                  <p className="paragraph paragraph--focus odi-title">
                    {title}
                  </p>
                  <p className="paragraph paragraph--focus odi-qty-price">
                    {quantity} x {formatAmount(price)}
                  </p>
                  <p className="paragraph paragraph--focus odi-equal">=</p>
                  <p className="paragraph paragraph--focus odi-total">
                    ${formatAmount(quantity * price)}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="order-section">
          <h4 className="header">Order amount:</h4>
          <div className="order-section__content">
            <p className="paragraph paragraph--focus">
              ${formatAmount(amount)}
            </p>
          </div>
        </div>

        <div className="order-section">
          <h4 className="header">Delivery address:</h4>
          <div className="order-section__content">
            <div className="order-address">
              <p className="paragraph">
                Recipient name:{" "}
                <span className="paragraph--focus">
                  {firstname} {lastname}
                </span>
              </p>
              <p className="paragraph paragraph--focus">
                {address1}, {address2 ? `${address2},` : ""} {city}, {state}, {zipcode}, Tel:{" "}
                {phone}
              </p>
            </div>
          </div>
        </div>

        <div className="order-section">
          <h4 className="header">Payment status:</h4>
          <div className="order-section__content">
            <p className="paragraph paragraph--focus">
              {paymentStatus || "n/a"}
            </p>
          </div>
        </div>

        <div className="order-section">
          <h4 className="header">Shippment status:</h4>
          <div className="order-section__content">
            <ShipmentStatusControl order={order} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageOrderDetail;
