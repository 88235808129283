import { useAsyncCall } from "./useAsyncCall";
import { firebase } from "../firebase/config";
import { usersRef } from "../firebase";

export const useManageShippingAddress = () => {
  const { loading, setLoading, error, setError } = useAsyncCall();

  const addNewAddress = async (data, userInfo) => {
    try {
      setLoading(true);

      const updateUserInfo = {
        shippingAddresses: userInfo.shippingAddresses
          ? [...userInfo.shippingAddresses, data]
          : [data],
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      };

      await usersRef.doc(userInfo.id).set(updateUserInfo, { merge: true });
      setLoading(false);

      return true;
    } catch (err) {
      const { message } = err;
      setError(message);
      setLoading(false);

      return false;
    }
  };

  const editAddress = async (data, index, userInfo) => {
    try {
      if (!userInfo.shippingAddresses) {
        setError("Sorry, cannot edit the shipping address");
        return false;
      }

      setLoading(true);

      const currentShippingAddresses = userInfo.shippingAddresses;

      currentShippingAddresses[index] = data;

      const updateUserInfo = {
        shippingAddresses: currentShippingAddresses,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      };

      await usersRef.doc(userInfo.id).set(updateUserInfo, { merge: true });
      setLoading(false);

      return true;
    } catch (err) {
      const { message } = err;
      setError(message);
      setLoading(false);

      return false;
    }
  };

  const deleteAddress = async (index, userInfo) => {
    try {
      if (
        !userInfo.shippingAddresses ||
        userInfo.shippingAddresses.length === 0
      ) {
        setError("Sorry, something went wrong!");
        return false;
      }

      setLoading(true);

      const updateUserInfo = {
        shippingAddresses: userInfo.shippingAddresses.filter(
          (_, i) => i !== index
        ),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      };

      await usersRef.doc(userInfo.id).set(updateUserInfo, { merge: true });
      setLoading(false);

      return true;
    } catch (err) {
      const { message } = err;
      setError(message);
      setLoading(false);

      return false;
    }
  };

  return { addNewAddress, editAddress, deleteAddress, loading, error };
};
