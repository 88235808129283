import React, { useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";

import ToggleSection from "../components/toggle-sections/ToggleSection";
import Spinner from "../components/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PageHeader from "../components/PageHeader";
import AccountNav from "../components/nav/AccountNav";
import Button from "../components/Button";
import BackButton from "../components/BackButton";
import PageNotFound from "./PageNotFound";
import { useQueryOrder } from "../hooks/useQueryOrder";
import { useManageSubscription } from "../hooks/useManageSubscription";
import { useManageCart } from "../hooks/useManageCart";
import AlertDialog from "../components/dialogs/AlertDialog";
import { useDialog } from "../hooks/useDialog";
import { useViewCartContext } from "../state/view-cart-context";
import { useUserUUIDContext } from "../state/user-id-context";
import { useCartLimitContext } from "../state/cart-limit-context";
import { usePaymentPlanContext } from "../state/payment-plan-context";
import { formatAmount } from "../helpers";

const OrderDetail = () => {
  const params = useParams();
  const { order, loading, error } = useQueryOrder(params.id);
  const { userUUID, setUserUUID } = useUserUUIDContext();
  const { setItemLimit } = useCartLimitContext();
  const { setPaymentPlan } = usePaymentPlanContext();
  const { setViewCart } = useViewCartContext();

  const history = useHistory();

  const {
    cancel,
    cancelLoading,
    cancelError,
    pause,
    pauseLoading,
    pauseError,
  } = useManageSubscription();

  const { openDialog, setOpenDialog } = useDialog();

  const {
    addToCart,
    loading: addToCartLoading,
    error: addToCartError,
  } = useManageCart();

  const [subscriptionToCancel, setSubscriptionToCancel] = useState(undefined);
  const [subscriptionToPause, setSubscriptionToPause] = useState(undefined);
  const [itemsToUpdate, setItemsToUpdate] = useState(undefined);
  const [orderIdToUpdate, setOrderIdToUpdate] = useState(undefined);

  if (loading) return <Spinner color="gray" height={50} width={50} />;

  if (error) return <h2 className="header--center">{error}</h2>;

  if (!order) return <PageNotFound />;

  const {
    id,
    amount,
    items,
    shippingAddress: {
      fullname,
      address1,
      address2,
      city,
      state,
      zipcode,
      phone,
    },
    paymentStatus,
    shipmentStatus,
    paymentType,
    subscriptionId,
  } = order;

  return (
    <>
      <PageHeader pageTitle={"Order History"} />

      <div className="head-page-route">
        <Link to="/">
          <span>Home</span>
        </Link>{" "}
        <FontAwesomeIcon
          className="fa-icons"
          icon={["fas", "chevron-right"]}
          size="xs"
        />{" "}
        <Link to="/orders/my-orders">
          <span>Order History</span>
        </Link>
        <FontAwesomeIcon
          className="fa-icons"
          icon={["fas", "chevron-right"]}
          size="xs"
        />{" "}
        Order Detail
      </div>

      <div className="account-pages-container">
        <div className="acc-left">
          <div className="acc-mobile">
            <ToggleSection title={"Account Menu"}>
              <AccountNav currentPage={"orders"} />
            </ToggleSection>
          </div>

          <div className="acc-desktop">
            <AccountNav currentPage={"home"} />
          </div>
        </div>

        <div className="acc-right">
          <div className="page--order-details">
            <h2 className="orders-title">order detail</h2>

            <div className="order-section">
              <h4 className="header">Order ID:</h4>
              <div className="order-section__content">
                <p className="paragraph paragraph--focus">{id}</p>
              </div>
            </div>

            <div className="order-section">
              <h4 className="header">Purchased Items:</h4>
              <div className="order-detail-items-mobile">
                {items.map(({ quantity, item: { id, title, price } }, i) => (
                  <div key={id} className="order-section__content">
                    <div className="order-item">
                      <div className="order-item-top">
                        <p className="paragraph paragraph--focus odi-number">
                          {i + 1}
                        </p>
                        <p className="paragraph paragraph--focus odi-title">
                          {title}
                        </p>
                        <p className="paragraph paragraph--focus odi-qty-price">
                          {quantity} x {formatAmount(price)}
                        </p>
                      </div>
                      <div className="order-item-bottom">
                        <p className="paragraph paragraph--focus odi-equal">
                          =
                        </p>
                        <p className="paragraph paragraph--focus odi-total">
                          ${formatAmount(quantity * price)}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="order-detail-items-desktop">
                {items.map(({ quantity, item: { id, title, price } }, i) => (
                  <div key={id} className="order-section__content">
                    <div className="order-item">
                      <p className="paragraph paragraph--focus odi-number">
                        {i + 1}
                      </p>
                      <p className="paragraph paragraph--focus odi-title">
                        {title}
                      </p>
                      <p className="paragraph paragraph--focus odi-qty-price">
                        {quantity} x {formatAmount(price)}
                      </p>
                      <p className="paragraph paragraph--focus odi-equal">=</p>
                      <p className="paragraph paragraph--focus odi-total">
                        ${formatAmount(quantity * price)}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="order-section">
              <h4 className="header">Order amount:</h4>
              <div className="order-section__content">
                <p className="paragraph paragraph--focus">
                  ${formatAmount(amount)}
                </p>
              </div>
            </div>

            <div className="order-section">
              <h4 className="header">Delivery address:</h4>
              <div className="order-section__content">
                <div className="order-address">
                  <p className="paragraph">
                    Recipient name:{" "}
                    <span className="paragraph--focus">{fullname}</span>
                  </p>
                  <p className="paragraph paragraph--focus">
                    {address1}, {address2 ? `${address2},` : ""} {city}, {state}
                    , {zipcode}, Tel: {phone}
                  </p>
                </div>
              </div>
            </div>

            <div className="order-section">
              <h4 className="header">Payment status:</h4>
              <div className="order-section__content">
                <p className="paragraph paragraph--focus">
                  {paymentStatus || "n/a"}
                </p>
              </div>
            </div>

            <div className="order-section">
              <h4 className="header">Shippment status:</h4>
              <div className="order-section__content">
                <p
                  className="paragraph paragraph--focus"
                  style={{
                    color:
                      shipmentStatus === "Upcoming"
                        ? "blue"
                        : shipmentStatus === "Preparing"
                        ? "chocolate"
                        : shipmentStatus === "Shipped"
                        ? "green"
                        : shipmentStatus === "Delivered"
                        ? "grey"
                        : shipmentStatus === "Canceled"
                        ? "red"
                        : undefined,
                  }}
                >
                  {shipmentStatus || "n/a"}
                </p>
              </div>
            </div>
            {paymentType === "SUBSCRIPTION" && shipmentStatus === "Upcoming" ? (
              <div className="order-section">
                <h4 className="header">Manage Subscription:</h4>
                <div className="order-section__content">
                  <div>
                    <Button
                      width={"100%"}
                      height={"40px"}
                      className="order-detail-cancel-btn"
                      style={{ margin: "1rem 0" }}
                      onClick={() => {
                        setOpenDialog(true);
                        setSubscriptionToCancel(subscriptionId);
                      }}
                    >
                      Cancel Subscription
                    </Button>
                  </div>
                  <div>
                    <Button
                      width={"100%"}
                      height={"40px"}
                      className="order-detail-update-btn"
                      style={{ margin: "1rem 0" }}
                      onClick={() => {
                        setItemsToUpdate(order.items);
                        setOrderIdToUpdate(id);
                        setOpenDialog(true);
                      }}
                    >
                      Update Subscription
                    </Button>
                  </div>
                  <div>
                    <Button
                      width={"100%"}
                      height={"40px"}
                      className="order-detail-pause-btn"
                      style={{ margin: "1rem 0" }}
                      onClick={() => {
                        setOpenDialog(true);
                        setSubscriptionToPause(subscriptionId);
                      }}
                    >
                      Pause Subscription
                    </Button>
                  </div>
                </div>
              </div>
            ) : undefined}

            {openDialog && subscriptionToCancel && (
              <AlertDialog
                header="Please confirm CANCEL subscription"
                message={`Do you want to cancel your subscripton?`}
                onCancel={() => {
                  setOpenDialog(false);
                  setSubscriptionToCancel(undefined);
                }}
                onConfirm={async () => {
                  const subscription = await cancel(subscriptionToCancel, id);
                  if (subscription) {
                    setOpenDialog(false);
                    setSubscriptionToCancel(undefined);
                  }
                }}
                loading={cancelLoading}
                error={cancelError}
              />
            )}
            {openDialog && subscriptionToPause && (
              <AlertDialog
                header="Please confirm PAUSE subscription"
                message={`Do you want to pause your subscription?`}
                onCancel={() => {
                  setOpenDialog(false);
                  setSubscriptionToPause(undefined);
                }}
                onConfirm={async () => {
                  const subscription = await pause(subscriptionToPause, id);
                  if (subscription) {
                    setOpenDialog(false);
                    setSubscriptionToPause(undefined);
                  }
                }}
                loading={pauseLoading}
                error={pauseError}
              />
            )}
            {openDialog && itemsToUpdate && (
              <AlertDialog
                header="Please confirm UPDATE subscription"
                message={`Do you want to update your subscription?`}
                onCancel={() => {
                  setOpenDialog(false);
                  setOrderIdToUpdate(undefined);
                  setItemsToUpdate(undefined);
                }}
                onConfirm={async () => {
                  const oldPlan = {
                    type: "SUBSCRIPTION",
                    interval: itemsToUpdate[0].interval,
                  };
                  const oldItemLimit = order.totalQuantity;
                  const oldSubscriptionId = order.subscriptionId;

                  window.localStorage.setItem(
                    "UserID",
                    JSON.stringify(itemsToUpdate[0].user)
                  );
                  window.localStorage.setItem(
                    "selectedPaymentPlan",
                    JSON.stringify(oldPlan)
                  );
                  window.localStorage.setItem(
                    "selecteditemLimit",
                    JSON.stringify(oldItemLimit)
                  );
                  window.sessionStorage.setItem(
                    "subscriptionId",
                    JSON.stringify(oldSubscriptionId)
                  );
                  window.sessionStorage.setItem(
                    "orderIdToUpdate",
                    JSON.stringify(orderIdToUpdate)
                  );

                  setUserUUID(itemsToUpdate[0].user);
                  setPaymentPlan(oldPlan);
                  setItemLimit(oldItemLimit);

                  for (const item of itemsToUpdate) {
                    await addToCart(
                      item.item.id,
                      item.quantity,
                      item.user,
                      item.item.inventory
                    );
                  }

                  setViewCart(true);
                  setOpenDialog(false);
                  setOrderIdToUpdate(undefined);
                  setItemsToUpdate(undefined);

                  history.push("/products");
                  return;
                }}
                loading={addToCartLoading}
                error={addToCartError}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderDetail;
