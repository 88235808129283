import { useState, useEffect } from "react";
import { ordersRef, snapshotToDoc } from "../firebase";

import { useAsyncCall } from "./useAsyncCall";

export const useQueryOrder = (orderId) => {
  const { loading, setLoading, error, setError } = useAsyncCall();
  const [order, setOrder] = useState(null);

  useEffect(() => {
    setLoading(true);

    const unsubscribe = ordersRef.doc(orderId).onSnapshot({
      next: (snapshot) => {
        if (!snapshot.exists) {
          setOrder(null);
          setError("Order not found.");
          setLoading(false);
          return;
        }

        const order = snapshotToDoc(snapshot);

        setOrder(order);
        setLoading(false);
      },
      error: (err) => {
        setError(err.message);
        setOrder(null);
        setLoading(false);
      },
    });

    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { order, loading, error };
};
