import React, { useState, useEffect } from "react";

import { useCartLimitContext } from "../../state/cart-limit-context";
import { useManageCart } from "../../hooks/useManageCart";
import { useCartContext } from "../../state/cart-context";
import { formatAmount, calculateCartQuantity } from "../../helpers";

const DropDownCartItem = ({ cartItem }) => {
  const {
    quantity,
    user,
    item: { id, title, category, price, imageUrl, images, inventory },
  } = cartItem;

  const { cart } = useCartContext();

  const { removeCartItem, addToCart } = useManageCart();

  const [newQuantity, setNewQuantity] = useState(quantity);
  const { itemLimit } = useCartLimitContext();

  const deleteItem = async (cartItem) => {
    await removeCartItem(cartItem.item.id, cartItem.user);
  };

  const updateQuantity = () => {
    if (quantity === newQuantity) return;

    if (newQuantity === 0) {
      deleteItem(cartItem);

      return;
    }

    return addToCart(id, newQuantity - quantity, user, inventory);
  };

  useEffect(() => {
    updateQuantity();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newQuantity]);

  console.log(images)

  return (
    <div className="dropdownitem-container">
      <div className="ddi-content">
        <div className="ddi-left">
          <img src={
            images ? images[0].imageUrl : imageUrl
          } alt={title} className="ddi-img" />
        </div>
        <div className="ddi-right">
          <div className="ddi-detail">
            <div className="ddi-category">{category}</div>
            <div className="ddi-title">{title}</div>
            <div className="ddi-price">${formatAmount(price)}</div>
          </div>
          <div className="ddi-quantity-control">
            <div
              className="ddi-quantity-control-minus"
              onClick={() => {
                setNewQuantity((prev) => {
                  return prev - 1;
                });
              }}
            >
              -
            </div>
            <div className="ddi-quantity-control-qty">{quantity}</div>
            <div className="plus-container">
              {calculateCartQuantity(cart) >= itemLimit ? undefined : (
                <div
                  className="ddi-quantity-control-plus"
                  onClick={() => {
                    setNewQuantity((prev) => {
                      if (prev === inventory) return prev;
                      if (prev === itemLimit) return prev;

                      return prev + 1;
                    });
                  }}
                >
                  +
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DropDownCartItem;
