import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import ToggleSection from "../components/toggle-sections/ToggleSection";
import Spinner from "../components/Spinner";
import Button from "../components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PageHeader from "../components/PageHeader";
import AccountNav from "../components/nav/AccountNav";
import OrderItem from "../components/orders/OrderItem";
import Tab from "../components/Tabs";
import { useOrdersContext } from "../state/orders-context";
import { useSelectTab } from "../hooks/useSelectTab";
import SubscriptionPlanItem from "../components/SubscriptionPlanItem";
import { orderTabs } from "../helpers";

export const orderTabType = "type";

const Orders = () => {
  const {
    ordersState: { orders, loading: orderLoading, error },
  } = useOrdersContext();

  const [activePlans, setActivePlans] = useState(["x"]);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const filterSubscriptions = () => {
    setLoading(true);

    let ordersArr = [];
    let ordersSubIdArr = [];
    let canceledArr = [];

    orders.forEach((order) => {
      if (order.paymentType !== "SUBSCRIPTION") {
        return;
      } else if (
        order.paymentType === "SUBSCRIPTION" &&
        order.shipmentStatus !== "Canceled"
      ) {
        if (!ordersSubIdArr.includes(order.subscriptionId)) {
          ordersArr.push(order);
          ordersSubIdArr.push(order.subscriptionId);
        }
      } else if (
        order.paymentType === "SUBSCRIPTION" &&
        order.shipmentStatus === "Canceled"
      ) {
        canceledArr.push(order);
      }
    });

    canceledArr.forEach((order) => {
      for (let j = 0; j < ordersArr.length; j++) {
        if (ordersArr[j].subscriptionId === order.subscriptionId) {
          ordersArr.splice(j, 1);
        }
      }
    });

    setActivePlans(ordersArr);

    setLoading(false);
  };

  useEffect(() => {
    if (!orders) return;

    filterSubscriptions();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders]);

  if (loading || orderLoading)
    return <Spinner color="gray" height={50} width={50} />;

  return (
    <>
      <PageHeader pageTitle={"Manage Plan"} />

      <div className="head-page-route">
        <Link to="/">
          <span>Home</span>
        </Link>{" "}
        <FontAwesomeIcon
          className="fa-icons"
          icon={["fas", "chevron-right"]}
          size="xs"
        />{" "}
        Manage Plan
      </div>

      <div className="account-pages-container">
        <div className="acc-left">
          <div className="acc-mobile">
            <ToggleSection title={"Account Menu"}>
              <AccountNav currentPage={"plan"} />
            </ToggleSection>
          </div>

          <div className="acc-desktop">
            <AccountNav currentPage={"plan"} />
          </div>
        </div>
        <div className="acc-right">
          <div className="orders-container">
            <div className="orders-title">Manage Plan</div>
            {activePlans[0] !== "x" ? (
              activePlans.map((plan) => {
                return (
                  <SubscriptionPlanItem key={plan.subscriptionId} plan={plan} />
                );
              })
            ) : (
              <h2 className="header--center">No active plan found.</h2>
            )}
          </div>
          <div className="mp-section-new-plan">
            <div className="mp-new-plan-title">Need more? Add new plan!</div>
            <Button
              height={"40px"}
              width={"200px"}
              onClick={() => {
                history.push("/welcome");
              }}
              className="new-plan-btn"
            >
              add new plan
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Orders;
