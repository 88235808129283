import React, {
  createContext,
  useState,
  useEffect,
  useContext,
} from "react";

import Signup from "../components/auth/Signup";
import Signin from '../components/auth/Signin'
import ResetPassword from '../components/auth/ResetPassword'

export const ModalStateContext = createContext(undefined);
export const ModalDispatchContext = createContext(undefined);

const modals = {
  close: null,
  signup: <Signup />,
  signin: <Signin />,
  reset_password: <ResetPassword />,
};

const ModalContextProvider = ({ children }) => {
  const [modal, setModal] = useState(null);
  const [modalType, setModalType] = useState("close");

  useEffect(() => {
    setModal(modals[modalType]);
  }, [modalType]);

  return (
    <ModalStateContext.Provider value={{ modal }}>
      <ModalDispatchContext.Provider value={{setModalType}}>
        {children}
      </ModalDispatchContext.Provider>
    </ModalStateContext.Provider>
  );
};

export default ModalContextProvider;

export const useModalContext = () => {
  const modalState = useContext(ModalStateContext);
  const modalDispatch = useContext(ModalDispatchContext);

  if (modalState === undefined || modalDispatch === undefined)
    throw new Error(
      "useModalContext must be uswed within the ModalContextProvider"
    );

  return { ...modalState, ...modalDispatch };
};
