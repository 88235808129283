import React, {createContext, useContext, useState} from 'react';

const ViewStateContext = createContext(undefined)
const ViewDispatchContext = createContext(undefined)

const ViewContextProvider = ({children}) => {
  const [viewMode, setViewMode] = useState('admin')
  return (
    <ViewStateContext.Provider value={viewMode}>
      <ViewDispatchContext.Provider value={setViewMode}>
        {children}
      </ViewDispatchContext.Provider>
    </ViewStateContext.Provider>
  );
};

export default ViewContextProvider;

export const useViewContext = () => {
  const viewMode = useContext(ViewStateContext)
  const setViewMode = useContext(ViewDispatchContext)

  if (viewMode === undefined || setViewMode === undefined) throw new Error('useViewContext must be used within the ViewContextProvider')

  return {viewMode, setViewMode}
}
