import React from "react";
import { Redirect, useLocation } from "react-router-dom";

import Spinner from "../components/Spinner";
import { useAuthContext } from "../state/auth-context";

const PrivateRoute = ({ children }) => {
  const {
    authState: { authUser, userInfo, authChecked },
  } = useAuthContext();

  const location = useLocation();

  if (!authChecked && (!authUser || !userInfo))
    return <Spinner color="gray" width={50} height={50} />;

  if (authChecked && (!authUser || !userInfo))
    return (
      <Redirect
        to={{
          pathname: "/",
          state: {
            from: location.pathname,
          },
        }}
      />
    );

  return (
    <>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, { userInfo })
      )}
    </>
  );
};

export default PrivateRoute;
