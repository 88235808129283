import React from "react";
import { Link, useLocation } from "react-router-dom";

const Tabs = ({
  label,
  tabType,
  activeTab,
  withPagination,
  resetFiltered,
  selectedFilter,
}) => {
  const { pathname } = useLocation();

  return (
    <Link
      onClick={resetFiltered}
      to={
        withPagination
          ? `${pathname}?${tabType}=${label}&page=1`
          : `${pathname}?${tabType}=${label}`
      }
    >
      {tabType === "type" ? (
        <p
          className={
            label === activeTab ? "selected-order-tab-item" : "order-tab-item"
          }
          style={{ marginRight: "1rem" }}
        >
          {label}
        </p>
      ) : (
        <p
          className={`paragraph ${
            selectedFilter
              ? "cat-tab-item"
              : label === activeTab || activeTab === "allproducts"
              ? "selected-cat-tab-item"
              : "cat-tab-item"
          }`}
          style={{ marginRight: "1rem" }}
        >
          {label === "AllProducts" ? "All" : label}
        </p>
      )}
    </Link>
  );
};

export default Tabs;
