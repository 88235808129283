import React, { createContext, useReducer, useContext, useEffect } from "react";

import { auth } from "../firebase/config";
import { usersRef, snapshotToDoc } from "../firebase";

const AuthStateContext = createContext(undefined);
const AuthDispatchContext = createContext(undefined);

export const fetchAuthUser = (user) => ({
  type: "FETCH_AUTH_USER",
  payload: user,
});

export const openUserDropdown = (open) => ({
  type: "OPEN_USER_DROPDOWN",
  payload: open,
});

export const fetchUserInfo = (userInfo) => ({
  type: "FETCH_USER_INFO",
  payload: userInfo,
});

export const signoutRedirect = (redirect) => ({
  type: "SIGNOUT_REDIRECT",
  payload: redirect,
});

// export const setUserRole = (role) => ({
//   type: "SET_USER_ROLE",
//   payload: role,
// });

export const finishAuthCheck = (checked) => ({
  type: 'FINISH_AUTH_CHECK',
  payload: checked,
})

const authReducer = (state, action) => {
  switch (action.type) {
    case "FETCH_AUTH_USER":
      return {
        ...state,
        authUser: action.payload,
      };

    case "OPEN_USER_DROPDOWN":
      return {
        ...state,
        isUserDropdownOpen: action.payload,
      };

    case "FETCH_USER_INFO":
      return {
        ...state,
        userInfo: action.payload,
      };

    case "SIGNOUT_REDIRECT":
      return {
        ...state,
        signoutRedirect: action.payload,
      };

    // case "SET_USER_ROLE":
    //   return {
    //     ...state,
    //     userRole: action.payload,
      // };

    case 'FINISH_AUTH_CHECK':
      return {
        ...state,
        authChecked: action.payload,
      }

    default:
      return state;
  }
};

const initialState = {
  authUser: null,
  isUserDropdownOpen: false,
  userInfo: null,
  signoutRedirect: false,
  // userRole: null,
  authChecked: false,
};

const AuthContextProvider = ({ children }) => {
  const [authState, authDispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        // user.getIdTokenResult().then(result => {
        //   const role = result.claims.role
        //   authDispatch(setUserRole(role))
        // }).catch(() => authDispatch(setUserRole(null)))
        authDispatch(fetchAuthUser(user))
      } else {
        authDispatch(fetchAuthUser(null));
        // authDispatch(setUserRole(null));

      };

      authDispatch(finishAuthCheck(true))
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (!authState.authUser) return authDispatch(fetchUserInfo(null));

    const unsubscribe = usersRef.doc(authState.authUser.uid).onSnapshot({
      next: (doc) => {
        if (!doc.exists) return authDispatch(fetchUserInfo(null));

        const userInfo = snapshotToDoc(doc);
        authDispatch(fetchUserInfo(userInfo));
      },
      error: () => authDispatch(fetchUserInfo(null)),
    });

    return () => unsubscribe();
  }, [authState.authUser]);

  return (
    <AuthStateContext.Provider value={authState}>
      <AuthDispatchContext.Provider value={authDispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};

export default AuthContextProvider;
export const useAuthContext = () => {
  const authState = useContext(AuthStateContext);
  const authDispatch = useContext(AuthDispatchContext);

  if (authState === undefined || authDispatch === undefined)
    throw new Error("useAuthContext must be used within AuthContextProvider");

  return { authState, authDispatch };
};
