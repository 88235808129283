import React, { createContext, useContext, useState } from "react";

const MobileNavStateContext = createContext(undefined);
const MobileNavDispatchContext = createContext(undefined);

const MobileNavContextProvider = ({ children }) => {
  const [mobileNav, setMobileNav] = useState(false);
  return (
    <MobileNavStateContext.Provider value={mobileNav}>
      <MobileNavDispatchContext.Provider value={setMobileNav}>
        {children}
      </MobileNavDispatchContext.Provider>
    </MobileNavStateContext.Provider>
  );
};

export default MobileNavContextProvider;

export const useMobileNavContext = () => {
  const mobileNav = useContext(MobileNavStateContext);
  const setMobileNav = useContext(MobileNavDispatchContext);

  if (mobileNav === undefined || setMobileNav === undefined)
    throw new Error(
      "useMobileNavContext must be used within the MobileNavContextProvider"
    );

  return { mobileNav, setMobileNav };
};
