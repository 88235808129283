import { v4 as uuidv4 } from 'uuid'

import { db, storageRef } from './config'

export const usersRef = db.collection('users')
export const userCountsRef = db.collection('user-counts')
export const productsRef = db.collection('products')
export const productCountsRef = db.collection('product-counts')
export const cartRef = db.collection('cart')
export const ordersRef = db.collection('orders')
export const orderCountsRef = db.collection('order-counts')
export const blogsRef = db.collection('blogs')
export const productImagesFolder = 'products'

export const snapshotToDoc = (doc) => {
  const docData = doc.data()
  const docObject = {
    ...docData,
    id: doc.id,
  }

  return docObject
}

export const createImageRef = (imageName) => {
  const uuid = uuidv4()

  return storageRef.child(`${productImagesFolder}/${imageName}-${uuid}`)
}
