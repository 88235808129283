import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import PageHeader from '../components/PageHeader'
import AdminProductItem from "../components/manage-products/AdminProductItem";
import Button from "../components/Button";
import Spinner from "../components/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddAndEditProduct from "../components/manage-products/AddAndEditProduct";
import AlertDialog from "../components/dialogs/AlertDialog";
import Pagination from "../components/Pagination";
import { useProductsContext } from "../state/products-context";
import { useSearchContext } from "../state/search-context";
import { useManageProduct } from "../hooks/useManageProduct";
import { useDialog } from "../hooks/useDialog";
import { usePagination } from "../hooks/usePagination";

const productsPerPage = 10;

const ManageProducts = () => {
  const [openProductForm, setOpenProductForm] = useState(false);
  const [productToEdit, setProductToEdit] = useState(null);
  const [productToDelete, setProductToDelete] = useState(null);
  const {
    productsState: {
      products,
      loading,
      error,
      productCounts,
      queryMoreProducts,
    },
  } = useProductsContext();
  const { searchedItems } = useSearchContext();
  const { openDialog, setOpenDialog } = useDialog();
  const {
    deleteProduct,
    loading: deleteProdLoading,
    error: deleteProdError,
  } = useManageProduct();
  const { page, totalPages } = usePagination(
    productCounts.All,
    productsPerPage,
    undefined,
    searchedItems
  );

  const [productsByPage, setProductsByPage] = useState(products.All);
  const [paginatedSearchedItems, setPaginatedSearchedItems] =
    useState(searchedItems);

  useEffect(() => {
    const startIndex = productsPerPage * (page - 1);
    const endIndex = productsPerPage * page;

    if (searchedItems) {
      setPaginatedSearchedItems(searchedItems.slice(startIndex, endIndex));
      setProductsByPage([]);
    } else {
      // Check if we need to query more products
      if (
        products.All.length < productCounts.All &&
        products.All.length < productsPerPage * page
      ) {
        queryMoreProducts();
        return;
      }

      setProductsByPage(products.All.slice(startIndex, endIndex));
      setPaginatedSearchedItems(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products.All, productCounts.All, page, searchedItems]);

  if (loading) return <Spinner color="grey" width={50} height={50} />;

  return (
    <>

<PageHeader pageTitle={'Manage Products'} />
      <div className="head-page-route">
        <Link to="/">
          <span>Home</span>
        </Link>{" "}
        <FontAwesomeIcon
          className="fa-icons"
          icon={["fas", "chevron-right"]}
          size="xs"
        />{" "}
        Manage Products{" "}
      </div>

      <div className="page--manage-products">
        <div className="manage-products__section">
          <Button
            className="add-product-btn"
            width="100%"
            height='35px'
            onClick={() => setOpenProductForm(true)}
          >
            Add new product
          </Button>

          {openProductForm && (
            <AddAndEditProduct
              setOpenProductForm={setOpenProductForm}
              productToEdit={productToEdit}
              setProductToEdit={setProductToEdit}
            />
          )}
        </div>

        {totalPages > 0 && (
          <div className="pagination-container">
            <Pagination page={page} totalPages={totalPages} />
          </div>
        )}

        <div className="manage-products__section">
          {!loading && products.All.length === 0 ? (
            <h2 className="header--center">No products, let's add one.</h2>
          ) : (
            <div className="productListTable">
              <table className="table">
                <thead>
                  <tr>
                    <th className="table-cell">Edit</th>
                    <th className="table-cell">Delete</th>
                    <th className="table-cell">SKU</th>
                    <th className="table-cell">Title</th>
                    <th className="table-cell">Image</th>
                    <th className="table-cell">Price ($)</th>
                    <th className="table-cell table-cell--hide">Created At</th>
                    <th className="table-cell table-cell--hide">Updated At</th>
                    <th className="table-cell">Inventory</th>

                    <th className="table-cell">Servings Per Container</th>
                    <th className="table-cell">Serving Size</th>
                    <th className="table-cell">Calories (Per Serving)</th>
                    <th className="table-cell">Total Fat</th>
                    <th className="table-cell">Saturated Fat</th>
                    <th className="table-cell">Trans Fat</th>
                    <th className="table-cell">Cholesterol</th>
                    <th className="table-cell">Sodium</th>
                    <th className="table-cell">Total Carbohydrate</th>
                    <th className="table-cell">Dietary Fiber</th>
                    <th className="table-cell">Total Sugars</th>
                    <th className="table-cell">Added Sugars</th>
                    <th className="table-cell">Protein</th>
                    <th className="table-cell">Vitamin A</th>
                    <th className="table-cell">Vitamin C</th>
                    <th className="table-cell">Vidamin D</th>
                    <th className="table-cell">Calcium</th>
                    <th className="table-cell">Iron</th>
                    <th className="table-cell">Potassium</th>
                  </tr>
                </thead>

                <tbody>
                  {paginatedSearchedItems ? (
                    <>
                      {paginatedSearchedItems.length < 1 ? (
                        <tr>
                          <td colSpan={6}>
                            <h2 className="header--center">
                              No products found.
                            </h2>
                          </td>
                        </tr>
                      ) : (
                        paginatedSearchedItems.map((product) => (
                          <AdminProductItem
                            key={product.id}
                            product={product}
                            setOpenProductForm={setOpenProductForm}
                            setProductToEdit={setProductToEdit}
                            setOpenDialog={setOpenDialog}
                            setProductToDelete={setProductToDelete}
                          />
                        ))
                      )}
                    </>
                  ) : (
                    productsByPage &&
                    productsByPage.map((product) => (
                      <AdminProductItem
                        key={product.id}
                        product={product}
                        setOpenProductForm={setOpenProductForm}
                        setProductToEdit={setProductToEdit}
                        setOpenDialog={setOpenDialog}
                        setProductToDelete={setProductToDelete}
                      />
                    ))
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>

        {error && <p className="paragraph paragraph--error">{error}</p>}

        {openDialog && (
          <AlertDialog
            header="Please confirm"
            message={`Are you sure you want to delete this ${
              productToDelete ? productToDelete?.title : "item"
            }?`}
            onCancel={() => {
              setProductToDelete(null);
              setOpenDialog(false);
            }}
            onConfirm={async () => {
              if (productToDelete) {
                const finished = await deleteProduct(productToDelete);

                if (finished) setOpenDialog(false);
              }
            }}
            loading={deleteProdLoading}
            error={deleteProdError}
          />
        )}
      </div>
    </>
  );
};

export default ManageProducts;
