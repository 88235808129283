import React from "react";
import { Link, useHistory } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Footer = () => {
  const history = useHistory();

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <div className="footer-logo"></div>
        </div>
        <div className="footer-section">
          <div className="footer-section-title">Products</div>
          <Link to={`/products?cat=Nuts&page=1`}>
            <div className="footer-section-item">Nuts</div>
          </Link>
          <Link to={`/products?cat=Dried%20Fruits&page=1`}>
            <div className="footer-section-item">Dried Fruits</div>
          </Link>
          <Link to={`/products?cat=Seeds&page=1`}>
            <div className="footer-section-item">Seeds</div>
          </Link>
          <Link to={`/products?cat=Mixed%20Nuts&page=1`}>
            <div className="footer-section-item">Mixed Nuts</div>
          </Link>
        </div>
        <div className="footer-section">
          <div className="footer-section-title">Healthy Snacks</div>

          <div
            onClick={() => {
              history.push("/collections/paleoDiet");
              window.location.reload();
            }}
            className="footer-section-item"
          >
            <span>Paleo Diet Friendly</span>
          </div>

          <div
            onClick={() => {
              history.push("/collections/athleteFriendly");
              window.location.reload();
            }}
            className="footer-section-item"
          >
            <span>Athlete Friendly</span>
          </div>

          <div
            onClick={() => {
              history.push("/collections/diabeticFriendly");
              window.location.reload();
            }}
            className="footer-section-item"
          >
            <span>Diabetic Friendly</span>
          </div>

          <div
            onClick={() => {
              history.push("/collections/plantBasedProtein");
              window.location.reload();
            }}
            className="footer-section-item"
          >
            <span>Plant Based Protein</span>
          </div>

          <div
            onClick={() => {
              history.push("/collections/highFiber");
              window.location.reload();
            }}
            className="footer-section-item"
          >
            <span>High Fiber</span>
          </div>

          <div
            onClick={() => {
              history.push("/collections/heartHealthy");
              window.location.reload();
            }}
            className="footer-section-item"
          >
            <span>Heart Healthy</span>
          </div>


          <div
            onClick={() => {
              history.push("/collections/ketoDiet");
              window.location.reload();
            }}
            className="footer-section-item"
          >
            <span>Keto Friendly</span>
          </div>

          <div
            onClick={() => {
              history.push("/collections/unsalted");
              window.location.reload();
            }}
            className="footer-section-item"
          >
            <span>Unsalted</span>
          </div>

        </div>
        <div className="footer-section">
          <div className="footer-section-title">Other Links</div>
          <a href="/blogs">
            <div className="footer-section-item">Blogs</div>
          </a>
          <a href="/contact">
            <div className="footer-section-item">Contact</div>
          </a>
          <a href="https://app.termly.io/document/privacy-policy/dd10f558-3133-4d00-8ec0-b35b8182abf6">
            <div className="footer-section-item">Privacy Policy</div>
          </a>
          <a href="https://app.termly.io/document/terms-of-use-for-ecommerce/327d9a1a-4575-4e47-8103-61eb691c9312">
            <div className="footer-section-item">Terms of Use</div>
          </a>
          <a href="https://app.termly.io/document/disclaimer/ad9fe1b8-0223-4f72-be69-255d5fb89bfe">
            <div className="footer-section-item">Disclaimer</div>
          </a>
          <a href="#">
            <div className="footer-section-item">Cookie Policy</div>
          </a>
          <a href="#">
            <div className="footer-section-item">Return Policy</div>
          </a>

          <div className="follow">
            <div className="footer-section-follow-title">Follow us: </div>
            <div className="footer-section-follow-icons">
              <a
                href="https://www.instagram.com/nutsinbox.official/"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  icon={["fab", "instagram"]}
                  size="1x"
                  color="white"
                  className="footer-follow-icon-item"
                />
              </a>
              <a
                href="https://www.facebook.com/Nutsinboxofficial-105365321613788"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  icon={["fab", "facebook-square"]}
                  size="1x"
                  color="white"
                  className="footer-follow-icon-item"
                />
              </a>
              <a
                href="https://www.youtube.com/channel/UCOAFTTFD8Nv9wrHaNfSXv3w"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  icon={["fab", "youtube-square"]}
                  size="1x"
                  color="white"
                  className="footer-follow-icon-item"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
