import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../Button";
import { useModalContext } from "../../state/modal-context";
// import { firebase } from "../../firebase/config";


const SocialMediaLogin = ({socialLogin, loading}) => {
    const { setModalType } = useModalContext();

    const handleSocialLogin = async (provider) => {
      const response = await socialLogin(provider);

      if (response) setModalType("close");
    };


  return (
    <div className="social">
      <Button
        className="social-btn social-btn--google"
        width="100%"
        height="3rem"
        onClick={() => handleSocialLogin("google")}
        disabled={loading}
      >
        <FontAwesomeIcon icon={["fab", "google"]} size="1x" />
        <span>Log in with Google</span>
      </Button>
    </div>
  );
};

export default SocialMediaLogin;
