import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";

import ToggleSection from "../components/toggle-sections/ToggleSection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PageHeader from "../components/PageHeader";
import AccountNav from "../components/nav/AccountNav";
import ProductCarousel from "../components/carousel/ProductCarousel";
import { useProductsContext } from "../state/products-context";

const MyAccount = () => {
  const {
    productsState: { products },
  } = useProductsContext();

  const [viewTab, setViewTab] = useState("foryou");

  return (
    <>
      <PageHeader pageTitle={"My Account"} />

      <div className="head-page-route">
        <Link to="/">
          <span>Home</span>
        </Link>{" "}
        <FontAwesomeIcon
          className="fa-icons"
          icon={["fas", "chevron-right"]}
          size="xs"
        />{" "}
        My Account
      </div>

      <div className="account-pages-container">
        <div className="acc-left">
          <div className="acc-mobile">
            <ToggleSection title={"Account Menu"}>
              <AccountNav currentPage={"home"} />
            </ToggleSection>
          </div>

          <div className="acc-desktop">
            <AccountNav currentPage={"home"} />
          </div>
        </div>
        <div className="acc-right">
          <div className="viewTabs">
            <button
              className={
                viewTab === "foryou"
                  ? "selected-acc-view-tab acc-view-tab"
                  : "acc-view-tab"
              }
              onClick={() => setViewTab("foryou")}
            >
              For you
            </button>
            <button
              className={
                viewTab === "all"
                  ? "selected-acc-view-tab acc-view-tab"
                  : "acc-view-tab"
              }
              onClick={() => setViewTab("all")}
            >
              all items
            </button>
          </div>

          <div className="my-account-banner"></div>

          {viewTab === "foryou" ? (
            <>
              <ProductCarousel
                title={"Our Recommendations"}
                description={
                  "Based on what you have ordered before we come up with the best recommendations."
                }
                items={products.Seeds}
              />
            </>
          ) : (
            <>
              <ProductCarousel
                title={"Nuts"}
                description={
                  "From vital superfoods like almonds, cashews and walnuts - to indulgent delights like fried wasabi peas and our Japanese Cajun peanuts - these nuts cover all the bases. Whether you’re at the ball game, hosting game night with friends or enjoying them all on your own, they’re ready to go where you go - so they can be there when it counts."
                }
                items={products.Nuts}
                link={"/products?cat=Nuts&page=1"}
              />
              <ProductCarousel
                title={"Dried Fruits"}
                description={
                  "Our sumptuous array of options runs from familiar comforts like dried figs and jumbo black raisins, to wow-worthy specialties like blood orange and sour tart cherry. Whether you toss them into trail mixes or enjoy them on their own, each selection packs all the nutrients of their hydrated counterparts, without any of the mess left behind. And with a selection of pure fruits - untouched by added sugar or preservatives - we’ve truly got something for everyone."
                }
                items={products["Dried Fruits"]}
                link={"/products?cat=Dried%20Fruits&page=1"}
              />
              <ProductCarousel
                title={"Seeds"}
                description={
                  "Each selection of seeds packs a universe of flavor and nutrients into tiny, mobile and highly cravable  packages. Whether you like your seeds seasoned or prefer them raw, we have a tantalizing choice that will crush your hunger in its tracks, and energize you through the day. Don’t be fooled by their size - these seeds are full of big-time taste and even bigger benefits."
                }
                items={products.Seeds}
                link={"/products?cat=Seeds&page=1"}
              />
              <ProductCarousel
                title={"Mixed Nuts"}
                description={
                  "We offer a variety of delicious, health-conscious mixes - each with a unique spectrum of flavors. Ingredients are consciously arranged to create a harmonious balance in every bag, giving you big-time taste to defeat big-time cravings. Whether you’re hiking, entertaining, or simply snacking solo, we’ve got a mix that’s perfect for you right now."
                }
                items={products["Mixed Nuts"]}
                link={"/products?cat=Mixed%20Nuts&page=1"}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default MyAccount;
