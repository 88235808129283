import React, { createContext, useContext, useState, useEffect } from "react";

import { useAuthContext } from "./auth-context";
import { useProductsContext } from "./products-context";
import { useAsyncCall } from "../hooks/useAsyncCall";
import { cartRef, snapshotToDoc } from "../firebase";
import { useUserUUIDContext } from "../state/user-id-context";

const CartStateContext = createContext(undefined);
const CartDispatchContext = createContext(undefined);

const CartContextProvider = ({ children }) => {
  const [cart, setCart] = useState(null);

  const {
    authState: { authUser },
  } = useAuthContext();
  const { loading, setLoading, error, setError } = useAsyncCall();
  const {
    productsState: {
      products: { All },
    },
  } = useProductsContext();

  const { userUUID } = useUserUUIDContext();

  useEffect(() => {
    setLoading(true);

    if (All.length === 0) return;

    const unsubscribe = cartRef
      .where("user", "==", userUUID)
      .orderBy("createdAt", "desc")
      .onSnapshot({
        next: (snapshots) => {
          const cart = [];

          snapshots.forEach((snapshot) => {
            const cartItem = snapshotToDoc(snapshot);
            const product = All.find((prod) => prod.id === cartItem.product);

            if (!product) return;

            cart.push({ ...cartItem, item: product });
          });

          setCart(cart);
        },
        error: (err) => {
          setError(err.message);
          setLoading(false);
        },
      });

    return () => unsubscribe();
  }, [authUser, setCart, setLoading, setError, All, userUUID]);

  return (
    <CartStateContext.Provider value={{ cart, loading, error }}>
      <CartDispatchContext.Provider value={{ setCart }}>
        {children}
      </CartDispatchContext.Provider>
    </CartStateContext.Provider>
  );
};

export default CartContextProvider;

export const useCartContext = () => {
  const cartState = useContext(CartStateContext);
  const cartDispatch = useContext(CartDispatchContext);

  if (cartState === undefined || cartDispatch === undefined)
    throw new Error("useCartContext must be used within CartContextProvider.");

  return { ...cartState, ...cartDispatch };
};
