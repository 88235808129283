import { useState } from "react";

import { useAsyncCall } from "./useAsyncCall";
import { createImageRef, productsRef } from "../firebase";
import { firebase, storageRef } from "../firebase/config";

export const useManageProduct = () => {
  const [uploadProgression, setUploadProgression] = useState(0);
  const [addProductFinished, setAddProductFinished] = useState(false);
  const [editProductFinished, setEditProductFinished] = useState(false);

  const { loading, setLoading, error, setError } = useAsyncCall();

  const uploadImageToStorage = async (images, cb) => {
    setLoading(true);

    // 1. Upload images to firebase storage, get back image urls
    const imagesInfo = images.map((image) => ({
      imageRef: createImageRef(image.name),
      image,
    }));

    try {
      // Upload the image one by one and wait until all are done to get all image urls and related info before use these pieces of data to upload to firestore

      const uploadImagesResult = await Promise.all(
        imagesInfo.map((imageInfo) => {
          const uploadTask = imageInfo.imageRef.put(imageInfo.image);
          const imageRef = imageInfo.imageRef;
          const imageFileName = imageInfo.image.name;

          return new Promise((resolve, reject) => {
            uploadTask.on(
              "state_changed",
              (snapshot) => {
                // Calculate upload progression
                const progression =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

                setUploadProgression(progression);
              },
              (err) => {
                // Error case
                reject(err);
              },
              () => {
                // Sucess case

                // Get the image url
                uploadTask.snapshot.ref
                  .getDownloadURL()
                  .then((imageUrl) => {
                    resolve({
                      imageUrl,
                      imageRef: imageRef.fullPath,
                      imageFileName,
                    });
                  })
                  .catch((err) => {
                    reject(err);
                  });
              }
            );
          });
        })
      );

      setLoading(false);

      return cb(uploadImagesResult);
    } catch (error) {
      setError("Sorry something went wrong.");
      setLoading(false);
    }
  };

  const uploadImages = async (images: File[]) => {
    setLoading(true);

    try {
      // 1. Upload images to firebase storage, get back image urls
      const imagesInfo = images.map((image) => ({
        imageRef: createImageRef(image.name),
        image,
      }));
      // Upload the image one by one and wait until all are done to get all image urls and related info before use these pieces of data to upload to firestore

      const uploadImagesResult = await Promise.all(
        imagesInfo.map((imageInfo) => {
          const uploadTask = imageInfo.imageRef.put(imageInfo.image);
          const imageRef = imageInfo.imageRef;
          const imageFileName = imageInfo.image.name;

          return new Promise((resolve, reject) => {
            uploadTask.on(
              "state_changed",
              (snapshot) => {
                // Calculate upload progression
                const progression =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

                setUploadProgression(progression);
              },
              (err) => {
                // Error case
                reject(err);
              },
              () => {
                // Sucess case

                // Get the image url
                uploadTask.snapshot.ref
                  .getDownloadURL()
                  .then((imageUrl) => {
                    resolve({
                      imageUrl,
                      imageRef: imageRef.fullPath,
                      imageFileName,
                    });
                  })
                  .catch((err) => {
                    reject(err);
                  });
              }
            );
          });
        })
      );

      setLoading(false);

      return uploadImagesResult;
    } catch (error) {
      setError("Sorry something went wrong.");
      setLoading(false);
    }
  };

  const addNewProduct = (data, creator) => (imagesInfo) => {
    const {
      sku,
      title,
      description,
      price,
      category,
      ingredients,
      paleoDiet,
      athleteFriendly,
      diabeticFriendly,
      plantBasedProtein,
      highFiber,
      heartHealthy,
      ketoDiet,
      unsalted,
      inventory,
      servingsPerContainer,
      servingSize,
      calories,
      totalFat,
      saturatedFat,
      transFat,
      cholesterol,
      sodium,
      totalCarbohydrate,
      dietaryFiber,
      totalSugars,
      addedSugars,
      protein,
      vitaminA,
      vitaminC,
      vitaminD,
      calcium,
      iron,
      potassium,
    } = data;

    setLoading(true);
    setAddProductFinished(false);

    const newProduct = {
      sku,
      title,
      description,
      price: +price,
      category,
      ingredients,
      paleoDiet,
      athleteFriendly,
      diabeticFriendly,
      plantBasedProtein,
      highFiber,
      heartHealthy,
      ketoDiet,
      unsalted,
      inventory: +inventory,
      images: imagesInfo,
      servingsPerContainer,
      servingSize,
      calories,
      totalFat,
      saturatedFat,
      transFat,
      cholesterol,
      sodium,
      totalCarbohydrate,
      dietaryFiber,
      totalSugars,
      addedSugars,
      protein,
      vitaminA,
      vitaminC,
      vitaminD,
      calcium,
      iron,
      potassium,
      creator,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    };

    productsRef
      .add(newProduct)
      .then(() => {
        setAddProductFinished(true);
        setLoading(false);
      })
      .catch((err) => {
        const { message } = err;

        setError(message);
        setLoading(false);
      });
  };

  const editProduct = (productId, data, creator) => {
    const {
      sku,
      title,
      description,
      price,
      images,
      category,
      ingredients,
      paleoDiet,
      athleteFriendly,
      diabeticFriendly,
      plantBasedProtein,
      highFiber,
      heartHealthy,
      ketoDiet,
      unsalted,
      inventory,
      servingsPerContainer,
      servingSize,
      calories,
      totalFat,
      saturatedFat,
      transFat,
      cholesterol,
      sodium,
      totalCarbohydrate,
      dietaryFiber,
      totalSugars,
      addedSugars,
      protein,
      vitaminA,
      vitaminC,
      vitaminD,
      calcium,
      iron,
      potassium,
    } = data;
    setLoading(true);
    setEditProductFinished(false);

    const edittedProduct = {
      sku,
      title,
      description,
      price: +price,
      category,
      images,
      ingredients,
      paleoDiet,
      athleteFriendly,
      diabeticFriendly,
      plantBasedProtein,
      highFiber,
      heartHealthy,
      ketoDiet,
      unsalted,
      inventory: +inventory,
      servingsPerContainer,
      servingSize,
      calories,
      totalFat,
      saturatedFat,
      transFat,
      cholesterol,
      sodium,
      totalCarbohydrate,
      dietaryFiber,
      totalSugars,
      addedSugars,
      protein,
      vitaminA,
      vitaminC,
      vitaminD,
      calcium,
      iron,
      potassium,
      creator,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    };

    productsRef
      .doc(productId)
      .set(edittedProduct, { merge: true })
      .then(() => {
        setEditProductFinished(true);
        setLoading(false);
      })
      .catch((err) => {
        const { message } = err;

        setError(message);
        setLoading(false);
      });
  };

  const deleteProduct = async (product) => {
    try {
      setLoading(true);

      // Define an array of images both multi images products and single image products
      const images = product.images
        ? product.images
        : [
            {
              imageRef: product.imageRef,
              imageUrl: product.imageUrl,
              imageFileName: product.imageFileName,
            },
          ];

      // Delete all images
      await Promise.all(
        images.map(async (image) => {
          const imageRef = storageRef.child(image.imageRef);
          await imageRef.delete();
        })
      );

      // Delete the product from firestore
      await productsRef.doc(product.id).delete();

      setLoading(false);

      return true;
    } catch (err) {
      const { message } = err;

      setError(message);
      setLoading(false);

      return false;
    }
  };

  return {
    uploadImageToStorage,
    addNewProduct,
    editProduct,
    deleteProduct,
    uploadProgression,
    addProductFinished,
    editProductFinished,
    setUploadProgression,
    loading,
    error,
    uploadImages,
  };
};
