import React from "react";
import { useForm } from "react-hook-form";

import Button from "../Button";
import Input from "../Input";
import { useModalContext } from "../../state/modal-context";
import { useAuthenticate } from "../../hooks/useAuthenticate";

const ResetPassword = () => {
  const { setModalType } = useModalContext();
  const { loading, error, resetPassword, successMsg } = useAuthenticate();
  const { register, errors, handleSubmit } = useForm();

  const handleResetPassword = handleSubmit((data) => resetPassword(data));

  return (
    <>
      <div className="backdrop" onClick={() => setModalType("close")}></div>

      <div className="modal modal--auth-form">
        <div className="modal-close" onClick={() => setModalType("close")}>
          &times;
        </div>

        <h5 className="header--center paragraph--orange">
          Enter your email below to reset your password.
        </h5>

        <form className="form" onSubmit={handleResetPassword}>
          <Input
            name="email"
            placeholder="Your email"
            error={errors.email?.message}
            ref={register({
              required: "Email is required.",
            })}
          />

          <Button loading={loading} width="100%" style={{ margin: "0.5rem 0" }}>
            Submit
          </Button>

          {error && <p className="paragraph paragraph--error">{error}</p>}
        </form>

        {successMsg && <p className="paragraph--success paragraph--small">{successMsg}</p>}
      </div>
    </>
  );
};

export default ResetPassword;
