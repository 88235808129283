import React from "react";
import { useHistory } from "react-router-dom";

import Input from "./Input";
import { useWelcomeLeadContext } from "../state/welcome-lead-context";

const CTA = ({ style }) => {
  const { setWelcomeLead } = useWelcomeLeadContext();

  const history = useHistory();

  const handleSetLead = (e) => {
    e.preventDefault();

    const email = e.target.email.value;
    const zipcode = e.target.zipcode.value;

    if (!email || !zipcode) return;

    setWelcomeLead({
      email,
      zipcode,
    });

    history.push("/select-plan");
    return
  };

  return (
    <div className="cta" style={{ ...style }}>
      <form onSubmit={handleSetLead}>
        <div className="cta-content">
          <div className="cta-text">
            <div className="cta-heading1">Ready to get started?</div>
            <div className="cta-heading2">
              Choose a subscription that works for you and receive deliveries on
              your schedule. It’s that simple to eat healthy.
            </div>
          </div>
<div className="cta-email-input">

<Input
            type="email"
            required
            style={{ width: "100%" }}
            className="cta-input cta-email"
            name="email"
            placeholder="Email"
          />
</div>
<div className="cta-zipcode-input">

<Input
            required
            style={{ width: "100%" }}
            className="cta-input cta-zipcode"
            name="zipcode"
            placeholder="Zip Code"
          />

</div>
          <button className="cta-btn">{`go`}</button>
        </div>
      </form>
    </div>
  );
};

export default CTA;
