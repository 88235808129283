import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPlus,
  faMinus,
  faCartArrowDown,
  faBoxOpen,
  faUserCircle,
  faEdit,
  faTrashAlt,
  faCheckCircle,
  faTimesCircle,
  faTimes,
  faCaretRight,
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faChevronDown,
  faCheck
} from "@fortawesome/free-solid-svg-icons";
import {
  faGoogle,
  faCcMastercard,
  faCcVisa,
  faCcAmex,
  faInstagram,
  faFacebookSquare,
  faYoutubeSquare,

} from "@fortawesome/free-brands-svg-icons";

library.add(
  faPlus,
  faMinus,
  faCartArrowDown,
  faBoxOpen,
  faUserCircle,
  faFacebookSquare,
  faYoutubeSquare,
  faGoogle,
  faEdit,
  faTrashAlt,
  faCheckCircle,
  faCcMastercard,
  faCcVisa,
  faCcAmex,
  faTimesCircle,
  faTimes,
  faCaretRight,
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faChevronLeft,
  faChevronRight,
  faInstagram,
  faChevronUp,
  faChevronDown,
  faCheck
);
