import React from 'react';
import {Link, useHistory} from 'react-router-dom'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PageHeader from "../components/PageHeader";
import BlogItem from '../components/blogs/BlogItem'
import {useBlogsContext} from '../state/blogs-context'

const Blogs = () => {

  const {blogsState:{blogs}} = useBlogsContext()


  return (
    <>

<PageHeader pageTitle={"Blogs"} />

<div className="head-page-route">
  <Link to="/">
    <span>Home</span>
  </Link>{" "}
  <FontAwesomeIcon
    className="fa-icons"
    icon={["fas", "chevron-right"]}
    size="xs"
  />{" "}
  Blogs
</div>

<div className="blogListingContainer">

  {!blogs ? false : blogs.map((blog) => {
    return <BlogItem blogData={blog} key={blog.title} />

  })}

</div>



    </>
  );
};

export default Blogs;
