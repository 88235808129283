import { useAsyncCall } from "./useAsyncCall";
import { functions, firebase, db } from "../firebase/config";
import { cartRef, ordersRef } from "../firebase";
import { address_key } from "../components/select-address/ShippingAddress";

export const useCheckout = () => {
  const { loading, setLoading, error, setError } = useAsyncCall();

  const completePayment = async (paymentData, options, order, cart) => {
    const { createPaymentIntentData, stripe, payment_method } = paymentData;
    const { save, setDefault, customerId } = options;
    try {
      setLoading(true);

      const createPaymentItents = functions.httpsCallable(
        "createPaymentIntents"
      );

      const paymentIntent = await createPaymentItents(createPaymentIntentData);

      if (!paymentIntent.data.clientSecret) return;

      const confirmPayment = await stripe.confirmCardPayment(
        paymentIntent.data.clientSecret,
        {
          payment_method,
          save_payment_method: save,
        }
      );

      if (confirmPayment?.error?.message) {
        setError(confirmPayment.error.message);
        setLoading(false);
        return false;
      }

      if (confirmPayment.paymentIntent?.status === "succeeded") {
        if (setDefault) {
          const setDefaultCard = functions.httpsCallable("setDefaultCard");

          await setDefaultCard({
            customerId,
            payment_method: confirmPayment.paymentIntent?.payment_method,
          });
        }

        // Create a new order in firestore
        const uploadOrder = {
          ...order,
          paymentStatus: "Success",
          paymentType: "ONETIME",
          shipmentStatus: "Upcoming",
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        };

        await ordersRef.add(uploadOrder).then(() => {
          // Delete the cart items from firestore
          cartRef
            .where("user", "==", order.user.id)
            .get()
            .then((snapshots) => {
              const batch = db.batch();
              snapshots.forEach((doc) =>
                cart.forEach((item) =>
                  item.id === doc.id ? batch.delete(doc.ref) : null
                )
              );

              return batch.commit();
            });
        });

        window.localStorage.removeItem(address_key);
        window.localStorage.removeItem("selectedPlan");
        window.localStorage.removeItem("itemLimit");
        setLoading(false);
        return true;
      }

      return false;
    } catch (err) {
      setError("Sorry, something went wrong.");
      setLoading(false);

      return false;
    }
  };

  const createStripeCustomerId = async () => {
    try {
      setLoading(true);

      const createStripeCustomer = functions.httpsCallable(
        "createStripeCustomer"
      );

      const stripeCustomerData = await createStripeCustomer();

      return stripeCustomerData.data.customerId;
    } catch (error) {
      setError("Sorry, something went wrong.");
      setLoading(false);

      return undefined;
    }
  };

  // ----------- Subscription

  const subscribe = async (
    subscriptionData,
    paymentData,
    options,
    order,
    cart
  ) => {
    const { stripe, payment_method } = paymentData;
    const { save, setDefault } = options;

    try {
      setLoading(true);

      // 1. Call cloud function
      const createSubscription = functions.httpsCallable("createSubscription");

      const subscription = await createSubscription(subscriptionData);

      // 2. Confirm the payment
      const confirmPayment = await stripe.confirmCardPayment(
        subscription.data.clientSecret,
        {
          payment_method,
          save_payment_method: save,
        }
      );

      if (confirmPayment?.error?.message) {
        setError(confirmPayment.error.message);
        setLoading(false);
        return false;
      }
      if (confirmPayment.paymentIntent?.status === "succeeded") {
        if (setDefault) {
          const setDefaultCard = functions.httpsCallable("setDefaultCard");

          await setDefaultCard({
            customerId: subscriptionData.stripeId,
            payment_method: confirmPayment.paymentIntent?.payment_method,
          });
        }

        // Create a new order in firestore, this is the first order
        const uploadOrder = {
          ...order,
          paymentStatus: "Processing",
          subscriptionId: subscription.data.subscriptionId,
          paymentType: "SUBSCRIPTION",
          shipmentStatus: "Upcoming",
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        };

        await ordersRef.add(uploadOrder).then(() => {
          // Delete the cart items from firestore
          cartRef
            .where("user", "==", order.user.id)
            .get()
            .then((snapshots) => {
              const batch = db.batch();
              snapshots.forEach((doc) =>
                cart.forEach((item) =>
                  item.id === doc.id ? batch.delete(doc.ref) : null
                )
              );
              return batch.commit();
            });
        });

        window.localStorage.removeItem(address_key);
        window.localStorage.removeItem("selectedPlan");
        window.localStorage.removeItem("itemLimit");
        setLoading(false);
        return true;
      }
      return false;
    } catch (err) {
      setError("Sorry, something went wrong.");
      setLoading(false);
      return false;
    }
  };

  // -------------------

  return { completePayment, createStripeCustomerId, subscribe, loading, error };
};
