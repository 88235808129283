import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import ProductItem from "../components/products/ProductItem";
import Spinner from "../components/Spinner";
import Tab from "../components/Tabs";
import Pagination from "../components/Pagination";
import ProductCarousel from "../components/carousel/ProductCarousel";
import { useAuthContext } from "../state/auth-context";
import { useModalContext } from "../state/modal-context";
import { useProductsContext } from "../state/products-context";
import { useSearchContext } from "../state/search-context";
import { useSelectTab } from "../hooks/useSelectTab";
import { usePagination } from "../hooks/usePagination";
import { usePaymentPlanContext } from "../state/payment-plan-context";
import { useCartContext } from "../state/cart-context";
import { useManageCart } from "../hooks/useManageCart";
import { useDialog } from "../hooks/useDialog";
import AlertDialog from "../components/dialogs/AlertDialog";
import { productTabs, collections } from "../helpers";
import ToggleSection from "../components/toggle-sections/ToggleSection";
import { Helmet, HelmetProvider } from "react-helmet-async";

export const prodTabType = "cat";
export const productsPerPage = 12;

const catName = (cat) => {
  if (cat === "Dried Fruits") {
    return "DriedFruits";
  } else if (cat === "Mixed Nuts") {
    return "MixedNuts";
  } else if (cat === "Gift Boxes") {
    return "GiftBoxes";
  } else {
    return cat;
  }
};

const ProductListing = () => {
  const { setModalType } = useModalContext();
  const {
    authState: { authUser, signoutRedirect },
  } = useAuthContext();
  const {
    productsState: { products, loading, productCounts, queryMoreProducts },
  } = useProductsContext();

  const { searchedItems } = useSearchContext();
  const { activeTab } = useSelectTab(prodTabType, "Nuts");

  const { cart } = useCartContext();
  const {
    addToCart,
    loading: addToCartLoading,
    error: addToCartError,
    removeCartItem,
  } = useManageCart();

  const { paymentPlan } = usePaymentPlanContext();
  const { openDialog, setOpenDialog } = useDialog();

  const [productsByCat, setProductsByCat] = useState(products[activeTab]);
  const [paginatedSearchedItems, setPaginatedSearchedItems] =
    useState(searchedItems);

  const [selectedFilter, setSelectedFilter] = useState(null);

  const { page, totalPages } = usePagination(
    productCounts[catName(activeTab)],
    productsPerPage,
    activeTab,
    searchedItems
  );

  const history = useHistory();
  const { state } = history.location;

  useEffect(() => {
    if (!signoutRedirect) {
      if (state?.from) {
        if (!authUser) setModalType("signin");
        else history.push(state.from);
      }
    } else {
      history.replace("/", undefined);
    }
  }, [setModalType, state, authUser, history, signoutRedirect]);

  useEffect(() => {
    if (activeTab === "AllProducts") return;

    const startIndex = productsPerPage * (page - 1);
    const endIndex = productsPerPage * page;

    if (searchedItems) {
      setPaginatedSearchedItems(searchedItems.slice(startIndex, endIndex));
      setProductsByCat([]);
    } else {
      if (
        products[activeTab].length < productCounts[activeTab] &&
        products[activeTab].length < productsPerPage * page
      ) {
        return queryMoreProducts();
      }
      setProductsByCat(products[activeTab].slice(startIndex, endIndex));
      setPaginatedSearchedItems(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, products, page, searchedItems, productCounts]);

  const deleteItem = async (item) => {
    const finished = await removeCartItem(item.item.id, item.user);

    if (finished) return;
  };

  if (loading) return <Spinner color="gray" width={50} height={50} />;

  if (!loading && products.All.length === 0)
    return <h2 className="header--center">No products</h2>;

  const filterCollection = (title) => {
    setSelectedFilter(title);
    const filteredArr = products["All"].filter((product) => {
      return product[title] === true;
    });

    setPaginatedSearchedItems(filteredArr);
  };

  const resetFiltered = () => {
    setPaginatedSearchedItems(searchedItems);
    setSelectedFilter(null);
  };

  return (
    <>
      <HelmetProvider>
        {selectedFilter === "paleoDiet" ? (
          <Helmet>
            <title>
              Paleo Diet Friendly Snacks | Healthy Snacks | Nuts | Seeds - nuts
              inbox
            </title>
            <meta
              name="description"
              content="Paleo-friendly snacking that never sacrifices taste: this rich assortment of nuts, seeds and mixes are loaded with the healthy fats you need."
            />
          </Helmet>
        ) : selectedFilter === "diabeticFriendly" ? (
          <Helmet>
            <title>
              Diabetic Friendly Snacks |Best Snacks for Diabetics - nuts inbox
            </title>
            <meta
              name="description"
              content="Regulating your blood sugar has never been more fulfilling - emphasis on filling. From seeds to nuts to fruits and mixes, we’ve got just the diabetic friendly snack."
            />
          </Helmet>
        ) : selectedFilter === "athleteFriendly" ? (
          <Helmet>
            <title>
              Athlete Friendly Snacks |Healthy Snacks for Athletes | Nuts - nuts
              inbox
            </title>
            <meta
              name="description"
              content="NutsinBox offers athlete friendly snacks with a delicious assortment of fruits, seeds, nuts and trail mixes. Click here to order!"
            />
          </Helmet>
        ) : selectedFilter === "plantBasedProtein" ? (
          <Helmet>
            <title>
              Plant Based Protein |Roasted and Salted Seeds | Roasted Nuts -
              nuts inbox
            </title>
            <meta
              name="description"
              content="NutsInBox offers plant based protein snacks; roasted, salted, or buttered and packed with big power & big flavor. Click here to order now!"
            />
          </Helmet>
        ) : selectedFilter === "highFiber" ? (
          <Helmet>
            <title>
              High Fiber Snacks | Roasted and Salted Mixed Nuts | Dried Fruits -
              nuts inbox
            </title>
            <meta
              name="description"
              content="High Fiber snacks of dried fruits and roasted and salted Mixed nuts come loaded with energy to keep you full and lively, all day long"
            />
          </Helmet>
        ) : selectedFilter === "heartHealthy" ? (
          <Helmet>
            <title>
              Heart Healthy Snacks | Healthy Snacks for Heart - nuts inbox
            </title>
            <meta
              name="description"
              content="From fruits to seeds to nuts, we’ve got options that are as good for your heart as they are for your tastebuds. These snacks prove health never has to mean compromising."
            />
          </Helmet>
        ) : selectedFilter === "ketoDiet" ? (
          <Helmet>
            <title>
              Keto Friendly Snacks | Healthy Keto Snacks - nuts inbox
            </title>
            <meta
              name="description"
              content="High in healthy fats and low in carbs, these keto-friendly snacks are always safe to enjoy. And with options like Chilli Lime and BBQ, trust us, there’s a whole lot here to enjoy."
            />
          </Helmet>
        ) : selectedFilter === "unsalted" ? (
          <Helmet>
            <title>Unsalted | Salt-Free Healthy Snacks - nuts inbox</title>
            <meta
              name="description"
              content="nuts inbox offers unsalted/salt-free snacks. Selections from every category are available to help lower hunger and blood pressure alike. Order now!"
            />
          </Helmet>
        ) : activeTab === "AllProducts" ? (
          <Helmet>
            <title>
              Nuts InBox - Premium Quality Nuts | Dried Fruits | Seeds | Mixed
              Nuts
            </title>
            <meta
              name="description"
              content="Nuts, dried fruits, seeds, and mixed nuts made from all-natural ingredients and packed with nutritional value, our snacks keep your family healthy."
            />
          </Helmet>
        ) : activeTab === "Nuts" ? (
          <Helmet>
            <title>
              Premium Quality Nuts | Nuts Box | Almonds | Cashews - Nuts InBox
            </title>
            <meta
              name="description"
              content="Check out our nuts box selection from vital superfoods like almonds, cashews and walnuts - to indulgent delights like fried wasabi peas and our Japanese Cajun peanuts."
            />
          </Helmet>
        ) : activeTab === "Dried Fruits" ? (
          <Helmet>
            <title>Dried Fruits | Healthy Snacks - Nuts InBox</title>
            <meta
              name="description"
              content="Check out our dried fruits options running from familiar comforts like dried figs and jumbo black raisins, to wow-worthy specialties like blood orange and sour tart cherry."
            />
          </Helmet>
        ) : activeTab === "Seeds" ? (
          <Helmet>
            <title>Seeds | Healthy Snacks - Nuts InBox</title>
            <meta
              name="description"
              content="Each selection of our seeds packs a universe of flavor and nutrients into tiny, mobile and highly cravable packages. Click here to order now!"
            />
          </Helmet>
        ) : activeTab === "Mixed Nuts" ? (
          <Helmet>
            <title>Mixed Nuts | Healthy Snacks - Nuts InBox</title>
            <meta
              name="description"
              content="At NutsInBox We offer a variety of delicious, health-conscious mixed nuts - with a unique spectrum of flavors. Click here to order now!"
            />
          </Helmet>
        ) : undefined}
      </HelmetProvider>

      <div className="product-page-container">
        <div className="pp-filters-desktop">
          <div className="pp-filters-desktop-container">
            <div className="pp-filters-title">Categories:</div>

            <div className="category-tab-items">
              {productTabs.map((cat) => (
                <Tab
                  key={cat}
                  label={cat}
                  tabType={prodTabType}
                  activeTab={activeTab}
                  withPagination={true}
                  resetFiltered={resetFiltered}
                  selectedFilter={selectedFilter}
                />
              ))}
            </div>

            <div className="pp-filters-title">Filter by:</div>
            <div className="collection-items">
              {collections.map((collection) => (
                <div
                  key={collection.title}
                  onClick={() => {
                    filterCollection(collection.nospace);
                  }}
                  className={
                    collection.nospace === selectedFilter
                      ? "selected-cat-tab-item"
                      : "cat-tab-item"
                  }
                >
                  {collection.title}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="pp-filters-mobile">
          <div className="pp-filters-mobile-container">
            <ToggleSection title={"Categories"}>
              <div className="pp-filters-title">Categories:</div>
              <div className="category-tab-items">
                {productTabs.map((cat) => (
                  <Tab
                    key={cat}
                    label={cat}
                    tabType={prodTabType}
                    activeTab={activeTab}
                    withPagination={true}
                    resetFiltered={resetFiltered}
                    selectedFilter={selectedFilter}
                  />
                ))}
              </div>

              <div className="pp-filters-title">Filter by:</div>
              <div className="collection-items">
                {collections.map((collection) => (
                  <div
                    key={collection.title}
                    onClick={() => filterCollection(collection.nospace)}
                    className={
                      collection.nospace === selectedFilter
                        ? "selected-cat-tab-item"
                        : "cat-tab-item"
                    }
                  >
                    {collection.title}
                  </div>
                ))}
              </div>
            </ToggleSection>
          </div>
        </div>

        <div className="pp-listing-content">
          <div className="products-page-head">
            <h1 className="products-page-head-category-name">
              {paginatedSearchedItems && paginatedSearchedItems.length > 0
                ? undefined
                : activeTab !== "AllProducts"
                ? activeTab
                : undefined}
            </h1>
            <div className="products-page-head-description">
              {paginatedSearchedItems && paginatedSearchedItems.length > 0
                ? undefined
                : activeTab === "Nuts"
                ? "From vital superfoods like almonds, cashews and walnuts - to indulgent delights like fried wasabi peas and our Japanese Cajun peanuts - these nuts cover all the bases. Whether you’re at the ball game, hosting game night with friends or enjoying them all on your own, they’re ready to go where you go - so they can be there when it counts."
                : activeTab === "Dried Fruits"
                ? "Our sumptuous array of options runs from familiar comforts like dried figs and jumbo black raisins, to wow-worthy specialties like blood orange and sour tart cherry. Whether you toss them into trail mixes or enjoy them on their own, each selection packs all the nutrients of their hydrated counterparts, without any of the mess left behind. And with a selection of pure fruits - untouched by added sugar or preservatives - we’ve truly got something for everyone."
                : activeTab === "Seeds"
                ? "Each selection of seeds packs a universe of flavor and nutrients into tiny, mobile and highly cravable  packages. Whether you like your seeds seasoned or prefer them raw, we have a tantalizing choice that will crush your hunger in its tracks, and energize you through the day. Don’t be fooled by their size - these seeds are full of big-time taste and even bigger benefits."
                : activeTab === "Mixed Nuts"
                ? "We offer a variety of delicious, health-conscious mixes - each with a unique spectrum of flavors. Ingredients are consciously arranged to create a harmonious balance in every bag, giving you big-time taste to defeat big-time cravings. Whether you’re hiking, entertaining, or simply snacking solo, we’ve got a mix that’s perfect for you right now."
                : undefined}
            </div>
          </div>

          <div
            className={
              activeTab === "AllProducts" && !selectedFilter
                ? "allproducts-carousels"
                : "products"
            }
          >
            {paginatedSearchedItems ? (
              <>
                {paginatedSearchedItems.length < 1 ? (
                  <h2 className="header--center">No products found.</h2>
                ) : (
                  paginatedSearchedItems.map((product) => (
                    <ProductItem
                      key={product.id}
                      product={product}
                      setOpenDialog={setOpenDialog}
                    />
                  ))
                )}
              </>
            ) : activeTab === "AllProducts" ? (
              <div className="allproducts-carousels">
                <ProductCarousel
                  setOpenDialog={setOpenDialog}
                  title={"Nuts"}
                  description={
                    "From vital superfoods like almonds, cashews and walnuts - to indulgent delights like fried wasabi peas and our Japanese Cajun peanuts - these nuts cover all the bases. Whether you’re at the ball game, hosting game night with friends or enjoying them all on your own, they’re ready to go where you go - so they can be there when it counts."
                  }
                  items={products.Nuts}
                  link={"/products?cat=Nuts&page=1"}
                  browse={true}
                />
                <ProductCarousel
                  setOpenDialog={setOpenDialog}
                  title={"Dried Fruits"}
                  description={
                    "Our sumptuous array of options runs from familiar comforts like dried figs and jumbo black raisins, to wow-worthy specialties like blood orange and sour tart cherry. Whether you toss them into trail mixes or enjoy them on their own, each selection packs all the nutrients of their hydrated counterparts, without any of the mess left behind. And with a selection of pure fruits - untouched by added sugar or preservatives - we’ve truly got something for everyone."
                  }
                  items={products["Dried Fruits"]}
                  link={"/products?cat=Dried%20Fruits&page=1"}
                  browse={true}
                />
                <ProductCarousel
                  setOpenDialog={setOpenDialog}
                  title={"Seeds"}
                  description={
                    "Each selection of seeds packs a universe of flavor and nutrients into tiny, mobile and highly cravable  packages. Whether you like your seeds seasoned or prefer them raw, we have a tantalizing choice that will crush your hunger in its tracks, and energize you through the day. Don’t be fooled by their size - these seeds are full of big-time taste and even bigger benefits."
                  }
                  items={products.Seeds}
                  link={"/products?cat=Seeds&page=1"}
                  browse={true}
                />
                <ProductCarousel
                  setOpenDialog={setOpenDialog}
                  title={"Mixed Nuts"}
                  description={
                    "We offer a variety of delicious, health-conscious mixes - each with a unique spectrum of flavors. Ingredients are consciously arranged to create a harmonious balance in every bag, giving you big-time taste to defeat big-time cravings. Whether you’re hiking, entertaining, or simply snacking solo, we’ve got a mix that’s perfect for you right now."
                  }
                  items={products["Mixed Nuts"]}
                  link={"/products?cat=Mixed%20Nuts&page=1"}
                  browse={true}
                />
              </div>
            ) : (
              productsByCat.map((product) => (
                <ProductItem
                  key={product.id}
                  product={product}
                  setOpenDialog={setOpenDialog}
                />
              ))
            )}
          </div>

          <div className="pagination-container">
            {activeTab !== "AllProducts" && !selectedFilter ? (
              <Pagination
                page={page}
                tabType={searchedItems ? undefined : prodTabType}
                activeTab={searchedItems ? undefined : activeTab}
                totalPages={totalPages}
              />
            ) : undefined}
          </div>
        </div>
      </div>

      <div className="page--products"></div>

      {openDialog && paymentPlan === "ONETIME" && (
        <AlertDialog
          header="You Have Gift Boxes In Your Shopping Cart"
          message={`Would you like to continue with your Gift Box Purchase or Cancel and select a subscription plan?`}
          onConfirm={() => {
            cart.forEach((item) => {
              deleteItem(item);
            });

            setOpenDialog(false);
            history.push("/welcome");
          }}
          confirmText="Select a subscription plan"
          cancelText="Continue with Gift Box"
          onCancel={() => {
            setOpenDialog(false);
          }}
          loading={loading}
        />
      )}
    </>
  );
};

export default ProductListing;
