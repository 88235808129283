import React, {
  createContext,
  useContext,
  useState,
} from 'react'

const SearchStateContext = createContext(undefined)
const SearchDispatchContext = createContext(
  undefined
)

const SearchContextProvider = ({ children }) => {
  const [searchedItems, setSearchedItems] = useState(null)

  return (
    <SearchStateContext.Provider value={{ searchedItems }}>
      <SearchDispatchContext.Provider value={{ setSearchedItems }}>
        {children}
      </SearchDispatchContext.Provider>
    </SearchStateContext.Provider>
  )
}

export default SearchContextProvider

export const useSearchContext = () => {
  const searchedState = useContext(SearchStateContext)
  const searchedDispatch = useContext(SearchDispatchContext)

  if (searchedState === undefined || searchedDispatch === undefined)
    throw new Error(
      'useSearchContext must be used within SearchContextProvider.'
    )

  return { ...searchedState, ...searchedDispatch }
}
