import React from "react";

const DialogWrapper = ({ children, header, onClose }) => {
  return (
    <div>
      <div
        className="backdrop"
        onClick={onClose ? () => onClose(false) : undefined}
      ></div>

      <div className="modal modal--dialog">
        {onClose && (
          <div
            className="modal-close"
            onClick={onClose ? () => onClose(false) : undefined}
          >
            &times;
          </div>
        )}

        <h3 className="dialog-header">{header}</h3>
        <div className="dialog">{children}</div>
      </div>
    </div>
  );
};

export default DialogWrapper;
