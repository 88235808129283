import React, { createContext, useContext, useState } from "react";

const OrderDataStateContext = createContext(undefined);
const OrderDataDispatchContext = createContext(undefined);

const OrderDataContextProvider = ({ children }) => {
  const [orderData, setOrderData] = useState(null);

  return (
    <OrderDataStateContext.Provider value={orderData}>
      <OrderDataDispatchContext.Provider value={setOrderData}>
        {children}
      </OrderDataDispatchContext.Provider>
    </OrderDataStateContext.Provider>
  );
};

export default OrderDataContextProvider;

export const useOrderDataContext = () => {
  const orderData = useContext(OrderDataStateContext);
  const setOrderData = useContext(OrderDataDispatchContext);

  if (orderData === undefined || setOrderData === undefined)
    throw new Error(
      "useOrderDataContext must be used within the OrderDataContextProvider"
    );

  return { orderData, setOrderData };
};
