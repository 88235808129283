import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import PageHeader from "../components/PageHeader";
import Spinner from "../components/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ManageOrderItem from "../components/manage-orders/ManageOrderItem";
import Tab from "../components/Tabs";
import Pagination from "../components/Pagination";
import { orderTabType } from "./Orders";
import { useOrdersContext } from "../state/orders-context";
import { useSearchContext } from "../state/search-context";
import { useOrderCountsContext } from "../state/orderCounts-context";
import { useSelectTab } from "../hooks/useSelectTab";
import { usePagination } from "../hooks/usePagination";
import { orderTabs } from "../helpers";

const ordersPerPage = 10;

const ManageOrders = () => {
  const {
    ordersState: { orders, loading, error, queryMoreOrders },
  } = useOrdersContext();
  const { searchedItems } = useSearchContext();
  const {
    orderCountsState: { orderCounts },
  } = useOrderCountsContext();

  const { activeTab } = useSelectTab(orderTabType, "All");
  const { page, totalPages } = usePagination(
    orderCounts,
    ordersPerPage,
    activeTab,
    searchedItems
  );

  const [ordersByTab, setOrdersByTab] = useState(orders);
  const [paginatedSearchedItems, setPaginatedSearchedItems] =
    useState(searchedItems);

  useEffect(() => {
    const startIndex = ordersPerPage * (page - 1);
    const endIndex = ordersPerPage * page;

    if (searchedItems) {
      setPaginatedSearchedItems(searchedItems.slice(startIndex, endIndex));
      setOrdersByTab([]);
    } else {
      if (!orders) {
        setOrdersByTab(null);
        return;
      }

      // Check if we need to query more orders
      if (orders.length < orderCounts && orders.length < ordersPerPage * page) {
        return queryMoreOrders();
      }

      if (activeTab === "All")
        setOrdersByTab(orders.slice(startIndex, endIndex));
      else
        setOrdersByTab(
          orders.filter((order) => order.shipmentStatus === activeTab)
        );

      setPaginatedSearchedItems(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, orders, setOrdersByTab, page, searchedItems, orderCounts]);

  if (loading) return <Spinner color="grey" height={50} width={50} />;

  if (error) return <h2 className="header--center">{error}</h2>;

  if (!orders || orders.length === 0)
    return <h2 className="header--center">Your have no orders.</h2>;

  console.log(orders[1]);
  return (
    <>
      <PageHeader pageTitle={"Manage Orders"} />
      <div className="head-page-route">
        <Link to="/">
          <span>Home</span>
        </Link>{" "}
        <FontAwesomeIcon
          className="fa-icons"
          icon={["fas", "chevron-right"]}
          size="xs"
        />{" "}
        Manage Orders{" "}
      </div>

      <div className="page--orders">
        <div className="orders-header">
          <div className="orders-tabs">
            {orderTabs.map((tab) => (
              <Tab
                key={tab}
                label={tab}
                activeTab={activeTab}
                tabType={orderTabType}
                withPagination={true}
              />
            ))}
          </div>
        </div>

        <div className="pagination-container">
          {activeTab === "All" && (
            <Pagination
              page={page}
              totalPages={totalPages}
              tabType={orderTabType}
              activeTab={activeTab}
            />
          )}
        </div>

        <div className="orders-details">
          <div className="orders-content">
            <div className="orders-column">
              <h3 className="header--center">Date</h3>
            </div>
            <div className="orders-column orders-column--hide">
              <h3 className="header--center">Quantity</h3>
            </div>
            <div className="orders-column orders-column--hide">
              <h3 className="header--center">Payment Type</h3>
            </div>

            <div className="orders-column">
              <h3 className="header--center">Amount ($)</h3>
            </div>
            <div className="orders-column orders-column--hide">
              <h3 className="header--center">Shipment status</h3>
            </div>
            <div className="orders-column orders-column-buyer">
              <h3 className="header--center">Buyer</h3>
            </div>
            <div className="orders-column orders-column--manage">
              <h3 className="header--center">Manage order</h3>
            </div>
          </div>

          {/* Order */}
          {paginatedSearchedItems ? (
            <>
              {paginatedSearchedItems.length < 1 ? (
                <h2 className="header--center">No orders found.</h2>
              ) : (
                paginatedSearchedItems.map((order) => (
                  <ManageOrderItem key={order.id} order={order} />
                ))
              )}
            </>
          ) : (
            ordersByTab &&
            ordersByTab.map((order) => (
              <ManageOrderItem key={order.id} order={order} />
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default ManageOrders;
