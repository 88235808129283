import { useAsyncCall } from "./useAsyncCall";
import { firebase } from "../firebase/config";
import { cartRef } from "../firebase";

export const useManageCart = () => {
  const { loading, setLoading, error, setError } = useAsyncCall();

  const addToCart = async (productId, quantity, userId, inventory) => {
    try {
      setLoading(true);

      const cartItemRef = cartRef.doc(`${userId}-${productId}`);
      const snapshot = await cartItemRef.get();

      let cartItem;
      if (!snapshot.exists) {
        cartItem = {
          product: productId,
          quantity,
          user: userId,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        };
      } else {
        const currentCartItem = snapshot.data();

        cartItem = {
          ...currentCartItem,
          quantity:
            currentCartItem.quantity + quantity > inventory
              ? inventory
              : currentCartItem.quantity + quantity,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        };
      }

      await cartItemRef.set(cartItem);
      setLoading(false);

      return true;
    } catch (err) {
      const { message } = err;

      setError(message);
      setLoading(false);

      return false;
    }
  };

  const removeCartItem = async (productId, userId) => {
    try {
      setLoading(true);

      const cartItemRef = cartRef.doc(`${userId}-${productId}`);
      const snapshot = await cartItemRef.get();

      if (!snapshot.exists) return;

      await cartItemRef.delete();
      setLoading(false);

      return true;
    } catch (err) {
      const { message } = err;

      setError(message);
      setLoading(false);

      return false;
    }
  };

  return { addToCart, removeCartItem, loading, error };
};
