import React from "react";

import DialogWrapper from "./DialogWrapper";
import Button from "../Button";

const AlertDialog = ({
  header,
  message,
  onConfirm,
  onCancel,
  loading,
  error,
  cancelText,
  confirmText,
}) => {
  return (
    <DialogWrapper
      header={header}
      onClose={onCancel && !loading ? onCancel : undefined}
    >
      <div className="dialog-body">
        <div className="dialog-message-container">
          <div className="dialog-message">
          {message}
          </div>
        </div>

        <div className="dialog-action-btns">
          {onCancel && (
            <Button
              width={"100%"}
              height={"30px"}
              className="dialog-cancel-btn"
              onClick={onCancel}
              disabled={loading}
            >
              {cancelText ? cancelText : "Cancel"}
            </Button>
          )}

          {onConfirm && (
            <Button
              width={"100%"}
              height={"30px"}
              className="dialog-confirm-btn"
              loading={loading}
              disabled={loading}
              onClick={() => onConfirm()}
            >
              {confirmText ? confirmText : "Confirm"}
            </Button>
          )}
        </div>

        {error && <p className="paragraph paragraph--error">{error}</p>}
      </div>
    </DialogWrapper>
  );
};

export default AlertDialog;
