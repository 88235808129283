import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Orders from "../pages/Orders";
import OrderDetail from "../pages/OrderDetail";
import ManagePlan from "../pages/ManagePlan";
import PageNotFound from "../pages/PageNotFound";
import OrdersContextProvider from "../state/orders-context";
import { isClient } from "../helpers";

const OrderRoutes = (props) => {
  const { userInfo } = props;

  if (!isClient(userInfo?.role)) return <Redirect to="/" />;
  return (
    <OrdersContextProvider>
      <Switch>
        <Route path="/orders/my-orders/:id">
          <OrderDetail />
        </Route>

        <Route path="/orders/my-orders">
          <Orders />
        </Route>

        <Route path="/orders/my-plans">
          <ManagePlan />
        </Route>

        <Route path="*">
          <PageNotFound />
        </Route>
      </Switch>
    </OrdersContextProvider>
  );
};

export default OrderRoutes;
