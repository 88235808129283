import React, {useState} from 'react';

import { useViewCartContext } from "../../state/view-cart-context";

const Hamberger = ({ mobileNav, setMobileNav }) => {
  const { setViewCart } = useViewCartContext();

  return (
    <div
      className="hamberger-container"
      onClick={() => {
        setMobileNav(!mobileNav);
        setViewCart(false);
      }}
    >
      <div className="hamberger-content">
        <div
          className={
            mobileNav ? "hamberger-line-on hamberger-line" : "hamberger-line"
          }
        ></div>
        {/* <div
          className={hambergerOn ? "hamberger-mid-on" : "hamberger-mid"}
        ></div> */}
        {/* <div
          className={hambergerOn ? "hamberger-bottom-on" : "hamberger-bottom"}
        ></div> */}
      </div>
    </div>
  );
};

export default Hamberger;
