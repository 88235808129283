import React, { useState, useEffect } from "react";

import Button from "../Button";
import Spinner from "../Spinner";
import CheckoutCartItem from "../cart/CheckoutCartItem";

import { useCartContext } from "../../state/cart-context";
import { formatAmount, calculateCartAmount } from "../../helpers";

const OrderConfirmation = ({
  setOpenConfirmation,
  handleSignup,
  handleUpdateSubscription,
  address,
  loading,
  cancelLoading,
  signupLoading,
  addressLoading,
  discountToDisplay,
}) => {
  const { cart } = useCartContext();

  const { address1, address2, city, state, zipcode } = address;

  const [orderIdToUpdate, setOrderIdToUpdate] = useState(null);

  useEffect(() => {
    if (window.sessionStorage.getItem("orderIdToUpdate")) {
      const orderToUpdate = JSON.parse(
        window.sessionStorage.getItem("orderIdToUpdate")
      );

      setOrderIdToUpdate(orderToUpdate);
    }
  }, []);

  return (
    <div className="order-confirmation-backdrop">
      <div className="order-confirmation-container">
        <div className="oc-content-container">
          <div className="oc-head">
            <div className="oc-title">Order Confirmation</div>
            <div className="oc-close">
              <span onClick={() => setOpenConfirmation(false)}>X</span>
            </div>
          </div>
          <div className="oc-data">
            <div className="oc-data-left">
              <div className="oc-data-description">
                Please confirm your details to proceed with payment{" "}
              </div>
              <div className="oc-data-deliver-to">
                <span>Deliver to:</span>
                {address1} {address2}, {city}, {state} {zipcode}
              </div>
              <div className="oc-data-next-delivery">
                <span>Next Delivery:</span>
                7-10 Business day
              </div>
            </div>
            <div className="oc-data-right">
              {calculateCartAmount(cart) === 0 ? <div className='oc-data-spinner'><Spinner color="gray" /></div> : <>
              {cart?.map((item) => (
                <CheckoutCartItem key={item.id} cartItem={item} />
              ))}

              <div className="chp-subtotal">
                <div className="chp-subtotal-title">Subtotal</div>
                <div className="chpi-price">
                  ${formatAmount(calculateCartAmount(cart))}
                </div>
              </div>

              <div className="chp-shipping">
                <div className="chp-shipping-title">Shipping</div>
                <div className="chpi-price">Free</div>
              </div>

              <div className="chp-discount">
                {discountToDisplay ? (
                  <>
                    <div className="chp-discount-title">Discount</div>
                    <div className="chpi-price">
                      ${formatAmount(discountToDisplay)}
                    </div>
                  </>
                ) : undefined}
              </div>

              <div className="chp-total">
                <div className="chp-total-title">Total</div>
                {discountToDisplay ? (
                  <div className="chpi-price">
                    $
                    {formatAmount(
                      calculateCartAmount(cart) - discountToDisplay
                    )}
                  </div>
                ) : (
                  <div className="chpi-price">
                    ${formatAmount(calculateCartAmount(cart))}
                  </div>
                )}
              </div>
              </>
}
            </div>
          </div>
          <Button
            width={"100%"}
            height={"40px"}
            className="confirmation-btn"
            onClick={orderIdToUpdate ? handleUpdateSubscription : handleSignup}
            disabled={
              loading || signupLoading || addressLoading || cancelLoading
            }
            loading={
              loading || signupLoading || addressLoading || cancelLoading
            }
          >
            {orderIdToUpdate ? "confirm & update" : "confirm & checkout"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default OrderConfirmation;
