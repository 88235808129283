import React from "react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const BackButton = ({ pathname }) => {
  return (
    <Link to={pathname} className="back-btn">
      <FontAwesomeIcon icon={["fas", "long-arrow-alt-left"]} size="2x" />
    </Link>
  );
};

export default BackButton;
