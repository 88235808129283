export const isAdmin = (role) => role === "ADMIN" || role === "SUPER_ADMIN";
export const isClient = (role) => role === "CLIENT";
export const categories = ["Nuts", "Dried Fruits", "Seeds", "Mixed Nuts", "Gift Boxes"];
export const collections = [
  {
    title: "Paleo Diet Friendly",
    description:
      "Paleo-friendly snacking that never sacrifices taste: this rich assortment of nuts, seeds and mixes are loaded with the healthy fats you need & absolutely nothing that you don’t.",
    nospace: "paleoDiet",
  },
  {
    title: "Keto Friendly",
    description:
      "High in healthy fats and low in carbs, these keto-friendly delights are always safe to enjoy. And with options like Chilli Lime and BBQ, trust us, there’s a whole lot here to enjoy.",
    nospace: "ketoDiet",
  },
  {
    title: "Unsalted",
    description:
      "There’s nothing salty about these treats, and we do mean that literally. Selections from every category are available to help lower hunger and blood pressure alike.",
    nospace: "unsalted",
  },
  {
    title: "Heart Healthy",
    description:
      "From fruits to seeds to nuts, we’ve got options that are as good for your heart as they are for your tastebuds. These snacks prove health never has to mean compromising.",
    nospace: "heartHealthy",
  },
  {
    title: "High Fiber",
    description:
      "These fruits and mixes come loaded with energy to keep you full and lively, all day long. Enjoying these snacks daily is a shortcut to healthy digestion too tasty to pass up.",
    nospace: "highFiber",
  },
  {
    title: "Plant Based Protein",
    description:
      "If protein’s what you’re craving, we’ve got you covered - and roasted, salted, or buttered, just to name some tasty twists. Everything here is packed with big power & big flavor.",
    nospace: "plantBasedProtein",
  },
  {
    title: "Diabetic Friendly",
    description:
      "Regulating your blood sugar has never been more fulfilling - emphasis on filling. From seeds to nuts to fruits and mixes, we’ve got just the snack to satisfy you safely.",
    nospace: "diabeticFriendly",
  },
  {
    title: "Athlete Friendly",
    description:
      "Whatever goal you’re aiming for, these snacks make for an ideal training mate. Go higher, faster & further with a delicious assortment of fruits, seeds, nuts and trail mixes.",
    nospace: "athleteFriendly",
  },
];

export const testimonials = [
  {
    id: 1,
    testimonial:
      "“In my busy schedule, NutsInBox has made eating healthy even easier. You gotta try it out!”",
    name: "Carson",
  },
  {
    id: 2,
    testimonial:
      "“NutsInBox gave me a guilt-free solution to all of my snack desires. I love to cook and the nuts and dried fruit work as ingredients in a lot of recipes. Almost anything from NutsInBox is great on a salad.”",
    name: "Drake",
  },
  {
    id: 3,
    testimonial:
      "“The almonds are my favorite. With each order I’m trying something new and I’m never bored with what I get.It arrives in such a stylish box and each package has a cool design. I feel like I’m opening something really special every time.”",
    name: "Brandon",
  },
];

export const shipmentStatuses = [
  "Upcoming",
  "Preparing",
  "Shipped",
  "Delivered",
  "Canceled",
];

export const productTabs = [
  'AllProducts',
  "Nuts",
  "Dried Fruits",
  "Seeds",
  "Mixed Nuts",
];

export const orderTabs = [
  "Upcoming",
  "Preparing",
  "Shipped",
  // "Delivered",
  "Canceled",
  "All",
];

export const giftBoxes = [
  "Qan1SqYrtVVCYzhGk57q",
  "Z0pZCXKYkWAdjdacriT0",
  "2wDR8afhvLOzL5rug67R",
  "GcmC7Swfo4s8dSQxH4Tj",
];

export const subCoupons = [
  'getsub50',
]

export const gbCoupons = [
  'getgb20',
]

export const formatAmount = (amount) => {
  const amountStr = amount.toString();
  return amountStr.slice(0, -2) + "." + amountStr.slice(-2);
};

export const calculateCartQuantity = (cart) =>
  cart.reduce((qty, item) => qty + item.quantity, 0);

export const calculateCartAmount = (cart) =>
  cart.reduce(
    (amount, cartItem) => amount + cartItem.quantity * cartItem.item.price,
    0
  );

export const calculateTotalPages = (totalItems, perPage) =>
  Math.ceil(totalItems / perPage);
