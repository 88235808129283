import React, { createContext, useContext, useEffect, useState } from "react";

import { useAuthContext } from "../state/auth-context";

const WelcomeLeadStateContext = createContext(undefined);
const WelcomeLeadDispatchContext = createContext(undefined);

const WelcomeLeadContextProvider = ({ children }) => {
  const [welcomeLead, setWelcomeLead] = useState(null);

  const {
    authState: { userInfo },
  } = useAuthContext();

  useEffect(() => {

    if (!userInfo || !userInfo.shippingAddresses) {
      setWelcomeLead(null);
      window.localStorage.removeItem("welcomeLead");
    } else {
      const email = userInfo.email;
      const zipcode = userInfo.shippingAddresses[0].zipcode;

      const wlStr = JSON.stringify({
        email,
        zipcode,
      });

      setWelcomeLead({
        email,
        zipcode,
      });

      window.localStorage.setItem("welcomeLead", wlStr);
    }
  }, [userInfo]);

  return (
    <WelcomeLeadStateContext.Provider value={welcomeLead}>
      <WelcomeLeadDispatchContext.Provider value={setWelcomeLead}>
        {children}
      </WelcomeLeadDispatchContext.Provider>
    </WelcomeLeadStateContext.Provider>
  );
};

export default WelcomeLeadContextProvider;

export const useWelcomeLeadContext = () => {
  const welcomeLead = useContext(WelcomeLeadStateContext);
  const setWelcomeLead = useContext(WelcomeLeadDispatchContext);

  if (welcomeLead === undefined || setWelcomeLead === undefined)
    throw new Error(
      "useWelcomeLeadContext must be used within the WelcomeLeadContextProvider"
    );

  return { welcomeLead, setWelcomeLead };
};
