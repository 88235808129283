import React, { createContext, useContext, useState } from "react";

const PaymentPlanStateContext = createContext(undefined);
const PaymentPlanDispatchContext = createContext(undefined);

const PaymentPlanContextProvider = ({ children }) => {
  const [paymentPlan, setPaymentPlan] = useState(
    JSON.parse(window.localStorage.getItem("selectedPaymentPlan"))
  );
  return (
    <PaymentPlanStateContext.Provider value={paymentPlan}>
      <PaymentPlanDispatchContext.Provider value={setPaymentPlan}>
        {children}
      </PaymentPlanDispatchContext.Provider>
    </PaymentPlanStateContext.Provider>
  );
};

export default PaymentPlanContextProvider;

export const usePaymentPlanContext = () => {
  const paymentPlan = useContext(PaymentPlanStateContext);
  const setPaymentPlan = useContext(PaymentPlanDispatchContext);

  if (paymentPlan === undefined || setPaymentPlan === undefined)
    throw new Error(
      "usePaymentPlanContext must be used within the PaymentPlanContextProvider"
    );

  return { paymentPlan, setPaymentPlan };
};
