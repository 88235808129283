import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PageHeader from "../components/PageHeader";
import { useCartContext } from "../state/cart-context";
import { useCartLimitContext } from "../state/cart-limit-context";
import { usePaymentPlanContext } from "../state/payment-plan-context";
import { useManageCart } from "../hooks/useManageCart";
import { useUserUUIDContext } from "../state/user-id-context";
import { useWelcomeLeadContext } from "../state/welcome-lead-context";
import { useOrderDataContext } from "../state/order-data-context";

const ThankYou = () => {
  const { cart } = useCartContext();
  const { removeCartItem } = useManageCart();
  const { itemLimit, setItemLimit } = useCartLimitContext();
  const { paymentPlan, setPaymentPlan } = usePaymentPlanContext();
  const { userUUID, setUserUUID } = useUserUUIDContext();
  const { welcomeLead, setWelcomeLead } = useWelcomeLeadContext();
  const { orderData, setOrderData } = useOrderDataContext();

  const [sentNotification, setSentNotification] = useState(false)

  const deleteItem = async (item) => {
    await removeCartItem(item.item.id, item.user);
  };

  const omnisendWelcome = async () => {
    if (!orderData || !orderData.readyToSend) return;

    const url = "https://api.omnisend.com/v3/contacts";

    const data = JSON.stringify({
      identifiers: [
        {
          type: "email",
          id: orderData.email,
          channels: {
            email: {
              status: "subscribed",
              statusDate: new Date(),
            },
          },
        },
        {
          type: "phone",
          id: orderData.phone,
          channels: {
            sms: {
              status: "subscribed",
              statusDate: new Date(),
            },
          },
        },
      ],
      customProperties: {
        page: "thankyou",
        type: orderData.type,
        orderTotal: orderData.orderTotal,
      },
      firstName: orderData.firstName,
      lastName: orderData.lastName,
      postalCode: orderData.zipcode,
    });

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",

        "x-api-key": `${process.env.REACT_APP_OMNISEND_API_KEY}`,
      },
      body: data,
    });

    return response.json();
  };


  useEffect(() => {


    if (!orderData || sentNotification || window.sessionStorage.getItem('sentNotification')) return

    const data = {

      "service_id": "service_vizmk1o",
      "template_id": "template_yvr6ied",
      "user_id": `${process.env.REACT_APP_EMAILJS_API_KEY}`,
      "accessToken": `${process.env.REACT_APP_EMAILJS_ACCESS_TOKEN}`,
      "template_params": {
        "cus_firstname": orderData.firstName,
        "cus_lastname": orderData.lastName,
        "cus_phone": orderData.phone,
        "cus_email": orderData.email,
        "cus_type": orderData.type,
        "cus_total": orderData.orderTotal,
      }}

      fetch('https://api.emailjs.com/api/v1.0/email/send', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then(response => response.json())
      .then(data => {
        setSentNotification(true)
      })
      .catch((error) => {
        console.error('Error:', error);
        setSentNotification(true)
      });

      window.sessionStorage.setItem('sentNotification', 'true')

  }, [sentNotification, orderData])



  useEffect(() => {
    if (!orderData || !orderData.readyToSend) return;

    omnisendWelcome().then((data) => {
      setWelcomeLead(null);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderData]);

  useEffect(() => {
    if (!cart) return;

    cart.forEach((item) => {
      deleteItem(item);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart]);

  useEffect(() => {
    if (!itemLimit && !window.localStorage.getItem("selecteditemLimit")) return;

    setItemLimit(0);
    window.localStorage.removeItem("selecteditemLimit");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemLimit]);

  useEffect(() => {
    if (!paymentPlan && !window.localStorage.getItem("selectedPaymentPlan"))
      return;

    setPaymentPlan(null);
    window.localStorage.removeItem("selectedPaymentPlan");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentPlan]);

  useEffect(() => {
    if (!userUUID && !window.localStorage.getItem("UserID")) return;

    setUserUUID(null);
    window.localStorage.removeItem("UserID");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userUUID]);

  useEffect(() => {
    if (!welcomeLead && !window.localStorage.getItem("welcomeLead")) return;

    setWelcomeLead(null);
    window.localStorage.removeItem("welcomeLead");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [welcomeLead]);



  return (
    <>
      <PageHeader pageTitle={"Thank You"} />

      <div className="head-page-route">
        <Link to="/">
          <span>Home</span>
        </Link>{" "}
        <FontAwesomeIcon
          className="fa-icons"
          icon={["fas", "chevron-right"]}
          size="xs"
        />{" "}
        Thank you
      </div>

      <div className="ty-page-container">
        <div className="ty-content-title">Thanks for your order!</div>
        <div className="ty-content-description">Expect to receive an email from us shortly, letting you know when to expect your snacks to arrive - along with some other helpful information.</div>

      </div>
    </>
  );
};

export default ThankYou;
