import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProductItem from "../../components/products/ProductItem";

const ProductCarousel = ({
  title,
  description,
  items,
  link,
  browse,
  setOpenDialog,
}) => {
  return (
    <div className="carousel-container">
      <h1 className="carousel-title">{title}</h1>
      <div
        className={
          browse ? "carousel-browse-description" : "carousel-description"
        }
      >
        {description}
      </div>
      {link ? (
        <Link to={link}>
          <div className="carousel-viewAll">View All</div>
        </Link>
      ) : undefined}

      <div className="carousel-content-container">
        {/* -----Swiper -------- */}

        <div className="product-carousel-swiper">
          <Swiper
            loop={true}
            pagination={{
              clickable: true,
              observer: true,
              observeParents: true,
              parallax: true,
            }}
            navigation={true}
            draggable={true}
            breakpoints={{
              // Very Large Screen
              1536: {
                slidesPerView: 4,
                spaceBetween: 0,
              },
              // Large Screen
              1024: {
                slidesPerView: 3,
                spaceBetween: 0,
              },
              // Medium Screen
              640: {
                slidesPerView: 2,
                spaceBetween: 0,
              },
              // Small Screen
              320: {
                slidesPerView: 1,
                spaceBetween: 0,
              },
            }}
          >
            {items.map((product) => (
              <SwiperSlide key={product.title}>
                <ProductItem
                  key={product.id}
                  product={product}
                  setOpenDialog={setOpenDialog}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        {/* -----Swiper -------- */}
      </div>
    </div>
  );
};

export default ProductCarousel;
