import React, {forwardRef} from 'react';

const Input = forwardRef(({ label, error, type = 'text', ...props }, ref) => {
  return (
    <div className="form__input-container">
      <label htmlFor={label} className="form__input-lable">
        {label}
      </label>
      <input
        type={type}
        className="input"
        {...props}
        ref={ref}
      />
      {error && <p className="paragraph paragraph--error-small">{error}</p>}
    </div>
  );
});

export default Input;
