import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PageHeader from "../components/PageHeader";
import CheckoutSteps from "../components/CheckoutSteps";
import { usePaymentPlanContext } from "../state/payment-plan-context";
import { useCartLimitContext } from "../state/cart-limit-context";
import { useWelcomeLeadContext } from "../state/welcome-lead-context";
import { useCartContext } from "../state/cart-context";
import { useManageCart } from "../hooks/useManageCart";

const SelectPlan = () => {
  const { welcomeLead, setWelcomeLead } = useWelcomeLeadContext();
  const { setItemLimit } = useCartLimitContext();
  const { paymentPlan, setPaymentPlan } = usePaymentPlanContext();
  const { removeCartItem } = useManageCart();
  const { cart } = useCartContext();

  const [tempPaymentPlan, setTempPaymentPlan] = useState({
    type: "SUBSCRIPTION",
    interval: "week",
  });
  const [tempItemLimit, setTempItemLimit] = useState(10);

  const history = useHistory();

  useEffect(() => {
    const localWelcomeLead = JSON.parse(
      window.localStorage.getItem("welcomeLead")
    );
    if (!localWelcomeLead) {
      return;
    } else if (localWelcomeLead !== welcomeLead) {
      setWelcomeLead(localWelcomeLead);

      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!welcomeLead) history.push("/welcome");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [welcomeLead]);

  const omnisendWelcome = async () => {
    if (!welcomeLead) return;

    const url = "https://api.omnisend.com/v3/contacts";

    const data = JSON.stringify({
      identifiers: [
        {
          type: "email",
          id: welcomeLead.email,
          channels: {
            email: {
              status: "subscribed",
              statusDate: new Date(),
            },
          },
        },
      ],
      customProperties: {
        page: "welcome",
      },
      postalCode: welcomeLead.zipcode,
    });

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",

        "x-api-key": `${process.env.REACT_APP_OMNISEND_API_KEY}`,
      },
      body: data,
    });

    return response.json();
  };

  useEffect(() => {
    if (welcomeLead && welcomeLead.sentLead) return;

    omnisendWelcome().then((data) => {
      setWelcomeLead({
        ...welcomeLead,
        sentLead: true,
      });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [welcomeLead]);

  const deleteItem = async (item) => {
    const finished = await removeCartItem(item.item.id, item.user);

    if (finished) {
      setPaymentPlan(tempPaymentPlan);
      setItemLimit(tempItemLimit);

      const planStr = JSON.stringify(tempPaymentPlan);
      const itemLimitStr = JSON.stringify(tempItemLimit);

      window.localStorage.setItem("selectedPaymentPlan", planStr);
      window.localStorage.setItem("selecteditemLimit", itemLimitStr);

      history.push("/products");
    }
  };

  const handleSelectPlan = () => {
    if (!tempItemLimit || !tempPaymentPlan) {
      return;
    } else {
      if (cart && cart.length > 0 && paymentPlan === "ONETIME") {
        cart.forEach((item) => {
          deleteItem(item);
        });
      } else {
        setPaymentPlan(tempPaymentPlan);
        setItemLimit(tempItemLimit);

        const planStr = JSON.stringify(tempPaymentPlan);
        const itemLimitStr = JSON.stringify(tempItemLimit);

        window.localStorage.setItem("selectedPaymentPlan", planStr);
        window.localStorage.setItem("selecteditemLimit", itemLimitStr);

        history.push("/products");
      }
    }
  };

  return (
    <>
      <PageHeader pageTitle={"Select Plan"} />

      <div className="head-page-route">
        <Link to="/">
          <span>Home</span>
        </Link>{" "}
        <FontAwesomeIcon
          className="fa-icons"
          icon={["fas", "chevron-right"]}
          size="xs"
        />{" "}
        <Link to="/welcome">
          <span>Get Started</span>
        </Link>{" "}
        <FontAwesomeIcon
          className="fa-icons"
          icon={["fas", "chevron-right"]}
          size="xs"
        />{" "}
        Select Plan
      </div>

      <CheckoutSteps pageTitle={"Select Plan"} />

      <div className="select-plan-container">
        <div className="sp-head">
          <div className="sp-head-title">Subscription Packages</div>
          <div className="sp-head-description">
            Mix and match based on taste and dietary restrictions, choose your
            subscription, and receive convenient weekly deliveries to keep you
            and your family healthy.
          </div>
        </div>

        <div className="sp-plans">
          <div className="sp-item">
            <div className="sp-item-title">5 Packs</div>
            <div className="sp-item-img sub-1-img"></div>
            <div className="sp-item-description">
              <ul>
                <li>Flexible delivery plans</li>
                <li>Always free shipping</li>
              </ul>
            </div>
            <div className="sp-item-interval">
              <button
                onClick={() => {
                  setTempPaymentPlan({
                    type: "SUBSCRIPTION",
                    interval: "week",
                  });

                  setTempItemLimit(5);
                }}
                className={`${
                  tempPaymentPlan?.interval === "week" && tempItemLimit === 5
                    ? "interval-btn-disabled"
                    : "interval-btn-active"
                }`}
                disabled={
                  tempPaymentPlan?.interval === "week" && tempItemLimit === 5
                }
              >
                weekly
              </button>
              <button
                onClick={() => {
                  setTempPaymentPlan({
                    type: "SUBSCRIPTION",
                    interval: "month",
                  });

                  setTempItemLimit(5);
                }}
                className={`${
                  tempPaymentPlan?.interval === "month" && tempItemLimit === 5
                    ? "interval-btn-disabled"
                    : "interval-btn-active"
                }`}
                disabled={
                  tempPaymentPlan?.interval === "month" && tempItemLimit === 5
                }
              >
                monthly
              </button>
            </div>
          </div>
          <div className="sp-item">
            <div className="sp-item-title">10 Packs</div>
            <div className="sp-item-img sub-2-img"></div>
            <div className="sp-item-description">
              <ul>
                <li>Flexible delivery plans</li>
                <li>Always free shipping</li>
                <li>$10 off first box</li>
              </ul>
            </div>
            <div className="sp-item-interval">
              <button
                onClick={() => {
                  setTempPaymentPlan({
                    type: "SUBSCRIPTION",
                    interval: "week",
                  });

                  setTempItemLimit(10);
                }}
                className={`${
                  tempPaymentPlan?.interval === "week" && tempItemLimit === 10
                    ? "interval-btn-disabled"
                    : "interval-btn-active"
                }`}
                disabled={
                  tempPaymentPlan?.interval === "week" && tempItemLimit === 10
                }
              >
                weekly
              </button>
              <button
                onClick={() => {
                  setTempPaymentPlan({
                    type: "SUBSCRIPTION",
                    interval: "month",
                  });

                  setTempItemLimit(10);
                }}
                className={`${
                  tempPaymentPlan?.interval === "month" && tempItemLimit === 10
                    ? "interval-btn-disabled"
                    : "interval-btn-active"
                }`}
                disabled={
                  tempPaymentPlan?.interval === "month" && tempItemLimit === 10
                }
              >
                monthly
              </button>
            </div>
          </div>
          <div className="sp-item">
            <div className="sp-item-title">15 Packs</div>
            <div className="sp-item-img sub-3-img"></div>
            <div className="sp-item-description">
              <ul>
                <li>Flexible delivery plans</li>
                <li>Always free shipping</li>
                <li>$20 off first box</li>
              </ul>
            </div>
            <div className="sp-item-interval">
              <button
                onClick={() => {
                  setTempPaymentPlan({
                    type: "SUBSCRIPTION",
                    interval: "week",
                  });

                  setTempItemLimit(15);
                }}
                className={`${
                  tempPaymentPlan?.interval === "week" && tempItemLimit === 15
                    ? "interval-btn-disabled"
                    : "interval-btn-active"
                }`}
                disabled={
                  tempPaymentPlan?.interval === "week" && tempItemLimit === 15
                }
              >
                weekly
              </button>
              <button
                onClick={() => {
                  setTempPaymentPlan({
                    type: "SUBSCRIPTION",
                    interval: "month",
                  });

                  setTempItemLimit(15);
                }}
                className={`${
                  tempPaymentPlan?.interval === "month" && tempItemLimit === 15
                    ? "interval-btn-disabled"
                    : "interval-btn-active"
                }`}
                disabled={
                  tempPaymentPlan?.interval === "month" && tempItemLimit === 15
                }
              >
                monthly
              </button>
            </div>
          </div>
        </div>
        <button onClick={handleSelectPlan} className="plan-select-btn">
          select items >
        </button>
      </div>
    </>
  );
};

export default SelectPlan;
