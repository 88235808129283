import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PageHeader from "../components/PageHeader";
import CheckoutSteps from "../components/CheckoutSteps";
import { usePaymentPlanContext } from "../state/payment-plan-context";
import { useCartLimitContext } from "../state/cart-limit-context";
import ProductListing from "./ProductListing";
import { useCartContext } from "../state/cart-context";
import { useViewCartContext } from "../state/view-cart-context";
import { calculateCartQuantity } from "../helpers";
import { Helmet, HelmetProvider } from "react-helmet-async";

const Products = () => {
  const { itemLimit, setItemLimit } = useCartLimitContext();
  const { paymentPlan } = usePaymentPlanContext();
  const { cart } = useCartContext();
  const { setViewCart } = useViewCartContext();

  useEffect(() => {
    const localSelecteditemLimit = JSON.parse(
      window.localStorage.getItem("selecteditemLimit")
    );

    if (itemLimit === localSelecteditemLimit) return;

    setItemLimit(localSelecteditemLimit);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (cart && calculateCartQuantity(cart) === 0) setViewCart(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (cart && calculateCartQuantity(cart) >= itemLimit) setViewCart(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart]);

  return (
    <>



      <PageHeader
        pageTitle={itemLimit && paymentPlan ? "Select Items" : "All Products"}
      />

      <div className="head-page-route">
        <Link to="/">
          <span>Home</span>
        </Link>{" "}
        <FontAwesomeIcon
          className="fa-icons"
          icon={["fas", "chevron-right"]}
          size="xs"
        />{" "}
        {itemLimit && paymentPlan ? (
          <Link to="/welcome">
            <span>Get Started </span>
          </Link>
        ) : undefined}
        {itemLimit && paymentPlan ? (
          <FontAwesomeIcon
            className="fa-icons"
            icon={["fas", "chevron-right"]}
            size="xs"
          />
        ) : undefined}
        {itemLimit && paymentPlan && paymentPlan !== "ONETIME"
          ? " Select Items"
          : " All Products"}
      </div>

      {itemLimit && paymentPlan && paymentPlan !== "ONETIME" ? (
        <CheckoutSteps pageTitle={"Select Items"} />
      ) : undefined}

      <ProductListing />
    </>
  );
};

export default Products;
