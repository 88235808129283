import React, { useEffect, createContext, useContext, useState } from "react";

const CartLimitStateContext = createContext(undefined);
const CartLimitDispatchContext = createContext(undefined);

const CartLimitContextProvider = ({ children }) => {
  const [itemLimit, setItemLimit] = useState(0);

  const localLimit = JSON.parse(
    window.localStorage.getItem("selecteditemLimit")
  );

  useEffect(() => {
    if (!localLimit) return;

    setItemLimit(localLimit);
  }, [localLimit]);

  return (
    <CartLimitStateContext.Provider value={itemLimit}>
      <CartLimitDispatchContext.Provider value={setItemLimit}>
        {children}
      </CartLimitDispatchContext.Provider>
    </CartLimitStateContext.Provider>
  );
};

export default CartLimitContextProvider;

export const useCartLimitContext = () => {
  const itemLimit = useContext(CartLimitStateContext);
  const setItemLimit = useContext(CartLimitDispatchContext);

  if (itemLimit === undefined || setItemLimit === undefined)
    throw new Error(
      "useCartLimitContext must be used within the CartLimitContextProvider"
    );

  return { itemLimit, setItemLimit };
};
