import React from "react";
import { useHistory } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuthContext, openUserDropdown } from "../../state/auth-context";
import { useMobileNavContext } from "../../state/mobile-view-context";
import { isAdmin, isClient } from "../../helpers";

const MobileDropDown = () => {
  const {
    authDispatch,
    authState: { userInfo },
  } = useAuthContext();

  const { setMobileNav } = useMobileNavContext();

  const history = useHistory();

  return (
    <div className="mobile-sidebar-container">
      <div className="mobile-sidebar mobile-sidebar-show">
        <div className="mobile-sidebar-content">
          <div className="devider"></div>
          <div className="mobile-nav-item"></div>
          <div className="mobile-nav-item">
            <p
              onClick={() => {
                history.push("/products?cat=AllProducts&page=1");
                setMobileNav(false);
              }}
            >
              browse
            </p>
          </div>
          <div className="mobile-nav-item">
            <p
              onClick={() => {
                history.push("/gift-boxes");
                setMobileNav(false);
              }}
            >
              Gift Boxes
            </p>
          </div>
          <div className="mobile-nav-item">
            <p
              onClick={() => {
                history.push("/welcome");
                setMobileNav(false);
              }}
            >
              get started
            </p>
          </div>

          {userInfo ? (
            <div className="mobile-nav-item">
              <FontAwesomeIcon
                className="mobile-account-icon"
                icon={["fas", "user-circle"]}
                size="2x"
                onClick={() => {
                  if (userInfo && isAdmin(userInfo?.role)) {
                    authDispatch(openUserDropdown(true));
                    setMobileNav(false);
                  } else if (userInfo && isClient(userInfo?.role)) {
                    history.push("/account");
                    setMobileNav(false);
                  } else {
                    return;
                  }
                }}
              />
            </div>
          ) : (
            <div className="mobile-nav-item">
              <p
                onClick={() => {
                  history.push("/login");
                  setMobileNav(false);
                }}
              >
                login
              </p>
            </div>
          )}

          <div
            className="mobile-nav-item mobile-logo"
            onClick={() => {
              history.push("/");
              setMobileNav(false);
            }}
          >
            <div className="mobile-nutsinbox-logo"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileDropDown;
