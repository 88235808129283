import React from "react";

import ToggleSection from "./ToggleSection";

const NutritionFacts = ({ product }) => {
  const analyzeNFValue = (value, index) => {
    if (index < 0 || index > 1) return "";

    const valueArr = value.split(" ");

    if (valueArr.length > 2 || valueArr.length < 1) return "";

    if (valueArr.length === 2 && !valueArr[1].includes("%")) return "";

    if (index == 0) {
      if (valueArr[0].includes("%")) {
        return "";
      } else {
        return valueArr[0];
      }
    } else if (index == 1) {
      if (valueArr.length == 1 && valueArr[0].includes("%")) return valueArr[0];

      if (!valueArr[1] || !valueArr[1].includes("%")) {
        return "";
      } else {
        return valueArr[1];
      }
    }
  };

  return (
    <div>
      <ToggleSection title={"Nutrition Facts:"}>
        <div className="nf-content">
          <div className="nf-heading nf-servingSize">{`Serving Size: ${product.servingSize}`}</div>

          <div className="nf-heading nf-row">Amount per serving</div>
          <div className="nf-row">
            <div className="nf-item nf-item-title">Calories per serving</div>
            <div className="nf-item">{analyzeNFValue(product.calories, 0)}</div>
          </div>
          <div className="nf-row"></div>
          <div className="nf-row">
            <div className="nf-heading daily-value">%Daily Value*</div>
          </div>
          <div className="nf-row">
            <div className="nf-item nf-item-title">Total Fat</div>
            <div className="nf-item nf-item-mid">
              {analyzeNFValue(product.totalFat, 0)}
            </div>
            <div className="nf-item nf-item-last">
              {analyzeNFValue(product.totalFat, 1)}
            </div>
          </div>
          <div className="nf-row">
            <div className="nf-item nf-item-title">Saturated Fat</div>
            <div className="nf-item nf-item-mid">
              {analyzeNFValue(product.saturatedFat, 0)}
            </div>
            <div className="nf-item nf-item-last">
              {analyzeNFValue(product.saturatedFat, 1)}
            </div>
          </div>
          <div className="nf-row">
            <div className="nf-item nf-item-title">Trans Fat</div>
            <div className="nf-item nf-item-mid">
              {analyzeNFValue(product.transFat, 0)}
            </div>
            <div className="nf-item nf-item-last">
              {analyzeNFValue(product.transFat, 1)}
            </div>
          </div>
          <div className="nf-row">
            <div className="nf-item nf-item-title">Cholesterol</div>
            <div className="nf-item nf-item-mid">
              {analyzeNFValue(product.cholesterol, 0)}
            </div>
            <div className="nf-item nf-item-last">
              {analyzeNFValue(product.cholesterol, 1)}
            </div>
          </div>
          <div className="nf-row">
            <div className="nf-item nf-item-title">Sodium</div>
            <div className="nf-item nf-item-mid">
              {analyzeNFValue(product.sodium, 0)}
            </div>
            <div className="nf-item nf-item-last">
              {analyzeNFValue(product.sodium, 1)}
            </div>
          </div>
          <div className="nf-row">
            <div className="nf-item nf-item-title">Total Carb</div>
            <div className="nf-item nf-item-mid">
              {analyzeNFValue(product.totalCarbohydrate, 0)}
            </div>
            <div className="nf-item nf-item-last">
              {analyzeNFValue(product.totalCarbohydrate, 1)}
            </div>
          </div>
          <div className="nf-row">
            <div className="nf-item nf-item-title">Dietary Fiber</div>
            <div className="nf-item nf-item-mid">
              {analyzeNFValue(product.dietaryFiber, 0)}
            </div>
            <div className="nf-item nf-item-last">
              {analyzeNFValue(product.dietaryFiber, 1)}
            </div>
          </div>
          <div className="nf-row">
            <div className="nf-item nf-item-title">Total Sugars</div>
            <div className="nf-item nf-item-mid">
              {analyzeNFValue(product.totalSugars, 0)}
            </div>
            <div className="nf-item nf-item-last">
              {analyzeNFValue(product.totalSugars, 1)}
            </div>
          </div>
          <div className="nf-row">
            <div className="nf-item nf-item-title">Added Sugars</div>
            <div className="nf-item nf-item-mid">
              {analyzeNFValue(product.addedSugars, 0)}
            </div>
            <div className="nf-item nf-item-last">
              {analyzeNFValue(product.addedSugars, 1)}
            </div>
          </div>
          <div className="nf-row">
            <div className="nf-item nf-item-title">Protein</div>
            <div className="nf-item nf-item-mid">
              {analyzeNFValue(product.protein, 0)}
            </div>
            <div className="nf-item nf-item-last">
              {analyzeNFValue(product.protein, 1)}
            </div>
          </div>
          <div className="nf-row">
            <div className="nf-item nf-item-title">Vitamin A</div>
            <div className="nf-item nf-item-mid">
              {analyzeNFValue(product.vitaminA, 0)}
            </div>
            <div className="nf-item nf-item-last">
              {analyzeNFValue(product.vitaminA, 1)}
            </div>
          </div>
          <div className="nf-row">
            <div className="nf-item nf-item-title">Vitamin C</div>
            <div className="nf-item nf-item-mid">
              {analyzeNFValue(product.vitaminC, 0)}
            </div>
            <div className="nf-item nf-item-last">
              {analyzeNFValue(product.vitaminC, 1)}
            </div>
          </div>
          <div className="nf-row">
            <div className="nf-item nf-item-title">Vitamin D</div>
            <div className="nf-item nf-item-mid">
              {analyzeNFValue(product.vitaminD, 0)}
            </div>
            <div className="nf-item nf-item-last">
              {analyzeNFValue(product.vitaminD, 1)}
            </div>
          </div>
          <div className="nf-row">
            <div className="nf-item nf-item-title">Calcium</div>
            <div className="nf-item nf-item-mid">
              {analyzeNFValue(product.calcium, 0)}
            </div>
            <div className="nf-item nf-item-last">
              {analyzeNFValue(product.calcium, 1)}
            </div>
          </div>
          <div className="nf-row">
            <div className="nf-item nf-item-title">Iron</div>
            <div className="nf-item nf-item-mid">
              {analyzeNFValue(product.iron, 0)}
            </div>
            <div className="nf-item nf-item-last">
              {analyzeNFValue(product.iron, 1)}
            </div>
          </div>
          <div className="nf-row">
            <div className="nf-item nf-item-title">Potassium</div>
            <div className="nf-item nf-item-mid">
              {analyzeNFValue(product.potassium, 0)}
            </div>
            <div className="nf-item nf-item-last">
              {analyzeNFValue(product.potassium, 1)}
            </div>
          </div>

          <p>{product.ingredients}</p>
        </div>
      </ToggleSection>
    </div>
  );
};

export default NutritionFacts;
