import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PageHeader from "../components/PageHeader";
import { useBlogsContext } from "../state/blogs-context";
import { Helmet, HelmetProvider } from "react-helmet-async";

const BlogContent = () => {
  const {
    blogsState: { blogs },
  } = useBlogsContext();

  const [blog, setBlog] = useState();

  const params = useParams();

  useEffect(() => {
    if (!blogs) return;

    const blogItem = blogs.find((item) => item.id === params.id);

    if (blogItem) {
      setBlog(blogItem);
    } else {
      setBlog(undefined);
    }
  }, [params, blogs]);

  return (
    <>
      <HelmetProvider>
        {blog && (
          <Helmet>
            <title>{blog.metatitle}</title>
            <meta name="description" content={blog.metadescription} />
            <meta name="keywords" content={blog.keywords} />
          </Helmet>
        )}
      </HelmetProvider>

      <PageHeader pageTitle={!blog ? "Blogs" : blog.title} />

      <div className="head-page-route">
        <Link to="/">
          <span>Home</span>
        </Link>{" "}
        <FontAwesomeIcon
          className="fa-icons"
          icon={["fas", "chevron-right"]}
          size="xs"
        />{" "}
        <Link to="/blogs">
          <span>Blogs</span>
        </Link>{" "}
        <FontAwesomeIcon
          className="fa-icons"
          icon={["fas", "chevron-right"]}
          size="xs"
        />
        {!blog ? false : blog.title}
      </div>

      <div class="blog-img">
        {!blog ? false : <img src={blog.imgurl} alt={blog.imgalt} />}
      </div>
      <div class="blog-date">
        {!blog ? false : blog.date}
        <span class="blog-author">
          <a href="https://nutsinbox.com/">NutsInBox</a>
        </span>
      </div>

      {!blog ? (
        false
      ) : (
        <div>
          <div dangerouslySetInnerHTML={{ __html: blog.content }} />
        </div>
      )}
    </>
  );
};

export default BlogContent;
