import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import ToggleSection from "../components/toggle-sections/ToggleSection";
import Spinner from "../components/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PageHeader from "../components/PageHeader";
import AccountNav from "../components/nav/AccountNav";
import OrderItem from "../components/orders/OrderItem";
import Tab from "../components/Tabs";
import { useOrdersContext } from "../state/orders-context";
import { useSelectTab } from "../hooks/useSelectTab";
import { orderTabs } from "../helpers";

export const orderTabType = "type";

const Orders = () => {
  const {
    ordersState: { orders, loading, error },
  } = useOrdersContext();
  const { activeTab } = useSelectTab(orderTabType, "Upcoming");

  const [ordersByTab, setOrdersByTab] = useState(
    orders
      ? orders.filter((order) => order.shipmentStatus === "Upcoming")
      : null
  );

  useEffect(() => {
    if (!orders) {
      setOrdersByTab(null);
      return;
    }

    if (activeTab === "All") setOrdersByTab(orders);
    else
      setOrdersByTab(
        orders.filter((order) => order.shipmentStatus === activeTab)
      );
  }, [activeTab, orders, setOrdersByTab]);

  if (loading) return <Spinner color="gray" height={50} width={50} />;

  if (error) return <h2 className="header--center">{error}</h2>;

  if (!orders || orders.length === 0)
    return <h2 className="header--center">You have no orders.</h2>;

  return (
    <>
      <PageHeader pageTitle={"Order History"} />

      <div className="head-page-route">
        <Link to="/">
          <span>Home</span>
        </Link>{" "}
        <FontAwesomeIcon
          className="fa-icons"
          icon={["fas", "chevron-right"]}
          size="xs"
        />{" "}
        Order History
      </div>

      <div className="account-pages-container">
        <div className="acc-left">
          <div className="acc-mobile">
            <ToggleSection title={"Account Menu"}>
              <AccountNav currentPage={"orders"} />
            </ToggleSection>
          </div>

          <div className="acc-desktop">
            <AccountNav currentPage={"orders"} />
          </div>
        </div>
        <div className="acc-right">
          <div className="orders-container">
            <div className="orders-title">order history</div>
            <div className="orders-tab-container">
              <div className="orders-tabs">
                {orderTabs.map((tab) => (
                  <Tab
                    key={tab}
                    label={tab}
                    activeTab={activeTab}
                    tabType={orderTabType}
                  />
                ))}
              </div>
            </div>
            <div className="orders-details">
              <div className="orders-content">
                <div className="orders-column">
                  <h3 className="header--center">Date</h3>
                </div>
                <div className="orders-column">
                  <h3 className="header--center">Quantity</h3>
                </div>
                <div className="orders-column orders-column-total-desktop">
                  <h3 className="header--center">Total ($)</h3>
                </div>
                <div className="orders-column orders-column-type-desktop">
                  <h3 className="header--center">Payment Type</h3>
                </div>
                <div className="orders-column">
                  <h3 className="header--center">Shipment Status</h3>
                </div>
              </div>

              {ordersByTab &&
                ordersByTab.map((order) => (
                  <OrderItem key={order.id} order={order} />
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Orders;
