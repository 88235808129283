import React from "react";
import { Switch, Route } from "react-router-dom";

import Index from "../pages/Index";
import Products from "../pages/Products";
import Welcome from '../pages/Welcome'
import SelectPlan from "../pages/SelectPlan";
import Collection from '../pages/Collection';
import ProductDetail from "../pages/ProductDetail";
import PageNotFound from "../pages/PageNotFound";
import BuyRoutes from "./BuyRoutes";
import OrderRoutes from "./OrderRoutes";
import AdminRoutes from "./AdminRoutes";
import PrivateRoute from './PrivateRoute'
import Login from "../pages/Login";
import GiftBoxes from "../pages/GiftBoxes";
import ThankYou from "../pages/ThankYou";
import Blogs from '../pages/Blogs'
import BlogContent from '../pages/BlogContent'
import MyAccount from "../pages/MyAccount";
import ScrollToTop from '../components/ScrollToTop'
import Contact from "../pages/Contact";

const Routes = () => {
  return (
    <>
    <ScrollToTop />
    <Switch>
      <Route path="/buy">
          <BuyRoutes />
      </Route>

      <Route path="/orders">
        <PrivateRoute>
          <OrderRoutes />
        </PrivateRoute>
      </Route>

      <Route path="/account">
        <PrivateRoute>
          <MyAccount />
        </PrivateRoute>
      </Route>

      <Route path="/admin">
        <PrivateRoute>
          <AdminRoutes />
        </PrivateRoute>
      </Route>

      <Route path="/welcome">
        <Welcome />
      </Route>

      <Route path="/select-plan">
        <SelectPlan />
      </Route>

      <Route path="/products/:productId">
        <ProductDetail />
      </Route>

      <Route path="/products">
        <Products />
      </Route>

      <Route path="/collections/:collection">
        <Collection />
      </Route>

      <Route path="/gift-boxes">
        <GiftBoxes />
      </Route>

      <Route path="/blogs/:id">
        <BlogContent />
      </Route>

      <Route path="/blogs">
        <Blogs />
      </Route>


      <Route path="/login">
        <Login />
      </Route>

      <Route path="/thankyou">
        <ThankYou />
      </Route>

      <Route path="/contact">
        <Contact />
      </Route>

      <Route exact path="/">
        <Index />
      </Route>

      <Route path="*">
        <PageNotFound />
      </Route>
    </Switch>
    </>
  );
};

export default Routes;
