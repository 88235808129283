import { useState } from "react";

import { functions } from "../firebase/config";
import { ordersRef } from "../firebase";

export const useManageSubscription = () => {
  const [cancelLoading, setCancelLoading] = useState(false);
  const [cancelError, setCancelError] = useState("");
  const [pauseLoading, setPauseLoading] = useState(false);
  const [pauseError, setPauseError] = useState("");

  const cancel = async (subscriptionId, orderId) => {
    setCancelLoading(true);

    try {
      // 1. Call the cloud function, to get a client secret
      const cancelSubscription = functions.httpsCallable("cancelSubscription");

      const subscriptionData = await cancelSubscription({
        subscriptionId,
      });

      // Update the order to cancel
      await ordersRef.doc(orderId).set(
        {
          paymentStatus: "Canceled",
          shipmentStatus: "Canceled",
        },
        { merge: true }
      );

      setCancelLoading(false);
      setCancelError("");

      return { subscription: subscriptionData.data.subscription };
    } catch (error) {
      setCancelLoading(false);
      setCancelError("Sorry, something went wrong.");
      return false;
    }
  };

  const pause = async (subscriptionId, orderId) => {
    setPauseLoading(true);

    try {
      // 1. Call the cloud function
      const pauseSubscription = functions.httpsCallable("pauseSubscription");

      const subscriptionData = await pauseSubscription({
        subscriptionId,
      });

      // Update the order to cancel
      await ordersRef.doc(orderId).set(
        {
          paymentStatus: "Pause",
          shipmentStatus: "Pause",
        },
        { merge: true }
      );

      setPauseLoading(false);
      setPauseError("");

      return { subscription: subscriptionData.data.subscription };
    } catch (error) {
      setPauseLoading(false);
      setPauseError("Sorry, something went wrong.");
      return false;
    }
  };

  return {
    cancel,
    cancelLoading,
    cancelError,
    pause,
    pauseLoading,
    pauseError,
  };
};
