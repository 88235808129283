import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TestimonialItem from "../TestimonialItem";
import { testimonials } from "../../helpers";

const TestimonialsCarousel = () => {
  const [count, setCount] = useState(0);
  const [translateX, setTranslateX] = useState(0);

//   return (
//     <div className="testimonials-carousel-container">
//       <div className="testimonial-desktop">
//         <div className="testimonial-backdrop">
//           <div className="testimonial-carousel container">
//             <div
//               style={{ transform: `translateX(${translateX}px)` }}
//               className="carousel-content"
//             >
//               {testimonials.map((item) => (
//                 <TestimonialItem
//                   key={item.id}
//                   testimonial={item.testimonial}
//                   name={item.name}
//                 />
//               ))}
//             </div>
//           </div>
//           <div className="carousel-btns">
//             <div className="carousel-back-btn">
//               <FontAwesomeIcon
//                 onClick={() => {
//                   if (count - 1 < 0) {
//                     setCount(testimonials.length - 1);
//                     setTranslateX((testimonials.length - 1) * -850);
//                   } else {
//                     setCount(count - 1);
//                     setTranslateX(translateX + 850);
//                   }
//                 }}
//                 className="carousel-btn"
//                 icon={["fas", "long-arrow-alt-left"]}
//                 size="2x"
//               />
//             </div>
//             <div className="carousel-next-btn">
//               <FontAwesomeIcon
//                 onClick={() => {
//                   if (count + 1 >= testimonials.length) {
//                     setCount(0);
//                     setTranslateX(0);
//                   } else {
//                     setCount(count + 1);
//                     setTranslateX(translateX - 850);
//                   }
//                 }}
//                 className="carousel-btn"
//                 icon={["fas", "long-arrow-alt-right"]}
//                 size="2x"
//               />
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="testimonial-mobile">
//         <div className="testimonial-backdrop-mobile">
//           <div className="testimonial-carousel container">
//             <div
//               style={{ transform: `translateX(${translateX}%)` }}
//               className="carousel-content"
//             >
//               {testimonials.map((item) => (
//                 <TestimonialItem
//                   key={item.id}
//                   testimonial={item.testimonial}
//                   name={item.name}
//                 />
//               ))}
//             </div>
//           </div>
//           <div className="carousel-btns">
//             <div className="carousel-back-btn">
//               <FontAwesomeIcon
//                 onClick={() => {
//                   if (count - 1 < 0) {
//                     setCount(testimonials.length - 1);
//                     setTranslateX((testimonials.length - 1) * -100);
//                   } else {
//                     setCount(count - 1);
//                     setTranslateX(translateX + 100);
//                   }
//                 }}
//                 className="carousel-btn"
//                 icon={["fas", "long-arrow-alt-left"]}
//                 size="2x"
//               />
//             </div>
//             <div className="carousel-next-btn">
//               <FontAwesomeIcon
//                 onClick={() => {
//                   if (count + 1 >= testimonials.length) {
//                     setCount(0);
//                     setTranslateX(0);
//                   } else {
//                     setCount(count + 1);
//                     setTranslateX(translateX - 100);
//                   }
//                 }}
//                 className="carousel-btn"
//                 icon={["fas", "long-arrow-alt-right"]}
//                 size="2x"
//               />
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default TestimonialsCarousel;


  return (
    <div className="testimonials-carousel-container">
      <div className="testimonial-desktop">
        <div className="testimonial-backdrop">
          <div className="testimonial-carousel container">
            <div
              style={{ transform: `translateX(${translateX}px)` }}
              className="carousel-content"
            >
              {testimonials.map((item) => (
                <TestimonialItem
                  key={item.id}
                  testimonial={item.testimonial}
                  name={item.name}
                />
              ))}
            </div>
          </div>
          <div className="testominal-carousel-btns">
            <div className="testominal-carousel-back-btn">
              <FontAwesomeIcon
                onClick={() => {
                  if (count - 1 < 0) {
                    setCount(testimonials.length - 1);
                    setTranslateX((testimonials.length - 1) * -850);
                  } else {
                    setCount(count - 1);
                    setTranslateX(translateX + 850);
                  }
                }}
                className="carousel-btn"
                icon={["fas", "chevron-left"]}
                size="2x"
              />
            </div>
            <div className="testominal-carousel-next-btn">
              <FontAwesomeIcon
                onClick={() => {
                  if (count + 1 >= testimonials.length) {
                    setCount(0);
                    setTranslateX(0);
                  } else {
                    setCount(count + 1);
                    setTranslateX(translateX - 850);
                  }
                }}
                className="carousel-btn"
                icon={["fas", "chevron-right"]}
                size="2x"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="testimonial-mobile">
        <div className="testimonial-backdrop-mobile">
          <div className="testimonial-carousel container">
            <div
              style={{ transform: `translateX(${translateX}%)` }}
              className="carousel-content"
            >
              {testimonials.map((item) => (
                <TestimonialItem
                  key={item.id}
                  testimonial={item.testimonial}
                  name={item.name}
                />
              ))}
            </div>
          </div>
          <div className="testominal-carousel-btns-mobile">
            <div className="testominal-carousel-back-btn">
              <FontAwesomeIcon
                onClick={() => {
                  if (count - 1 < 0) {
                    setCount(testimonials.length - 1);
                    setTranslateX((testimonials.length - 1) * -100);
                  } else {
                    setCount(count - 1);
                    setTranslateX(translateX + 100);
                  }
                }}
                className="carousel-btn"
                icon={["fas", "chevron-left"]}
                size="2x"
              />
            </div>
            <div className="testominal-carousel-next-btn">
              <FontAwesomeIcon
                onClick={() => {
                  if (count + 1 >= testimonials.length) {
                    setCount(0);
                    setTranslateX(0);
                  } else {
                    setCount(count + 1);
                    setTranslateX(translateX - 100);
                  }
                }}
                className="carousel-btn"
                icon={["fas", "chevron-right"]}
                size="2x"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialsCarousel;
