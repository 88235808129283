import React, { createContext, useContext, useState } from "react";

const ViewCartStateContext = createContext(undefined);
const ViewCartDispatchContext = createContext(undefined);

const ViewCartContextProvider = ({ children }) => {
  const [viewCart, setViewCart] = useState(false);
  return (
    <ViewCartStateContext.Provider value={viewCart}>
      <ViewCartDispatchContext.Provider value={setViewCart}>
        {children}
      </ViewCartDispatchContext.Provider>
    </ViewCartStateContext.Provider>
  );
};

export default ViewCartContextProvider;

export const useViewCartContext = () => {
  const viewCart = useContext(ViewCartStateContext);
  const setViewCart = useContext(ViewCartDispatchContext);

  if (viewCart === undefined || setViewCart === undefined)
    throw new Error(
      "useViewCartContext must be used within the ViewCartContextProvider"
    );

  return { viewCart, setViewCart };
};
