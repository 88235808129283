import React from "react";
import {useHistory} from 'react-router-dom'

import CTA from './CTA'

const Slider = () => {

  const history = useHistory()

  return (
    <div className="slider">
      <div className="slide">
        <div className="slide-content">
          <div className="slider-logo"></div>
          <h1 className="slider-headline1">
            Healthy Snacks Delivered Straight To You!
          </h1>
          <div className="slider-headline2">
            Nutsinbox delivers hand-picked nuts, dried fruit, and gummies all
            made from premium ingredients and delivered straight to your front
            door.
          </div>
          <button
            onClick={() => history.push("/welcome")}
            className="slider-btn"
          >
            get started
          </button>
        </div>
      </div>

      <CTA
        className="slider-cta"
        style={{
          transform: 'translate(-1%, -70px)',
          width: '102%',
        }}
      />
    </div>
  );
};

export default Slider;
