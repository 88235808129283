import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Button from "../Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  formatAmount,
  calculateCartAmount,
  calculateCartQuantity,
} from "../../helpers";

const ManageOrderItem = ({
  order: {
    id,
    createdAt,
    items,
    paymentType,
    shipmentStatus,
    subscriptionStartDate,
    user: { name },
  },
}) => {
  const [newOrderDate, setNewOrderDate] = useState();

  useEffect(() => {
    if (!subscriptionStartDate) return;

    const t = new Date(1970, 0, 1); // Epoch
    t.setSeconds(subscriptionStartDate);

    setNewOrderDate(t.toDateString());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionStartDate]);

  return (
    <Link to={`/admin/manage-orders/${id}`}>
      <div className="orders-content orders-content--content">
        <div className="orders-column">
          <p className="paragraph--center paragraph--focus">
            {paymentType === "ONETIME"
              ? createdAt.toDate().toDateString()
              : newOrderDate
              ? newOrderDate
              : createdAt.toDate().toDateString()}
          </p>
        </div>
        <div className="orders-column orders-column--hide">
          <p className="paragraph--center paragraph--focus">
            {calculateCartQuantity(items)}
          </p>
        </div>
        <div className="orders-column orders-column--hide">
        <p className="paragraph--center paragraph--focus">{paymentType}</p>
        </div>
        <div className="orders-column">
          <p className="paragraph--center paragraph--focus">
            {formatAmount(calculateCartAmount(items))}
          </p>
        </div>
        <div className="orders-column orders-column--hide">
          <p
            className="paragraph--center paragraph--focus"
            style={{
              color:
                shipmentStatus === "Upcoming"
                  ? "blue"
                  : shipmentStatus === "Preparing"
                  ? "#f2827f"
                  : shipmentStatus === "Shipped"
                  ? "green"
                  : shipmentStatus === "Delivered"
                  ? "gray"
                  : shipmentStatus === "Canceled"
                  ? "red"
                  : undefined,
            }}
          >
            {shipmentStatus ? shipmentStatus : ""}
          </p>
        </div>
        <div className="orders-column orders-column-buyer">
          <p className="paragraph--center paragraph--focus">{name}</p>
        </div>
        <div className="orders-column orders-column--manage">
          <p className="paragraph--center paragraph--focus">
            {shipmentStatus === "Delivered" ? (
              "Done"
            ) : (
              <Button
                width="60%"
                className="btn--orange manage-order-btn-mobile"
              >
                <FontAwesomeIcon icon={["fas", "edit"]} size="1x" />
              </Button>
            )}
          </p>
        </div>
      </div>
    </Link>
  );
};

export default ManageOrderItem;
