import React, { useState } from "react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PageHeader from "../components/PageHeader";
import Button from "../components/Button";
import Input from "../components/Input";
import emailjs from "emailjs-com";

const Contact = () => {
  const [sendLoading, setSendLoading] = useState(false);

  const handleContactFormSubmission = (e) => {
    setSendLoading(true);

    e.preventDefault();

    emailjs
      .sendForm(
        "service_vizmk1o",
        "template_sx9red5",
        e.target,
        process.env.REACT_APP_EMAILJS_API_KEY
      )
      .then(
        (result) => {

          const confirmationMsg = document.querySelector(
            ".contact-confirmation"
          );

          confirmationMsg.classList.remove("hide");
          setSendLoading(false);

          window.setTimeout(() => {
            confirmationMsg.classList.add("hide");
          }, 4000);
        },
        (error) => {
          setSendLoading(false);
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  return (
    <>
      <PageHeader pageTitle={"Contact Us"} />

      <div className="head-page-route">
        <Link to="/">
          <span>Home</span>
        </Link>{" "}
        <FontAwesomeIcon
          className="fa-icons"
          icon={["fas", "chevron-right"]}
          size="xs"
        />{" "}
        Contact Us
      </div>

      <form className="form" onSubmit={handleContactFormSubmission}>
        <Input
          name="from_name"
          placeholder="Full Name"
          required
          className="contact-input"
        />

        <Input
          name="from_email"
          placeholder="Email Address"
          type="email"
          required
          className="contact-input"
        />

        <Input
          name="from_phone"
          placeholder="Phone Number"
          className="contact-input"
        />

        <textarea
          name="from_message"
          id=""
          cols="30"
          rows="10"
          placeholder="Message"
          required
          className="contact-textarea"
        />

        <Button
          className="cta-btn"
          width="100%"
          height="100%"
          style={{ margin: "0.5rem 0" }}
          loading={sendLoading}
        >
          Submit
        </Button>
      </form>
      <div className="confirmation-msg-container">
        <div className="contact-confirmation hide">Message sent!</div>
      </div>
    </>
  );
};

export default Contact;
