import React from "react";
import { Link, useLocation } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Pagination = ({ page, totalPages, tabType, activeTab }) => {
  const { pathname } = useLocation();

  return (
    <div className="pagination">
      <Link
        to={
          tabType
            ? `${pathname}?${tabType}=${activeTab}&page=${
                page > 1 ? page - 1 : 1
              }`
            : `${pathname}?page=${page > 1 ? page - 1 : 1}`
        }
        className="pagination__page"
        style={{ cursor: page === 1 ? "not-allowed" : undefined }}
        onClick={page === 1 ? (e) => e.preventDefault() : undefined}
      >
        <FontAwesomeIcon
          className={page !== 1 ? "orange-icon" : undefined}
          icon={["fas", "long-arrow-alt-left"]}
          size="2x"
        />
      </Link>

      <div className="page-total">
        <p className="paragraph--center">
          {page} of {totalPages}
        </p>
      </div>

      <Link
        to={
          tabType
            ? `${pathname}?${tabType}=${activeTab}&page=${
                page < totalPages ? page + 1 : page
              }`
            : `${pathname}?page=${page < totalPages ? page + 1 : page}`
        }
        className="pagination__page"
        style={{
          cursor:
            page === totalPages || totalPages === 0 ? "not-allowed" : undefined,
        }}
        onClick={page === totalPages ? (e) => e.preventDefault() : undefined}
      >
        <FontAwesomeIcon
          className={
            page === totalPages || totalPages === 0 ? undefined : "orange-icon"
          }
          icon={["fas", "long-arrow-alt-right"]}
          size="2x"
        />
      </Link>
    </div>
  );
};

export default Pagination;
