import React from "react";
import { Link } from "react-router-dom";

import { useAuthContext, signoutRedirect } from "../../state/auth-context";
import { useAuthenticate } from "../../hooks/useAuthenticate";

const AccountNav = ({ currentPage }) => {
  const { signout } = useAuthenticate();
  const { authDispatch } = useAuthContext();

  return (
    <div className="acc-nav">
      <Link to={"/account"}>
        <div
          className={
            currentPage === "home"
              ? "acc-nav-item display-acc-nav-dash"
              : "acc-nav-item hide-acc-nav-dash"
          }
        >
          <span>- </span>
          Home
        </div>
      </Link>
      <Link to={"/orders/my-plans"}>
        <div
          className={
            currentPage === "plan"
              ? "acc-nav-item display-acc-nav-dash"
              : "acc-nav-item hide-acc-nav-dash"
          }
        >
          <span>- </span>
          Manage Plan
        </div>
      </Link>
      <Link to={"/orders/my-orders"}>
        <div
          className={
            currentPage === "orders"
              ? "acc-nav-item display-acc-nav-dash"
              : "acc-nav-item hide-acc-nav-dash"
          }
        >
          <span>- </span>
          Order History
        </div>
      </Link>
      {/* <Link to={"#"}>
        <div
          className={
            currentPage === "info"
              ? "acc-nav-item display-acc-nav-dash"
              : "acc-nav-item hide-acc-nav-dash"
          }
        >
          <span>- </span>
          Account Info
        </div>
      </Link>
      <Link to={"#"}>
        <div
          className={
            currentPage === "review"
              ? "acc-nav-item display-acc-nav-dash"
              : "acc-nav-item hide-acc-nav-dash"
          }
        >
          <span>- </span>
          Write a Review
        </div>
      </Link>
      <Link to={"#"}>
        <div
          className={
            currentPage === "story"
              ? "acc-nav-item display-acc-nav-dash"
              : "acc-nav-item hide-acc-nav-dash"
          }
        >
          <span>- </span>
          Our Story
        </div>
      </Link>
      <Link to={"#"}>
        <div
          className={
            currentPage === "help"
              ? "acc-nav-item display-acc-nav-dash"
              : "acc-nav-item hide-acc-nav-dash"
          }
        >
          <span>- </span>
          Help
        </div>
      </Link> */}
      <div
        onClick={() => {
          signout();
          authDispatch(signoutRedirect(true));
        }}
        className={
          currentPage === "logout"
            ? "acc-nav-item display-acc-nav-dash"
            : "acc-nav-item hide-acc-nav-dash"
        }
      >
        <span>- </span>
        Log Out
      </div>
    </div>
  );
};

export default AccountNav;
