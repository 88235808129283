import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { formatAmount } from "../../helpers";

const AdminProductItem = ({
  product,
  setOpenProductForm,
  setProductToEdit,
  setOpenDialog,
  setProductToDelete,
}) => {

  return (
    <tr>
      <td
        className="table-cell table-cell--icon"
        onClick={() => {
          setOpenProductForm(true);
          setProductToEdit(product);
        }}
      >
        <FontAwesomeIcon icon={["fas", "edit"]} size="1x" />
      </td>
      <td className="table-cell table-cell--icon">
        <FontAwesomeIcon
          icon={["fas", "trash-alt"]}
          size="1x"
          color="red"
          onClick={() => {
            setOpenDialog(true);
            setProductToDelete(product);
          }}
        />
      </td>

      <td className="table-cell">{product.sku}</td>
      <td className="table-cell">{product.title}</td>
      <td className='table-cell'>{product.title}</td>
      <td className='table-cell table-cell-images'>
        {product.images ? (
          product.images.map((img) => (
            <div key={img.imageUrl} className='images'>
              <img src={img.imageUrl} alt={product.title} className='img' />
            </div>
          ))
        ) : product.imageUrl ? (
          <img src={product.imageUrl} alt={product.title} className='img' />
        ) : null}
      </td>
      <td className="table-cell">{formatAmount(product.price)}</td>
      <td className="table-cell table-cell--hide">
        {product.createdAt && product.createdAt.toDate().toDateString()}
      </td>
      <td className="table-cell table-cell--hide">
        {product.updatedAt && product.updatedAt.toDate().toDateString()}
      </td>
      <td
        className="table-cell"
        style={{ color: product.inventory === 0 ? "red" : undefined }}
      >
        {product.inventory}
      </td>

      <td className="table-cell">{product.servingsPerContainer}</td>
      <td className="table-cell">{product.servingSize}</td>
      <td className="table-cell">{product.calories}</td>
      <td className="table-cell">{product.totalFat}</td>
      <td className="table-cell">{product.saturatedFat}</td>
      <td className="table-cell">{product.transFat}</td>
      <td className="table-cell">{product.cholesterol}</td>
      <td className="table-cell">{product.sodium}</td>
      <td className="table-cell">{product.totalCarbohydrate}</td>
      <td className="table-cell">{product.dietaryFiber}</td>
      <td className="table-cell">{product.totalSugars}</td>
      <td className="table-cell">{product.addedSugars}</td>
      <td className="table-cell">{product.protein}</td>
      <td className="table-cell">{product.vitaminA}</td>
      <td className="table-cell">{product.vitaminC}</td>
      <td className="table-cell">{product.vitaminD}</td>
      <td className="table-cell">{product.calcium}</td>
      <td className="table-cell">{product.iron}</td>
      <td className="table-cell">{product.potassium}</td>
    </tr>
  );
};

export default AdminProductItem;
