import React from "react";
import { Link, useHistory } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PageHeader from "../components/PageHeader";
import { usePaymentPlanContext } from "../state/payment-plan-context";
import { useCartLimitContext } from "../state/cart-limit-context";
import { useCartContext } from "../state/cart-context";
import { useManageCart } from "../hooks/useManageCart";
import { Helmet, HelmetProvider } from "react-helmet-async";

const GiftBoxes = () => {
  const { paymentPlan, setPaymentPlan } = usePaymentPlanContext();
  const { itemLimit, setItemLimit } = useCartLimitContext();
  const { removeCartItem } = useManageCart();
  const { cart } = useCartContext();

  const history = useHistory();

  const deleteItem = async (item) => {
    const finished = await removeCartItem(item.item.id, item.user);

    if(finished) {
      setPaymentPlan("ONETIME");
      setItemLimit(9999);

      window.localStorage.setItem(
        "selectedPaymentPlan",
        JSON.stringify("ONETIME")
      );
      window.localStorage.setItem("selecteditemLimit", JSON.stringify(9999));

      history.push("/products/Qan1SqYrtVVCYzhGk57q");
    }
  };

  return (
    <>
    <HelmetProvider>
    <Helmet>
          <title>
          Nuts Gift Boxes | Gummy Gift Boxes | Dried Fruits Gift Boxes - nuts inbox
          </title>
          <meta
            name="description"
            content="Delivered to your doorstep in a beautiful hand-crafted package, our nuts gift boxes make the perfect gift for any occasion. Mix and match your selections."
          />
        </Helmet>
    </HelmetProvider>


      <PageHeader pageTitle={"Gift Boxes"} />

      <div className="head-page-route">
        <Link to="/">
          <span>Home</span>
        </Link>{" "}
        <FontAwesomeIcon
          className="fa-icons"
          icon={["fas", "chevron-right"]}
          size="xs"
        />{" "}
        Gift Boxes
      </div>

      <div className="gift-box-main-container">
        <div className="gb-header">
          <div className="gb-head-slide"></div>
          <h1 className="gb-head-title">
            Premium ingredients. Gorgeous boxes.
          </h1>
          <div className="gb-head-description">
            Delivered to your doorstep in a beautiful hand-crafted package, our
            nut boxes make the perfect gift for any occasion. Mix and match your
            selections to suit anyone’s taste or dietary restrictions.
          </div>
        </div>

        <div className="gb-content">
          <div className="gb-first gb-row">
            <div className="gb-row-img-container">
              <div className="gb-row-img gb-first-img"></div>
            </div>
            <div className="gb-row-text-container">
              <h2 className="gb-row-title">Protein-Packed Superfood </h2>
              <div className="gb-row-description">
                Chock full of protein and healthy fats, nuts are among nature’s
                best superfoods. We offer everything from classics like peanuts
                and almonds to indulgences like wasabi peas and Japanese Cajun
                peanuts -- all hand-picked to ensure quality.
              </div>
              <button
                onClick={() => {
                  if (cart && cart.length > 0 && paymentPlan !== "ONETIME") {
                    cart.forEach((item) => {
                      deleteItem(item);
                    });
                  } else {
                    setPaymentPlan("ONETIME");
                    setItemLimit(9999);

                    window.localStorage.setItem(
                      "selectedPaymentPlan",
                      JSON.stringify("ONETIME")
                    );
                    window.localStorage.setItem(
                      "selecteditemLimit",
                      JSON.stringify(9999)
                    );

                    history.push("/products/Qan1SqYrtVVCYzhGk57q");
                  }
                }}
                className="gs-btn gb-btn"
              >
                view item >
              </button>
            </div>
          </div>
          <div className="gb-second gb-row">
            <div className="gb-row-img-container gb-second-img-top">
              <div className="gb-row-img gb-second-img"></div>
            </div>
            <div className="gb-row-text-container">
              <h2 className="gb-row-title">
                The Healthy Way To Snack Sweet{" "}
              </h2>
              <div className="gb-row-description">
                Treat yourself to a little something sweet with our gourmet
                gummies. Made from premium ingredients and available in a range
                of flavors, gummies make the perfect snack to stock your cabinet
                or gift to a loved one.
              </div>
              <button
                onClick={() => {
                  if (cart && cart.length > 0 && paymentPlan !== "ONETIME") {
                    cart.forEach((item) => {
                      deleteItem(item);
                    });
                  } else {
                    setPaymentPlan("ONETIME");
                    setItemLimit(9999);

                    window.localStorage.setItem(
                      "selectedPaymentPlan",
                      JSON.stringify("ONETIME")
                    );
                    window.localStorage.setItem(
                      "selecteditemLimit",
                      JSON.stringify(9999)
                    );

                    history.push("/products/Z0pZCXKYkWAdjdacriT0");
                  }
                }}
                className="gs-btn gb-btn"
              >
                view item >
              </button>
            </div>
            <div className="gb-row-img-container gb-second-img-bottom">
              <div className="gb-row-img gb-second-img"></div>
            </div>
          </div>
          <div className="gb-first gb-row">
            <div className="gb-row-img-container">
              <div className="gb-row-img gb-third-img"></div>
            </div>
            <div className="gb-row-text-container">
              <h2 className="gb-row-title">Healthy, Sweet, Mess-Free</h2>
              <div className="gb-row-description">
                A sweeter way to get in your nine servings, our
                preservative-free dried fruit lets you savor Mother Earth’s
                sweetest treats with less mess. Enjoy healthier desserts or easy
                grab-and-go snacks with choices ranging from classics like
                raisins to specialties like blood orange and sour cherry.
              </div>
              <button
                onClick={() => {
                  if (cart && cart.length > 0 && paymentPlan !== "ONETIME") {
                    cart.forEach((item) => {
                      deleteItem(item);
                    });
                  } else {
                    setPaymentPlan("ONETIME");
                    setItemLimit(9999);

                    window.localStorage.setItem(
                      "selectedPaymentPlan",
                      JSON.stringify("ONETIME")
                    );
                    window.localStorage.setItem(
                      "selecteditemLimit",
                      JSON.stringify(9999)
                    );

                    history.push("/products/2wDR8afhvLOzL5rug67R");
                  }
                }}
                className="gs-btn gb-btn"
              >
                view item >
              </button>
            </div>
          </div>





          <div className="gb-second gb-row">
            <div className="gb-row-img-container gb-second-img-top">
              <div className="gb-row-img gb-fourth-img"></div>
            </div>
            <div className="gb-row-text-container">
              <h2 className="gb-row-title">
              The Ultimate Mix{" "}
              </h2>
              <div className="gb-row-description">
              Why choose what to give when you can give it all? Our ultimate gift box lets you mix and match across all nine squares - from our healthiest options to our most decadent.
              </div>
              <button
                onClick={() => {
                  if (cart && cart.length > 0 && paymentPlan !== "ONETIME") {
                    cart.forEach((item) => {
                      deleteItem(item);
                    });
                  } else {
                    setPaymentPlan("ONETIME");
                    setItemLimit(9999);

                    window.localStorage.setItem(
                      "selectedPaymentPlan",
                      JSON.stringify("ONETIME")
                    );
                    window.localStorage.setItem(
                      "selecteditemLimit",
                      JSON.stringify(9999)
                    );

                    history.push("/products/GcmC7Swfo4s8dSQxH4Tj");
                  }
                }}
                className="gs-btn gb-btn"
              >
                view item >
              </button>
            </div>
            <div className="gb-row-img-container gb-second-img-bottom">
              <div className="gb-row-img gb-fourth-img"></div>
            </div>
          </div>







        </div>
      </div>
    </>
  );
};

export default GiftBoxes;
