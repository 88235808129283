import React from "react";
import { useHistory} from 'react-router-dom'

const BlogItem = ({ blogData }) => {

  const { id, title, shortdescription, imgurl, imgalt } = blogData;


  const history = useHistory()

  return (
    <>

      <div className="blogItem" onClick={() => history.push(`/blogs/${id}`)}>
        <div className="blogItem-content">
          <div className="blogItem-top">
            <div className="blogItem-img"><img src={imgurl} alt={imgalt} /></div>
          </div>
          <div className="blogItem-bottom">
            <div className="blogItem-title">{title}</div>
            {/* <div className="blogItem-short-discription">{shortdescription}</div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogItem;
