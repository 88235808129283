import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PageHeader from "../components/PageHeader";
import CheckoutSteps from "../components/CheckoutSteps";
import Input from "../components/Input";
import FAQ from "../components/toggle-sections/FAQ";
import { useWelcomeLeadContext } from "../state/welcome-lead-context";

const Welcome = () => {
  const { welcomeLead, setWelcomeLead } = useWelcomeLeadContext();

  const history = useHistory();

  useEffect(() => {
    if (welcomeLead) history.push("/select-plan");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [welcomeLead]);

  const handleSetLead = (e) => {
    e.preventDefault();

    const email = e.target.email.value;
    const zipcode = e.target.zipcode.value;

    const wlStr = JSON.stringify({
      email,
      zipcode,
    });

    if (!email || !zipcode) return;

    setWelcomeLead({
      email,
      zipcode,
    });

    window.localStorage.setItem("welcomeLead", wlStr);

    history.push("/select-plan");
  };

  return (
    <>
      <PageHeader pageTitle={"Welcome"} />

      <div className="head-page-route">
        <Link to="/">
          <span>Home</span>
        </Link>{" "}
        <FontAwesomeIcon
          className="fa-icons"
          icon={["fas", "chevron-right"]}
          size="xs"
        />{" "}
        <Link to="/welcome">
          <span>Get Started</span>
        </Link>{" "}
        <FontAwesomeIcon
          className="fa-icons"
          icon={["fas", "chevron-right"]}
          size="xs"
        />{" "}
        Welcome
      </div>

      <CheckoutSteps pageTitle={"Welcome"} />

      <div className="welcome-lead-container">
        <div className="wl-left">
          <div className="wl-left-content">
            <div className="wl-title">Ready to get started?</div>
            <div className="wl-description">
              Ready to get started? Set your subscription and enter your address
              at checkout. It’s that easy to revamp your lifestyle with
              heart-healthy snacks
            </div>
            <form onSubmit={handleSetLead}>
              <Input
                type="email"
                required
                className="wl-input"
                name="email"
                placeholder="Email"
              />

              <Input
                required
                style={{ marginBottom: "10px" }}
                className="wl-input"
                name="zipcode"
                placeholder="Zip Code"
              />

              <button onClick={() => {}} className="gs-btn signup-btn">
                get started >
              </button>
            </form>

            <p className="login-signup">
              Have an account?{" "}
              <span
                onClick={() => history.push("/login")}
                className="login-signup-link"
              >
                Login
              </span>
            </p>
          </div>
        </div>
        <div className="wl-right">
          <div className="wl-right-content">
            <div className="wl-right-text">
              The purest ingredients, delivered at your convenience.{" "}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Welcome;
