import React, { useRef, ChangeEvent, useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import _ from "lodash";
import Input from "../Input";
import Button from "../Button";
import { useAuthContext } from "../../state/auth-context";
import { useManageProduct } from "../../hooks/useManageProduct";
import { storageRef } from "../../firebase/config";
import { categories } from "../../helpers";

const fileType = ["image/png", "image/jpeg", "image/jpg"];

const AddAndEditProduct = ({
  setOpenProductForm,
  productToEdit,
  setProductToEdit,
}) => {
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [fileNames, setFileNames] = useState("");

  const [existingImages, setExistingImages] = useState(
    productToEdit
      ? productToEdit.images
        ? productToEdit.images
        : [
            {
              imageFileName: productToEdit.imageFileName,
              imageRef: productToEdit.imageRef,
              imageUrl: productToEdit.imageUrl,
            },
          ]
      : []
  );
  const [imagesToRemove, setImagesToRemove] = useState([]); // Will be used to remove images from storage

  console.log(selectedFiles);
  console.log(fileNames);

  const {
    authState: { authUser },
  } = useAuthContext();

  const {
    uploadImageToStorage,
    addNewProduct,
    editProduct,
    addProductFinished,
    setUploadProgression,
    uploadProgression,
    editProductFinished,
    loading,
    error,
    uploadImages,
  } = useManageProduct();

  const { register, handleSubmit, errors, reset } = useForm();

  const inputRef = useRef(null);

  useEffect(() => {
    if (addProductFinished) {
      reset();
      setSelectedFiles(null);
      setUploadProgression(0);
    }
  }, [addProductFinished, reset, setUploadProgression, setSelectedFiles]);

  useEffect(() => {
    if (editProductFinished) {
      reset();
      setSelectedFiles(null);
      setUploadProgression(0);
      setProductToEdit(null);
      setOpenProductForm(false);
    }
  }, [
    editProductFinished,
    reset,
    setUploadProgression,
    setSelectedFiles,
    setProductToEdit,
    setOpenProductForm,
  ]);

  const handleOpenUploadBox = () => {
    if (inputRef?.current) inputRef.current.click();
  };

  // Remove from the local states only, not related to the storage
  function handleRemoveImg(removedImage) {
    if (existingImages.length === 0) return;

    const newUpdatedImages = existingImages.filter(
      (img) => img.imageRef !== removedImage.imageRef
    );

    // Update the existingImages state
    setExistingImages((prev) => (prev ? newUpdatedImages : prev));

    // Put the removed image into the remove images state for use to remove all these images from firebase storage
    setImagesToRemove((prev) => [...prev, removedImage]);
  }

  const handleSelectFile = (e) => {
    const files = e.target.files;

    if (!files || files.length === 0) return;

    // Convert files to an array of file
    const filesArray = [...files];

    // Validate if file type of each image is correct
    const typeVaidations = filesArray.map((file) =>
      fileType.includes(file.type)
    );
    if (typeVaidations.includes(false)) {
      // If one of the file has invalid type
      alert('Wrong file format, allow only "png" or "jpeg", or "jpg"');
      return;
    }

    // Store file names for displaying
    let names = "";
    filesArray.forEach((file, i) => {
      if (i < filesArray.length - 1) {
        names = names.concat(`${file.name}, `);
      } else {
        names = names.concat(file.name);
      }
    });

    setSelectedFiles(filesArray);
    setFileNames(names);
  };

  const handleAddProduct = handleSubmit((data) => {
    if (!selectedFiles || !authUser) return;

    return uploadImageToStorage(
      selectedFiles,
      addNewProduct(data, authUser?.uid)
    );
  });

  const handleEditProduct = handleSubmit(async (data) => {
    if (!productToEdit || !authUser) return;

    const oldProduct = {
      sku: productToEdit.sku,
      title: productToEdit.title,
      description: productToEdit.description,
      price: productToEdit.price,
      category: productToEdit.category,
      ingredients: productToEdit.ingredients,
      paleoDiet: productToEdit.paleoDiet,
      athleteFriendly: productToEdit.athleteFriendly,
      diabeticFriendly: productToEdit.diabeticFriendly,
      plantBasedProtein: productToEdit.plantBasedProtein,
      highFiber: productToEdit.highFiber,
      heartHealthy: productToEdit.heartHealthy,
      ketoDiet: productToEdit.ketoDiet,
      unsalted: productToEdit.unsalted,
      inventory: productToEdit.inventory,
      servingsPerContainer: productToEdit.servingsPerContainer,
      servingSize: productToEdit.servingSize,
      calories: productToEdit.calories,
      saturatedFat: productToEdit.saturatedFat,
      transFat: productToEdit.transFat,
      cholesterol: productToEdit.cholesterol,
      sodium: productToEdit.sodium,
      totalCarbohydrate: productToEdit.totalCarbohydrate,
      dietaryFiber: productToEdit.dietaryFiber,
      totalSugars: productToEdit.totalSugars,
      addedSugars: productToEdit.addedSugars,
      protein: productToEdit.protein,
      vitaminA: productToEdit.vitaminA,
      vitaminC: productToEdit.vitaminC,
      vitaminD: productToEdit.vitaminD,
      calcium: productToEdit.calcium,
      iron: productToEdit.iron,
      potassium: productToEdit.potassium,
    };

    // The current product images before update
    const oldImages = productToEdit.images
      ? productToEdit.images
      : [
          {
            imageRef: productToEdit.imageRef,
            imageUrl: productToEdit.imageUrl,
            imageFileName: productToEdit.imageFileName,
          },
        ]; // For the previously added products change its image to array

    // The updated product (except images)
    const updatedProduct = {
      sku: data.sku,
      title: data.title,
      description: data.description,
      price: +data.price,
      category: data.category,
      inventory: +data.inventory,
      paleoDiet: data.paleoDiet,
      athleteFriendly: data.athleteFriendly,
      diabeticFriendly: data.diabeticFriendly,
      diabeticFriendly: data.diabeticFriendly,
      plantBasedProtein: data.plantBasedProtein,
      highFiber: data.highFiber,
      highFiber: data.highFiber,
      heartHealthy: data.heartHealthy,
      ketoDiet: data.ketoDiet,
      unsalted: data.unsalted,
      servingsPerContainer: data.servingsPerContainer,
      servingSize: data.servingSize,
      calories: data.calories,
      ingredients: data.ingredients,
      totalFat: data.totalFat,
      saturatedFat: data.saturatedFat,
      transFat: data.transFat,
      cholesterol: data.cholesterol,
      sodium: data.sodium,
      totalCarbohydrate: data.totalCarbohydrate,
      dietaryFiber: data.dietaryFiber,
      totalSugars: data.totalSugars,
      addedSugars: data.addedSugars,
      protein: data.protein,
      vitaminA: data.vitaminA,
      vitaminC: data.vitaminC,
      vitaminD: data.vitaminD,
      calcium: data.calcium,
      iron: data.iron,
      potassium: data.potassium,
    };

    // The updated images (the images array that will be used in firstore)
    let updatedImages = existingImages;

    // Check if the product has been changed
    // 1. Check other properties (except images)
    const isOtherPropsChanged = !_.isEqual(oldProduct, updatedProduct);

    // 2. Check if the existing images has been removed
    const isExistingImagesChanged = !_.isEqual(oldImages, updatedImages);

    // 3. Check if new images have been added
    const isNewImages = !!selectedFiles && selectedFiles.length > 0;

    if (!isOtherPropsChanged && !isExistingImagesChanged && !isNewImages) {
      // Nothing changed
      alert("Notthing changed");
      return;
    } else {
      // Something change

      // Firstly, check if the existing images have been removed
      if (isExistingImagesChanged) {
        // If yes, remove the removed images from storage

        const deleteImagesPromises = imagesToRemove.map((image) => {
          const imageRef = storageRef.child(image.imageRef);

          return imageRef.delete();
        });

        await Promise.all(deleteImagesPromises);
      }

      // Secondly, check if new images have been added
      if (isNewImages) {
        // If yes, add new images to firstore --> get back an array of images info
        // Before uploading, check to confirm that we have files to upload
        if (!selectedFiles) return;

        const uploadedImagesInfo = await uploadImages(selectedFiles);

        if (uploadedImagesInfo) {
          // After getting back an array of added images, add them to the updated images array
          updatedImages = [...updatedImages, ...uploadedImagesInfo];
        }
      }

      // Lastly uppdate the product in firestore with the most updated info
      return editProduct(
        productToEdit.id,
        { ...updatedProduct, images: updatedImages },
        authUser.uid
      );
    }
  });

  return (
    <>
      <div
        className="backdrop"
        onClick={() => {
          setProductToEdit(null);
          setOpenProductForm(false);
        }}
      ></div>

      <div className="modal modal--add-product">
        <div
          className="modal-close"
          onClick={() => {
            setProductToEdit(null);
            setOpenProductForm(false);
          }}
        >
          &times;
        </div>
        <h2 className="header--center">
          {productToEdit ? "Edit A Product" : "Add A New Product"}
        </h2>

        <form
          className="form"
          onSubmit={productToEdit ? handleEditProduct : handleAddProduct}
        >
          <Input
            label="SKU"
            name="sku"
            placeholder="Product SKU"
            defaultValue={productToEdit ? productToEdit.sku : ""}
            ref={register({
              required: "SKU is required.",
              minLength: {
                value: 3,
                message: "Product SKU must be at least 3 characters.",
              },
            })}
            error={errors.sku?.message}
          />
          <Input
            label="Title"
            name="title"
            placeholder="Product title"
            defaultValue={productToEdit ? productToEdit.title : ""}
            ref={register({
              required: "Title is required.",
              minLength: {
                value: 3,
                message: "Product title must be at least 3 characters.",
              },
            })}
            error={errors.title?.message}
          />
          <div className="product-description">
            <label htmlFor="description" className="product-description-label">
              Description
            </label>
            <textarea
              className="product-description"
              name="description"
              placeholder="Product description"
              defaultValue={productToEdit ? productToEdit.description : ""}
              ref={register({
                required: "Description is required.",
                minLength: {
                  value: 6,
                  message: "Product description must be at least 6 characters.",
                },
              })}
              error={errors.description?.message}
              cols="41"
              rows="10"
            ></textarea>
          </div>

          <Input
            label="Price"
            type="number"
            name="price"
            defaultValue={productToEdit ? productToEdit.price : ""}
            placeholder="Product price"
            ref={register({
              required: "Price is required.",
              minLength: {
                value: 1,
                message: "Product price must be at least $1.",
              },
            })}
            error={errors.price?.message}
          />
          {/* Image */}
          <div className="form__input-container">
            <label htmlFor="Image" className="form__input-label">
              Image
            </label>

            {/* For edit product, show existing images */}
            {productToEdit && (
              <div className="form__input-container">
                <label htmlFor="Image" className="form__input-label">
                  Current Images
                </label>

                <div className="current-prod-images">
                  {existingImages.length > 0
                    ? existingImages.map((img) => (
                        <div key={img.imageUrl} className="img-container">
                          <img src={img.imageUrl} alt={img.imageFileName} />
                          <div
                            className="img-remove"
                            onClick={handleRemoveImg.bind(undefined, img)}
                          >
                            &times;
                          </div>
                        </div>
                      ))
                    : "No images"}
                </div>
              </div>
            )}
            <div className="form__input-container">
              <label htmlFor="Image" className="form__input-label">
                Upload Image
              </label>

              <div className="form__input-file-upload">
                {uploadProgression ? (
                  <div style={{ width: "70%" }}>
                    <input
                      type="text"
                      className="upload-progression"
                      style={{
                        width: `${uploadProgression}%`,
                        color: "white",
                        textAlign: "center",
                      }}
                      value={`${Math.round(uploadProgression)}%`}
                      readOnly
                    />
                  </div>
                ) : (
                  <input
                    type="text"
                    name="imageFileName"
                    className="input"
                    readOnly
                    style={{ width: "70%", cursor: "pointer" }}
                    onClick={handleOpenUploadBox}
                    value={selectedFiles ? fileNames : ""}
                    ref={register(
                      productToEdit
                        ? undefined
                        : { required: "Product image is required." }
                    )}
                  />
                )}

                <Button
                  width="30%"
                  height="4rem"
                  type="button"
                  style={{ borderRadius: "0", border: "1px solid #282c3499" }}
                  onClick={handleOpenUploadBox}
                >
                  <span className="paragraph--small">Select</span>
                </Button>
                <input
                  type="file"
                  style={{ display: "none" }}
                  ref={inputRef}
                  onChange={handleSelectFile}
                  multiple
                />
              </div>

              {!productToEdit &&
                (!selectedFiles || selectedFiles.length === 0) && (
                  <p className="paragraph paragraph--error-small">
                    Please add at least 1 image
                  </p>
                )}
            </div>

            {/* ------------------------------ */}
{/*
            <div className="form__input-file-upload">
              {uploadProgression ? (
                <div style={{ width: "70%" }}>
                  <input
                    type="text"
                    className="upload-progression"
                    style={{
                      width: `${uploadProgression}%`,
                      color: "white",
                      textAlign: "center",
                    }}
                    value={`${Math.round(uploadProgression)}%`}
                    readOnly
                  />
                </div>
              ) : (
                <input
                  type="text"
                  name="imageFileName"
                  className="input"
                  readOnly
                  style={{ width: "70%", cursor: "pointer" }}
                  onClick={handleOpenUploadBox}
                  // value={
                  //   selectedFile
                  //     ? selectedFile.name
                  //     : productToEdit
                  //     ? productToEdit.imageFileName
                  //     : ''
                  // }
                  value={selectedFiles ? fileNames : ""}
                  // ref={register({ required: "Product image is required." })}
                />
              )}

              <Button
                width="30%"
                height="100%"
                type="button"
                style={{ borderRadius: "0", border: "1px solid #282c3499" }}
                onClick={handleOpenUploadBox}
              >
                <span className="paragraph--small">Select</span>
              </Button>
              <input
                type="file"
                style={{ display: "none" }}
                ref={inputRef}
                onChange={handleSelectFile}
                multiple
              />
            </div> */}

            {/* ---------------------- */}

            {errors?.imageFileName && !selectedFiles && (
              <p className="paragraph paragraph--error-small">
                {errors.imageFileName.message}
              </p>
            )}
          </div>
          <div className="form__input-container">
            <label htmlFor="Category" className="form__input-lable">
              Category
            </label>

            <select
              name="category"
              className="input"
              defaultValue={productToEdit ? productToEdit.category : undefined}
              ref={register({
                required: "Product category is required.",
              })}
            >
              <option style={{ display: "none" }}></option>
              {categories.map((cat) => (
                <option key={cat} value={cat}>
                  {cat}
                </option>
              ))}
            </select>

            {errors?.category && (
              <p className="paragraph paragraph--error-small">
                {errors.category.message}
              </p>
            )}
          </div>

          <div className="form__input-container">
            <div className="collections-title">Collections</div>
            <div className="select-collection">
              <div className="collection-item">
                <input
                  type="checkbox"
                  name="paleoDiet"
                  defaultChecked={
                    productToEdit && productToEdit.paleoDiet === true
                      ? true
                      : undefined
                  }
                  ref={register}
                />
                <label htmlFor="paleoDiet">Paleo Diet Friendly</label>
              </div>
              <div className="collection-item">
                <input
                  type="checkbox"
                  name="athleteFriendly"
                  defaultChecked={
                    productToEdit && productToEdit.athleteFriendly === true
                      ? true
                      : undefined
                  }
                  ref={register}
                />
                <label htmlFor="athleteFriendly">Athlete Friendly</label>
              </div>
              <div className="collection-item">
                <input
                  type="checkbox"
                  name="diabeticFriendly"
                  defaultChecked={
                    productToEdit && productToEdit.diabeticFriendly === true
                      ? true
                      : undefined
                  }
                  ref={register}
                />
                <label htmlFor="diabeticFriendly">Diabetic Friendly</label>
              </div>
              <div className="collection-item">
                <input
                  type="checkbox"
                  name="plantBasedProtein"
                  defaultChecked={
                    productToEdit && productToEdit.plantBasedProtein === true
                      ? true
                      : undefined
                  }
                  ref={register}
                />
                <label htmlFor="plantBasedProtein">Plant Based Protein</label>
              </div>
              <div className="collection-item">
                <input
                  type="checkbox"
                  name="highFiber"
                  defaultChecked={
                    productToEdit && productToEdit.highFiber === true
                      ? true
                      : undefined
                  }
                  ref={register}
                />
                <label htmlFor="highFiber">High Fiber</label>
              </div>
              <div className="collection-item">
                <input
                  type="checkbox"
                  name="heartHealthy"
                  defaultChecked={
                    productToEdit && productToEdit.heartHealthy === true
                      ? true
                      : undefined
                  }
                  ref={register}
                />
                <label htmlFor="heartHealthy">Heart Healthy</label>
              </div>
              <div className="collection-item">
                <input
                  type="checkbox"
                  name="ketoDiet"
                  defaultChecked={
                    productToEdit && productToEdit.ketoDiet === true
                      ? true
                      : undefined
                  }
                  ref={register}
                />
                <label htmlFor="ketoDiet">Keto Friendly</label>
              </div>
              <div className="collection-item">
                <input
                  type="checkbox"
                  name="unsalted"
                  defaultChecked={
                    productToEdit && productToEdit.unsalted === true
                      ? true
                      : undefined
                  }
                  ref={register}
                />
                <label htmlFor="unsalted">Unsalted</label>
              </div>
            </div>
            {errors?.paleoDiet && (
              <p className="paragraph paragraph--error-small">
                {errors.paleoDiet.message}
              </p>
            )}
            {errors?.athleteFriendly && (
              <p className="paragraph paragraph--error-small">
                {errors.athleteFriendly.message}
              </p>
            )}
            {errors?.diabeticFriendly && (
              <p className="paragraph paragraph--error-small">
                {errors.diabeticFriendly.message}
              </p>
            )}
            {errors?.plantBasedProtein && (
              <p className="paragraph paragraph--error-small">
                {errors.plantBasedProtein.message}
              </p>
            )}
            {errors?.highFiber && (
              <p className="paragraph paragraph--error-small">
                {errors.highFiber.message}
              </p>
            )}
            {errors?.heartHealthy && (
              <p className="paragraph paragraph--error-small">
                {errors.heartHealthy.message}
              </p>
            )}
            {errors?.ketoDiet && (
              <p className="paragraph paragraph--error-small">
                {errors.ketoDiet.message}
              </p>
            )}
            {errors?.unsalted && (
              <p className="paragraph paragraph--error-small">
                {errors.unsalted.message}
              </p>
            )}
          </div>

          <Input
            label="Inventory"
            type="number"
            name="inventory"
            placeholder="Product inventory"
            defaultValue={productToEdit ? productToEdit.inventory : ""}
            ref={register({
              required: "Inventory is required.",
              min: 0,
              pattern: {
                value: /^[0-9]\d*$/,
                message: "Inventory must be a positive whole number.",
              },
            })}
            error={errors.inventory?.message}
          />

          <div className="product-description">
            <label htmlFor="ingredients" className="product-description-label">
              Ingredients
            </label>
            <textarea
              className="product-description"
              name="ingredients"
              placeholder="Product ingredients"
              defaultValue={productToEdit ? productToEdit.ingredients : ""}
              ref={register}
              error={errors.ingredients?.message}
              cols="41"
              rows="10"
            ></textarea>
          </div>

          <Input
            label="Servings Per Container"
            name="servingsPerContainer"
            placeholder="Servings Per Container"
            ref={register}
            defaultValue={
              productToEdit ? productToEdit.servingsPerContainer : ""
            }
            error={errors.servingsPerContainer?.message}
          />
          <Input
            label="Serving Size"
            name="servingSize"
            placeholder="Serving Size"
            ref={register}
            defaultValue={productToEdit ? productToEdit.servingSize : ""}
            error={errors.servingSize?.message}
          />
          <Input
            label="Calories"
            name="calories"
            placeholder="Calories"
            ref={register}
            defaultValue={productToEdit ? productToEdit.calories : ""}
            error={errors.calories?.message}
          />
          <Input
            label="Total Fat"
            name="totalFat"
            placeholder="Total Fat"
            ref={register}
            defaultValue={productToEdit ? productToEdit.totalFat : ""}
            error={errors.totalFat?.message}
          />
          <Input
            label="Saturated Fat"
            name="saturatedFat"
            placeholder="Saturated Fat"
            ref={register}
            defaultValue={productToEdit ? productToEdit.saturatedFat : ""}
            error={errors.saturatedFat?.message}
          />
          <Input
            label="Trans Fat"
            name="transFat"
            placeholder="Trans Fat"
            ref={register}
            defaultValue={productToEdit ? productToEdit.transFat : ""}
            error={errors.transFat?.message}
          />
          <Input
            label="Cholesterol"
            name="cholesterol"
            placeholder="Cholesterol"
            ref={register}
            defaultValue={productToEdit ? productToEdit.cholesterol : ""}
            error={errors.cholesterol?.message}
          />
          <Input
            label="Sodium"
            name="sodium"
            placeholder="Sodium"
            ref={register}
            defaultValue={productToEdit ? productToEdit.sodium : ""}
            error={errors.sodium?.message}
          />
          <Input
            label="Total Carbohydrate"
            name="totalCarbohydrate"
            placeholder="Total Carbohydrate"
            ref={register}
            defaultValue={productToEdit ? productToEdit.totalCarbohydrate : ""}
            error={errors.totalCarbohydrate?.message}
          />
          <Input
            label="Dietary Fiber"
            name="dietaryFiber"
            placeholder="Dietary Fiber"
            ref={register}
            defaultValue={productToEdit ? productToEdit.dietaryFiber : ""}
            error={errors.dietaryFiber?.message}
          />
          <Input
            label="Total Sugars"
            name="totalSugars"
            placeholder="Total Sugars"
            ref={register}
            defaultValue={productToEdit ? productToEdit.totalSugars : ""}
            error={errors.totalSugars?.message}
          />
          <Input
            label="Added Sugars"
            name="addedSugars"
            placeholder="Added Sugars"
            ref={register}
            defaultValue={productToEdit ? productToEdit.addedSugars : ""}
            error={errors.addedSugars?.message}
          />
          <Input
            label="Protein"
            name="protein"
            placeholder="Protein"
            ref={register}
            defaultValue={productToEdit ? productToEdit.protein : ""}
            error={errors.protein?.message}
          />
          <Input
            label="Vitamin A"
            name="vitaminA"
            placeholder="Vitamin A"
            ref={register}
            defaultValue={productToEdit ? productToEdit.vitaminA : ""}
            error={errors.vitaminA?.message}
          />
          <Input
            label="Vitamin C"
            name="vitaminC"
            placeholder="Vitamin C"
            ref={register}
            defaultValue={productToEdit ? productToEdit.vitaminC : ""}
            error={errors.vitaminC?.message}
          />
          <Input
            label="Vitamin D"
            name="vitaminD"
            placeholder="Vitamin D"
            ref={register}
            defaultValue={productToEdit ? productToEdit.vitaminD : ""}
            error={errors.vitaminD?.message}
          />
          <Input
            label="Calcium"
            name="calcium"
            placeholder="Calcium"
            ref={register}
            defaultValue={productToEdit ? productToEdit.calcium : ""}
            error={errors.calcium?.message}
          />
          <Input
            label="Iron"
            name="iron"
            placeholder="Iron"
            ref={register}
            defaultValue={productToEdit ? productToEdit.iron : ""}
            error={errors.iron?.message}
          />
          <Input
            label="Potassium"
            name="potassium"
            placeholder="Potassium"
            ref={register}
            defaultValue={productToEdit ? productToEdit.potassium : ""}
            error={errors.potassium?.message}
          />
          <Button
            className="add-product-btn"
            width="100%"
            style={{ marginTop: "1rem" }}
            loading={loading}
            disabled={loading}
          >
            Submit
          </Button>
        </form>

        {error && <p className="paragraph paragraph--error">{error}</p>}
      </div>
    </>
  );
};

export default AddAndEditProduct;
