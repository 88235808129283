import React, { createContext, useContext, useState, useEffect } from "react";

import { blogsRef } from "../firebase";

const BlogsStateContext = createContext(undefined);
const BlogsDispatchContext = createContext(undefined);

const BlogsContextProvider = ({ children }) => {
  const [blogs, setBlogs] = useState();

  useEffect(() => {
    blogsRef
      .orderBy("date", "desc")
      .get()
      .then((snapshot) => {
        const blogsArr = [];

        snapshot.docs.forEach((doc) => {
          blogsArr.push(doc.data());
        });

        setBlogs(blogsArr);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <BlogsStateContext.Provider value={{ blogs }}>
      <BlogsDispatchContext.Provider value={{ setBlogs }}>
        {children}
      </BlogsDispatchContext.Provider>
    </BlogsStateContext.Provider>
  );
};

export default BlogsContextProvider;

export const useBlogsContext = () => {
  const blogsState = useContext(BlogsStateContext);
  const blogsDispatch = useContext(BlogsDispatchContext);

  if (blogsState === undefined || blogsDispatch === undefined)
    throw new Error(
      "useBlogsContext must be used within BlogsContextProvider."
    );

  return { blogsState, blogsDispatch };
};
