import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import Spinner from "../components/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PageHeader from "../components/PageHeader";
import { useProductsContext } from "../state/products-context";
import ProductItem from "../components/products/ProductItem";
import { collections } from "../helpers";
import { Helmet, HelmetProvider } from "react-helmet-async";

const Collection = () => {
  const [filteredProducts, setFilteredProducts] = useState(["x"]);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();

  const {
    productsState: { products, loading: productsLoading },
  } = useProductsContext();

  const location = useLocation();
  const path = location.pathname.split("/collections/")[1];

  const filterCollectionProducts = () => {
    if (!products) return;

    setLoading(true);
    let productsArr = [];

    products.All.forEach((item) => {
      if (item[path]) {
        productsArr.push(item);
      }
    });

    setFilteredProducts(productsArr);
    setLoading(false);
  };

  const collectionTitle = () => {
    collections.forEach((collection) => {
      if (collection.nospace === path) {
        return setTitle(collection.title);
      }
    });
  };

  const collectionDescription = () => {
    collections.forEach((collection) => {
      if (collection.nospace === path) {
        return setDescription(collection.description);
      }
    });
  };

  useEffect(() => {
    filterCollectionProducts();
    collectionTitle();
    collectionDescription();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  if (loading || productsLoading)
    return <Spinner color="gray" width={50} height={50} />;

  return (
    <>

<HelmetProvider>
  {
    path === "paleoDiet" ? (
      <Helmet>
        <title>
          Paleo Diet Friendly Snacks | Healthy Snacks | Nuts | Seeds - nuts
          inbox
        </title>
        <meta
          name="description"
          content="Paleo-friendly snacking that never sacrifices taste: this rich assortment of nuts, seeds and mixes are loaded with the healthy fats you need."
        />
      </Helmet>
    ) : path === "diabeticFriendly" ? (
      <Helmet>
        <title>
          Diabetic Friendly Snacks |Best Snacks for Diabetics - nuts inbox
        </title>
        <meta
          name="description"
          content="Regulating your blood sugar has never been more fulfilling - emphasis on filling. From seeds to nuts to fruits and mixes, we’ve got just the diabetic friendly snack."
        />
      </Helmet>
    ) : path === "athleteFriendly" ? (
      <Helmet>
        <title>
          Athlete Friendly Snacks |Healthy Snacks for Athletes | Nuts - nuts
          inbox
        </title>
        <meta
          name="description"
          content="NutsinBox offers athlete friendly snacks with a delicious assortment of fruits, seeds, nuts and trail mixes. Click here to order!"
        />
      </Helmet>
    ) : path === "plantBasedProtein" ? (
      <Helmet>
        <title>
          Plant Based Protein |Roasted and Salted Seeds | Roasted Nuts -
          nuts inbox
        </title>
        <meta
          name="description"
          content="NutsInBox offers plant based protein snacks; roasted, salted, or buttered and packed with big power & big flavor. Click here to order now!"
        />
      </Helmet>
    ) : path === "highFiber" ? (
      <Helmet>
        <title>
          High Fiber Snacks | Roasted and Salted Mixed Nuts | Dried Fruits -
          nuts inbox
        </title>
        <meta
          name="description"
          content="High Fiber snacks of dried fruits and roasted and salted Mixed nuts come loaded with energy to keep you full and lively, all day long"
        />
      </Helmet>
    ) : path === "heartHealthy" ? (
      <Helmet>
        <title>
          Heart Healthy Snacks | Healthy Snacks for Heart - nuts inbox
        </title>
        <meta
          name="description"
          content="From fruits to seeds to nuts, we’ve got options that are as good for your heart as they are for your tastebuds. These snacks prove health never has to mean compromising."
        />
      </Helmet>
    ) : path === "ketoDiet" ? (
      <Helmet>
        <title>
          Keto Friendly Snacks | Healthy Keto Snacks - nuts inbox
        </title>
        <meta
          name="description"
          content="High in healthy fats and low in carbs, these keto-friendly snacks are always safe to enjoy. And with options like Chilli Lime and BBQ, trust us, there’s a whole lot here to enjoy."
        />
      </Helmet>
    ) : path === "unsalted" ? (
      <Helmet>
        <title>Unsalted | Salt-Free Healthy Snacks - nuts inbox</title>
        <meta
          name="description"
          content="nuts inbox offers unsalted/salt-free snacks. Selections from every category are available to help lower hunger and blood pressure alike. Order now!"
        />
      </Helmet> ) : undefined
  }
</HelmetProvider>


      <PageHeader pageTitle={title} />

      <div className="head-page-route">
        <Link to="/">
          <span>Home</span>
        </Link>{" "}
        <FontAwesomeIcon
          className="fa-icons"
          icon={["fas", "chevron-right"]}
          size="xs"
        />{" "}
        Collections
        <FontAwesomeIcon
          className="fa-icons"
          icon={["fas", "chevron-right"]}
          size="xs"
        />{" "}
        {title}
      </div>

      <h1 className="carousel-title">{title}</h1>
      <div className="carousel-description">{description}</div>

      <div className="products-container">
        {filteredProducts[0] !== "x" && filteredProducts.length !== 0 ? (
          <div className="products">
            {filteredProducts.map((product) => {
              return <ProductItem key={product.id} product={product} />;
            })}
          </div>
        ) : (
          <h2 className="header--center no-products">No products found.</h2>
        )}
      </div>
    </>
  );
};

export default Collection;
