import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";

import Button from "../components/Button";
import Input from "../components/Input";
import { useAuthContext } from "../state/auth-context";
import SocialMediaLogin from "../components/auth/SocialMediaLogin";
// import { useModalContext } from "../state/modal-context";
import { useAuthenticate } from "../hooks/useAuthenticate";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PageHeader from "../components/PageHeader";

const Login = () => {
  const [loginView, setLoginView] = useState("login");

  const { signin, loading, error, socialLogin } = useAuthenticate();
  const {
    loading: resetLoading,
    error: resetError,
    resetPassword,
    successMsg,
  } = useAuthenticate();
  const { register, errors, handleSubmit } = useForm();

  const history = useHistory();

  const handleSignin = handleSubmit(async (data) => {
    const response = await signin(data);
    if (response) {
      window.setTimeout(() => {
        history.push("/account");
      }, 1000);
    }
  });

  const handleResetPassword = handleSubmit((data) => resetPassword(data));

  return (
    <>
      <PageHeader pageTitle={"Login"} />
      <div className="head-page-route">
        <Link to="/">
          <span>Home</span>
        </Link>{" "}
        <FontAwesomeIcon
          className="fa-icons"
          icon={["fas", "chevron-right"]}
          size="xs"
        />{" "}
        Login{" "}
      </div>

      <div className="login-page-content">
        <div className="login-page-left">
          <h2 className="login-title">
            {loginView === "login" ? "Login" : "Reset Password"}
          </h2>
          <p className="login-description">
            {loginView === "login"
              ? "Login to your account to get access to all your order details, prefernces, past orders and more..."
              : "Enter your email below to reset your password."}
          </p>

          {/* {loginView === "login" ? (
            <SocialMediaLogin socialLogin={socialLogin} loading={loading} />
          ) : undefined} */}

          {loginView === "login" ? (
            <hr style={{ marginTop: "20px" }} />
          ) : undefined}
          {/* {loginView === "login" ? (
            <p className="paragraph--center paragraph--focus paragraph--small">
              Or sign in with an email
            </p>
          ) : undefined} */}

          <form
            className="form"
            onSubmit={
              loginView === "login" ? handleSignin : handleResetPassword
            }
          >
            <Input
              style={{ marginTop: "20px" }}
              className="login-input"
              name="email"
              lable="Email"
              placeholder="Email"
              error={errors.email?.message}
              ref={register({
                required: "Email is required.",
              })}
            />
            {loginView === "login" ? (
              <Input
                className="login-input"
                name="password"
                type="password"
                lable="Password"
                placeholder="Password"
                error={errors.password?.message}
                ref={register({
                  required: "Password is required.",
                })}
              />
            ) : undefined}

            {loginView === "login" ? (
              <p
                className="login-forgot-password"
                onClick={() => setLoginView("reset")}
              >
                Forgot your password?
              </p>
            ) : (
              <p
                className="login-forgot-password"
                onClick={() => setLoginView("login")}
              >
                Back to login
              </p>
            )}

            <Button
              width={"100%"}
              height={"35px"}
              className="login-btn"
              loading={loading}
            >
              {loginView === "login" ? "LOGIN" : "Submit"}
            </Button>

            {error && <p className="paragraph paragraph--error">{error}</p>}
            {resetError && (
              <p className="paragraph paragraph--error">{resetError}</p>
            )}
          </form>

          {successMsg && (
            <p className="paragraph--success paragraph--small">{successMsg}</p>
          )}

          <p className="login-signup">
            Don't have an account?{" "}
            <span
              className="login-signup-link"
              onClick={() => history.push("/welcome")}
              // onClick={() => setModalType("signin")}
            >
              Sign Up
            </span>
          </p>
        </div>
        <div className="login-page-right">
          <div className="login-page-right-content">
            <div className="login-banner-text-content">
              Premium ingredients just taste better. Our nuts are 100% organic
              and hand-picked to ensure quality.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
