import React from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Hamberger from "./Hamberger";
import { useAuthContext, openUserDropdown } from "../../state/auth-context";
import { useViewCartContext } from "../../state/view-cart-context";
import { useMobileNavContext } from "../../state/mobile-view-context";
import { useCartContext } from "../../state/cart-context";
import { useCartLimitContext } from "../../state/cart-limit-context";
import { usePaymentPlanContext } from "../../state/payment-plan-context";
import { isAdmin, isClient, calculateCartQuantity } from "../../helpers";

const MainNav = () => {
  const {
    authDispatch,
    authState: { userInfo },
  } = useAuthContext();
  // const { setSearchedItems } = useSearchContext();
  const { setViewCart } = useViewCartContext();
  const { mobileNav, setMobileNav } = useMobileNavContext();

  const { cart } = useCartContext();
  const { itemLimit } = useCartLimitContext();
  const { paymentPlan } = usePaymentPlanContext();

  // const [searchString, setSearchString] = useState("");

  const history = useHistory();

  // const { searchItems, loading, error } = useSearchItems(location.pathname);

  // useEffect(() => {
  //   if (!searchString) {
  //     setSearchedItems(null);
  //     history.replace(location.pathname);
  //   }
  // }, [searchString, setSearchedItems, location.pathname, history]);

  // useEffect(() => {
  //   if (error) alert(error);
  // }, [error]);

  // const handleSearchChange = (e) => {
  //   searchItems(e.target.value); // Just add this line
  //   setSearchString(e.target.value);
  // };

  // const handleKeyDown = (e) => {
  //   if (e.key === "Enter") {
  //     return handleSearch();
  //   }
  // };

  // const handleSearch = async () => {
  //   if (!searchString) return;

  //   return searchItems(searchString);
  // };

  return (
    <header className="head-container">
      <div className="mob-head-content">
        <div className="mob-head-nav-bar">
          <div className="mob-head-hamberger">
            <Hamberger mobileNav={mobileNav} setMobileNav={setMobileNav} />
          </div>

          <div
            className="mob-head-logo"
            onClick={() => {
              history.push("/");
              setMobileNav(false);
            }}
          ></div>

          {/*
          <div className="mobile-head-account">
            {userInfo ? (
              <FontAwesomeIcon
                className="mobile-account-icon"
                icon={["fas", "user-circle"]}
                size="2x"
                onClick={() => {
                  if (userInfo && isAdmin(userInfo?.role)) {
                    authDispatch(openUserDropdown(true));
                  } else if (userInfo && isClient(userInfo?.role)) {
                    history.push("/account");
                  } else {
                    return;
                  }
                }}
              />
            ) : (
              <Link to="/login">login</Link>
            )}
          </div> */}

          <div className="mob-head-cart">
            <div
              className="mob-head-right"
              onClick={() => {
                setMobileNav(false);
                setViewCart(true);
              }}
            >
              {paymentPlan === "ONETIME" ? (
                <FontAwesomeIcon
                  className="nav-cart-icon"
                  icon={["fas", "cart-arrow-down"]}
                  color="white"
                  size="1x"
                />
              ) : (
                <FontAwesomeIcon
                  className="nav-cart-icon"
                  icon={["fas", "box-open"]}
                  color="white"
                  size="1x"
                />
              )}

              <div className="nav-cart-count">
                {!cart ? (
                  0
                ) : (
                  <>
                    {" "}
                    {cart && calculateCartQuantity(cart)}
                    {paymentPlan !== "ONETIME" && itemLimit !== 9999
                      ? `/${itemLimit ? itemLimit : 0}`
                      : undefined}{" "}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="head-content">
        <div className="head-center">
          <div className="head-nav-item">
            <a href="/products?cat=AllProducts&page=1">browse</a>
          </div>

          <div className="head-nav-item">
            <Link to="/gift-boxes">Gift Boxes</Link>
          </div>

          <Link className="head-logo-link" to="/">
            <div className="head-nav-item nutsinbox-logo"></div>
          </Link>

          <div className="head-nav-item">
            {userInfo ? (
              <FontAwesomeIcon
                className="user-account-icon"
                icon={["fas", "user-circle"]}
                size="2x"
                onClick={() => {
                  if (userInfo && isAdmin(userInfo?.role)) {
                    authDispatch(openUserDropdown(true));
                  } else if (userInfo && isClient(userInfo?.role)) {
                    history.push("/account");
                  } else {
                    return;
                  }
                }}
              />
            ) : (
              <Link to="/login">login</Link>
            )}
          </div>

          <div className="head-nav-item">
            <Link to="/welcome">get started</Link>
          </div>
        </div>
        <div
          className="head-right"
          onClick={() => {
            setMobileNav(false);
            setViewCart(true);
          }}
        >
          {paymentPlan === "ONETIME" ? (
            <FontAwesomeIcon
              className="nav-cart-icon"
              icon={["fas", "cart-arrow-down"]}
              color="white"
              size="1x"
            />
          ) : (
            <FontAwesomeIcon
              className="nav-cart-icon"
              icon={["fas", "box-open"]}
              color="white"
              size="1x"
            />
          )}

          <div className="nav-cart-count">
            {!cart ? (
              0
            ) : (
              <>
                {" "}
                {cart && calculateCartQuantity(cart)}
                {paymentPlan !== "ONETIME" && itemLimit !== 9999
                  ? `/${itemLimit ? itemLimit : 0}`
                  : undefined}{" "}
              </>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default MainNav;
