import React from "react";
import { useHistory } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CheckoutSteps = ({ pageTitle }) => {
  const history = useHistory();

  return (
    <div className="checkout-steps-container">
      <div className="dashed-line"></div>
      <div className="chs-bar">
        <div className="chs-item">
          <div
            className={
              pageTitle === "Welcome"
                ? "chs-item-number chs-item-current"
                : "chs-item-number chs-item-complete"
            }
          >
            {pageTitle === "Welcome" ? (
              <span onClick={() => history.push('/welcome')}>1</span>
            ) : (
              <FontAwesomeIcon
                className="fa-chs-icon"
                icon={["fas", "check"]}
                size="xs"
              />
            )}
          </div>
          <div className="chs-item-title" onClick={() => history.push('/welcome')}>welcome</div>
        </div>
        <div className="chs-item">
          <div
            className={
              pageTitle === "Welcome"
                ? "chs-item-number chs-item-incomplete"
                : pageTitle === "Select Plan"
                ? "chs-item-number chs-item-current"
                : pageTitle === "Select Items" || pageTitle === "Checkout"
                ? "chs-item-number chs-item-complete"
                : undefined
            }
          >
            {pageTitle === "Welcome" || pageTitle === "Select Plan" ? (
            <span onClick={() => history.push('/select-plan')}>2</span>
            ) : (
              <FontAwesomeIcon
              onClick={() => history.push('/select-plan')}
                className="fa-chs-icon"
                icon={["fas", "check"]}
                size="xs"
              />
            )}
          </div>
          <div className="chs-item-title" onClick={() => history.push('/select-plan')}>select plan</div>
        </div>
        <div className="chs-item">
          <div
            className={
              pageTitle === "Welcome" || pageTitle === "Select Plan"
                ? "chs-item-number chs-item-incomplete"
                : pageTitle === "Select Items"
                ? "chs-item-number chs-item-current"
                : pageTitle === "Checkout"
                ? "chs-item-number chs-item-complete"
                : undefined
            }
          >
            {pageTitle === "Welcome" ||
            pageTitle === "Select Plan" ||
            pageTitle === "Select Items" ? (
              <span onClick={() => history.push('/products')}>3</span>
            ) : (
              <FontAwesomeIcon
              onClick={() => history.push('/products')}
                className="fa-chs-icon"
                icon={["fas", "check"]}
                size="xs"
              />
            )}
          </div>
          <div className="chs-item-title" onClick={() => history.push('/products')}>select items</div>
        </div>
        <div className="chs-item">
          <div
            className={
              pageTitle === "Welcome" ||
              pageTitle === "Select Plan" ||
              pageTitle === "Select Items"
                ? "chs-item-number chs-item-incomplete"
                : "chs-item-number chs-item-complete"
            }
          >
            4
          </div>
          <div className="chs-item-title">checkout</div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutSteps;
