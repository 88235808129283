import React from "react";

const AnnouncementBar = () => {
  return (
    <div className="annbar-container">
      <div className="annbar-content">
        Up to $20 Off Your First Subscription Box + Free US Shipping
      </div>
    </div>
  );
};

export default AnnouncementBar;
