import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import Button from "../Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DropDownCartItem from "../cart/DropDownCartItem";
import { useCartContext } from "../../state/cart-context";
import { useViewCartContext } from "../../state/view-cart-context";
import { useCartLimitContext } from "../../state/cart-limit-context";
import { usePaymentPlanContext } from "../../state/payment-plan-context";
import { useManageCart } from "../../hooks/useManageCart";
import { calculateCartQuantity } from "../../helpers";

const CartDropDown = () => {
  const { cart } = useCartContext();
  const { setViewCart } = useViewCartContext();
  const { removeCartItem } = useManageCart();
  const { itemLimit, setItemLimit } = useCartLimitContext();
  const { paymentPlan, setPaymentPlan } = usePaymentPlanContext();

  const [orderIdToUpdate, setOrderIdToUpdate] = useState(null);
  const [addMore, setAddMore] = useState(false);
  const [shopping, setShopping] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (window.sessionStorage.getItem("orderIdToUpdate")) {
      const orderToUpdate = JSON.parse(
        window.sessionStorage.getItem("orderIdToUpdate")
      );

      setOrderIdToUpdate(orderToUpdate);
    }
  }, []);

  useEffect(() => {
    if (addMore) {
      if (paymentPlan === "ONETIME") {
        history.push("/gift-boxes");
      } else {
        history.push("/products");
      }

      setViewCart(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addMore]);

  useEffect(() => {
    if (shopping) {
      history.push("/products");

      setViewCart(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopping]);

  const deleteItem = async (item) => {
    const response = await removeCartItem(item.item.id, item.user);

    if (response) return;
  };

  useEffect(() => {
    document.getElementById("backdrop").addEventListener("click", (e) => {
      if (e.target === e.currentTarget) setViewCart(false);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="cart-sidebar-container" id="backdrop">
      <div className="cart-sidebar sidebar-show">
        <div className="cart-sidebar-content">
          <div className="cs-close">
            <FontAwesomeIcon
              className="cs-close-icon"
              icon={["fas", "long-arrow-alt-left"]}
              size="1x"
              onClick={() => {
                setViewCart(false);
              }}
            />
          </div>
          <div className="cs-head">
            <div className="cs-head-title">My Box</div>
            <div className="cs-head-count">
              {cart && calculateCartQuantity(cart)}
              {paymentPlan !== "ONETIME" && itemLimit !== 9999
                ? `/${itemLimit ? itemLimit : 0}`
                : undefined}
            </div>
          </div>
          {!cart ? (
            false
          ) : (
            <div className="cs-item-container">
              {cart.length === 0 && (
                <h2 className="header--center">
                  Your cart is empty, start{" "}
                  <span
                    className="header--orange header--link"
                    onClick={() => {
                      setShopping(true);
                    }}
                  >
                    shopping?
                  </span>
                </h2>
              )}
              {cart.map((item) => (
                <DropDownCartItem key={item.id} cartItem={item} />
              ))}
            </div>
          )}

          <div className="cs-btns">
            {!cart ? (
              false
            ) : (
              <div className="cs-top-btns">
                {calculateCartQuantity(cart) > itemLimit &&
                itemLimit !== 0 &&
                paymentPlan !== "ONETIME" ? (
                  <div className="cart-full-msg">Over plan limit!</div>
                ) : calculateCartQuantity(cart) === itemLimit &&
                  itemLimit !== 0 &&
                  paymentPlan !== "ONETIME" ? (
                  <div className="cart-full-msg">Your box is full!</div>
                ) : (
                  <button
                    onClick={() => {
                      setAddMore(true);
                    }}
                    className="cs-top-btn-item"
                  >
                    add more items
                  </button>
                )}
                <button
                  onClick={() => {
                    cart.forEach((item) => {
                      deleteItem(item);
                    });
                  }}
                  className="cs-top-btn-item"
                >
                  empty box
                </button>
              </div>
            )}

            {orderIdToUpdate ? (
              <div className="cs-bottom-btns">
                <Button
                  width={"100%"}
                  height={"50px"}
                  className="cancel-update-btn"
                  onClick={() => {
                    cart.forEach((item) => {
                      deleteItem(item);
                    });

                    window.localStorage.removeItem("selectedPaymentPlan");
                    window.localStorage.removeItem("selecteditemLimit");
                    window.sessionStorage.removeItem("subscriptionId");
                    window.sessionStorage.removeItem("orderIdToUpdate");

                    setPaymentPlan(null);
                    setItemLimit(0);
                    setViewCart(false);
                  }}
                >
                  Cancel Update
                </Button>
              </div>
            ) : undefined}

            <div className="cs-bottom-btns">
              {!cart ? (
                false
              ) : (
                <Button
                  width={"100%"}
                  height={"50px"}
                  onClick={() => {
                    setViewCart(false);
                    history.push("/buy/checkout");
                  }}
                  className={
                    cart.length === 0 ||
                    (paymentPlan !== "ONETIME" &&
                      itemLimit !== 9999 &&
                      calculateCartQuantity(cart) !== itemLimit &&
                      itemLimit !== 0) ||
                    (paymentPlan !== "ONETIME" &&
                      itemLimit !== 9999 &&
                      calculateCartQuantity(cart) > itemLimit &&
                      itemLimit !== 0)
                      ? "cs-checkout-btn cs-checkout-disable-btn"
                      : "cs-checkout-btn"
                  }
                  disabled={
                    cart.length === 0 ||
                    (paymentPlan !== "ONETIME" &&
                      itemLimit !== 9999 &&
                      calculateCartQuantity(cart) !== itemLimit &&
                      itemLimit !== 0) ||
                    (paymentPlan !== "ONETIME" &&
                      itemLimit !== 9999 &&
                      calculateCartQuantity(cart) > itemLimit &&
                      itemLimit !== 0)
                  }
                >
                  checkout >
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartDropDown;
