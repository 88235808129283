import React from 'react';

const PageHeader = ({pageTitle}) => {
  return (
    <div className='page-header'>
      <div className="page-header-content">
      <div className="page-title">
        {pageTitle}
      </div>
      </div>
    </div>
  );
};

export default PageHeader;
