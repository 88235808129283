import React, { forwardRef } from "react";

import Spinner from "./Spinner";

const Button = forwardRef(
  ({
    children,
    disabled,
    style,
    className,
    height = "2.7rem",
    width = "9rem",
    loading,
    spinnerColor,
    spinnerHeight,
    spinnerWidth,
    ...props
  },
    ref
  ) => {
    return (
      <button
        ref={ref}
        className={`btn ${className}`}
        disabled={disabled}
        style={{
          cursor: loading || disabled ? "not-allowed" : undefined,
          height,
          width,
          ...style,
        }}
        {...props}
      >
        {loading ? (
          <Spinner
            color={spinnerColor}
            height={spinnerHeight}
            width={spinnerWidth}
          />
        ) : (
          children
        )}
      </button>
    );
  }
);

export default Button;
