import React, { useState, useEffect } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Swiper, SwiperSlide } from "swiper/react";

import Button from "../components/Button";
import Spinner from "../components/Spinner";
import PageHeader from "../components/PageHeader";
import CheckoutSteps from "../components/CheckoutSteps";
import NutritionFacts from "../components/toggle-sections/NutritionFacts";
import PageNotFound from "../pages/PageNotFound";
import { useProductsContext } from "../state/products-context";
import { usePaymentPlanContext } from "../state/payment-plan-context";
import { useCartContext } from "../state/cart-context";
import { useCartLimitContext } from "../state/cart-limit-context";
import { useUserUUIDContext } from "../state/user-id-context";
import { useManageCart } from "../hooks/useManageCart";
import { v4 as uuidv4 } from "uuid";
import { useViewCartContext } from "../state/view-cart-context";
import { useDialog } from "../hooks/useDialog";
import AlertDialog from "../components/dialogs/AlertDialog";
import { formatAmount, calculateCartQuantity, giftBoxes } from "../helpers";

const ProductDetail = () => {
  const {
    productsState: { products, loading, error },
  } = useProductsContext();
  const {
    addToCart,
    loading: addToCartLoading,
    removeCartItem,
  } = useManageCart();
  const { openDialog, setOpenDialog } = useDialog();
  const { cart } = useCartContext();
  const { paymentPlan } = usePaymentPlanContext();
  const { itemLimit } = useCartLimitContext();
  const { userUUID, setUserUUID } = useUserUUIDContext();
  const { setViewCart } = useViewCartContext();

  const [quantity, setQuantity] = useState(1);
  const [setAddedCartItem] = useState(null);

  const params = useParams();
  const history = useHistory();

  const [product, setProduct] = useState();
  const [thumbsSwiper, setThumbsSwiper] = useState(null)
  const [mobileThumbsSwiper, setMobileThumbsSwiper] = useState()

  useEffect(() => {
    const prod = products.All.find((item) => item.id === params.productId);

    if (prod) {
      setProduct(prod);
    } else {
      setProduct(undefined);
    }
  }, [params, products.All]);

  useEffect(() => {
    if (userUUID) return;

    const newUserID = uuidv4();
    const newUserIDStr = JSON.stringify(newUserID);

    setUserUUID(newUserID);

    window.localStorage.setItem("UserID", newUserIDStr);
  }, [setUserUUID, userUUID]);

  useEffect(() => {
    if (cart && calculateCartQuantity(cart) >= itemLimit) setViewCart(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart]);

  if (loading) return <Spinner color="gray" width={50} height={50} />;

  if (!loading && error) return <h2 className="header">{error}</h2>;

  if (!product) return <PageNotFound />;

  const deleteItem = async (item) => {
    const finished = await removeCartItem(item.item.id, item.user);

    if (finished) return;
  };

  return (
    <>
      <PageHeader pageTitle={product.title} />

      <div className="head-page-route">
        <Link to="/">
          <span>Home</span>
        </Link>{" "}
        <FontAwesomeIcon
          className="fa-icons"
          icon={["fas", "chevron-right"]}
          size="xs"
        />{" "}
        <Link
          to={
            giftBoxes.includes(product.id)
              ? "/gift-boxes"
              : `/products?cat=${product.category}&page=1`
          }
        >
          <span>{product.category}</span>
        </Link>{" "}
        <FontAwesomeIcon
          className="fa-icons"
          icon={["fas", "chevron-right"]}
          size="xs"
        />{" "}
        {product.title}{" "}
      </div>

      {itemLimit && paymentPlan && paymentPlan !== "ONETIME" ? (
        <CheckoutSteps pageTitle={"Select Items"} />
      ) : undefined}

      <div className="product-detail-main-container">
        <div className="product-detail-mobile">
          {/* <img
            src={product.imageUrl}
            alt={product.title}
            className="product-detail-image-mobile"
          /> */}

          {/* ---------- Gallery ---------- */}

          {product.images ? <div className="gallery">
            {/* Thumbnails Vertical */}
            <Swiper
              className="thumbnails"
              direction="vertical"
              onSwiper={setMobileThumbsSwiper}
              freeMode
              watchSlidesProgress
              breakpoints={{
                // Very Large Screen
                1536: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                // Large Screen
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                // Medium Screen
                640: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                // Small Screen
                320: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
              }}
            >
              {product.images.map((image) => (
                <SwiperSlide key={image.imageUrl}>
                  <img src={image.imageUrl} alt={product.title} />
                </SwiperSlide>
              ))}
            </Swiper>

            {/* Big Image Vertical */}
            <Swiper
              className="big-images"
              // direction="vertical"
              spaceBetween={10}
              thumbs={{ swiper: mobileThumbsSwiper }}
            >
              {product.images.map((image) => (
                <SwiperSlide key={image.imageUrl}>
                  <div className="big-image-container">
                    <img src={image.imageUrl} alt={product.title} />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div> : <img
            src={product.imageUrl}
            alt={product.title}
            className="product-detail-image-mobile"
          /> }

          {/* ---------- Gallery ---------- */}

          <div className="pd-top-mobile-detail">
            <div className="product-detail-title">{product.title}</div>
            {product.servingSize ? (
              <div className="product-detail-servingSize">
                {product.servingSize}
              </div>
            ) : undefined}
            <hr />
            <div className="product-detail-price">
              ${formatAmount(product.price)}
            </div>
            <hr />

            {giftBoxes.includes(product.id) ? (
              <div className="qty-control">
                {product.inventory === 0 ? (
                  <p className="paragraph--error">Out of stock</p>
                ) : (
                  <div className="pd-qty-control-container">
                    <div
                      className="pd-qty-control"
                      onClick={() =>
                        setQuantity((prev) => {
                          if (prev < 2) return prev;

                          return prev - 1;
                        })
                      }
                    >
                      <FontAwesomeIcon
                        icon={["fas", "minus"]}
                        size="xs"
                        color="gray"
                        style={{
                          cursor: quantity === 1 ? "not-allowed" : undefined,
                          fontSize: "9px",
                        }}
                        className="qty-control-icon"
                      />
                    </div>
                    <div className="pd-qty-control-qty">
                      <p className="paragraph">{quantity}</p>
                    </div>
                    <div
                      className="pd-qty-control"
                      onClick={() =>
                        setQuantity((prev) => {
                          if (prev === product.inventory) return prev;

                          return prev + 1;
                        })
                      }
                    >
                      <FontAwesomeIcon
                        icon={["fas", "plus"]}
                        size="xs"
                        color="gray"
                        style={{
                          cursor:
                            quantity === product.inventory
                              ? "not-allowed"
                              : undefined,
                          fontSize: "9px",
                        }}
                        className="qty-control-icon"
                      />
                    </div>
                  </div>
                )}
                <hr />

                <Button
                  disabled={product.inventory === 0 || addToCartLoading}
                  loading={addToCartLoading}
                  width="100%"
                  height="40px"
                  className="product-detail-btn"
                  onClick={
                    !paymentPlan
                      ? () => history.push("/select-plan")
                      : paymentPlan === "ONETIME" &&
                        !giftBoxes.includes(product.id)
                      ? cart && cart.length > 0
                        ? () => setOpenDialog(true)
                        : () => history.push("/select-plan")
                      : paymentPlan !== "ONETIME" &&
                        giftBoxes.includes(product.id)
                      ? cart && cart.length > 0
                        ? () => setOpenDialog(true)
                        : () => history.push("/gift-boxes")
                      : async () => {
                          const foundItem = cart
                            ? cart.find((item) => item.product === product.id)
                            : undefined;

                          if (
                            foundItem &&
                            foundItem.quantity + quantity > product.inventory
                          ) {
                            const allowedQty =
                              product.inventory - foundItem.quantity;
                            setQuantity(allowedQty === 0 ? 1 : allowedQty);
                            alert(
                              `You already have "${foundItem.quantity} pcs" of this item in your cart, so maximum quantity allowed for this item is "${allowedQty} pcs".`
                            );
                            return;
                          }

                          const finished = await addToCart(
                            product.id,
                            quantity,
                            userUUID,
                            product.inventory
                          );

                          if (finished) {
                            setAddedCartItem({ product, quantity });
                            setQuantity(1);
                          }
                        }
                  }
                >
                  {!paymentPlan ? (
                    "GET STARTED"
                  ) : paymentPlan === "ONETIME" &&
                    !giftBoxes.includes(product.id) ? (
                    "GET STARTED"
                  ) : paymentPlan !== "ONETIME" &&
                    giftBoxes.includes(product.id) ? (
                    "GET STARTED"
                  ) : (
                    <>
                      <FontAwesomeIcon
                        icon={["fas", "plus"]}
                        className="addtocart-plus"
                        style={{
                          cursor:
                            quantity === product.inventory
                              ? "not-allowed"
                              : undefined,
                        }}
                      />{" "}
                      Add to cart
                    </>
                  )}
                </Button>
              </div>
            ) : (
              <div className="qty-control">
                {" "}
                <Button
                  disabled={
                    (!cart
                      ? false
                      : calculateCartQuantity(cart) === itemLimit &&
                        itemLimit !== 0) ||
                    product.inventory === 0 ||
                    addToCartLoading
                  }
                  loading={addToCartLoading}
                  width="100%"
                  height="40px"
                  className="product-detail-btn"
                  onClick={
                    !paymentPlan
                      ? () => history.push("/select-plan")
                      : paymentPlan === "ONETIME" &&
                        !giftBoxes.includes(product.id)
                      ? cart && cart.length > 0
                        ? () => setOpenDialog(true)
                        : () => history.push("/select-plan")
                      : paymentPlan !== "ONETIME" &&
                        giftBoxes.includes(product.id)
                      ? cart && cart.length > 0
                        ? () => setOpenDialog(true)
                        : () => history.push("/gift-boxes")
                      : async () => {
                          // if (calculateCartQuantity(cart) >= itemLimit) {
                          //   history.push("/buy/my-cart");
                          // }

                          const foundItem = cart
                            ? cart.find((item) => item.product === product.id)
                            : undefined;

                          if (
                            foundItem &&
                            foundItem.quantity + quantity > product.inventory
                          ) {
                            const allowedQty =
                              product.inventory - foundItem.quantity;
                            setQuantity(allowedQty === 0 ? 1 : allowedQty);
                            alert(
                              `You already have "${foundItem.quantity} pcs" of this item in your cart, so maximum quantity allowed for this item is "${allowedQty} pcs".`
                            );
                            return;
                          }

                          const finished = await addToCart(
                            product.id,
                            quantity,
                            userUUID,
                            product.inventory
                          );

                          if (finished) {
                            setAddedCartItem({ product, quantity });
                            setQuantity(1);
                          }
                        }
                  }
                >
                  {!paymentPlan ? (
                    "GET STARTED"
                  ) : paymentPlan === "ONETIME" &&
                    !giftBoxes.includes(product.id) ? (
                    "GET STARTED"
                  ) : paymentPlan !== "ONETIME" &&
                    giftBoxes.includes(product.id) ? (
                    "GET STARTED"
                  ) : (
                    <>
                      <FontAwesomeIcon
                        icon={["fas", "plus"]}
                        className="addtocart-plus"
                        style={{
                          cursor:
                            quantity === product.inventory
                              ? "not-allowed"
                              : undefined,
                        }}
                      />{" "}
                      Add to cart
                    </>
                  )}
                </Button>
              </div>
            )}
          </div>

          <div className="product-detail-bottom-row">
            {product.totalSugars === "0g" ? (
              <div className="product-qualities">
                <div className="sugarFree-icon"></div>
              </div>
            ) : undefined}
            <div className="product-description">{product.description}</div>

            {product.ingredients ||
            product.servingsPerContainer ||
            product.servingSize ||
            product.calories ||
            product.totalFat ||
            product.saturatedFat ||
            product.transFat ||
            product.cholesterol ||
            product.sodium ||
            product.totalCarbohydrate ||
            product.dietaryFiber ||
            product.totalSugars ||
            product.addedSugars ||
            product.protein ||
            product.vitaminA ||
            product.vitaminC ||
            product.vitaminD ||
            product.calcium ||
            product.iron ||
            product.potassium ? (
              <NutritionFacts product={product} />
            ) : undefined}
          </div>
        </div>

        <div className="product-detail-desktop">
          <div className="product-detail-top-row">
            <div className="pd-top-left">
              {/* ---------- Gallery ---------- */}

          {product.images ? <div className="gallery">
            {/* Thumbnails Vertical */}
            <Swiper
              className="thumbnails"
              direction="vertical"
              onSwiper={setThumbsSwiper}
              freeMode
              watchSlidesProgress
              breakpoints={{
                // Very Large Screen
                1536: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                // Large Screen
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                // Medium Screen
                640: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                // Small Screen
                320: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
              }}
            >
              {product.images.map((image) => (
                <SwiperSlide key={image.imageUrl}>
                  <img src={image.imageUrl} alt={product.title} />
                </SwiperSlide>
              ))}
            </Swiper>

            {/* Big Image Vertical */}
            <Swiper
              className="big-images"
              // direction="vertical"
              spaceBetween={10}
              thumbs={{ swiper: thumbsSwiper }}
            >
              {product.images.map((image) => (
                <SwiperSlide key={image.imageUrl}>
                  <div className="big-image-container">
                    <img src={image.imageUrl} alt={product.title} />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div> : <img
            src={product.imageUrl}
            alt={product.title}
            className="product-detail-image-mobile"
          /> }

          {/* ---------- Gallery ---------- */}

              <div className="product-detail-bottom-row">
                {product.totalSugars === "0g" ? (
                  <div className="product-qualities">
                    <div className="sugarFree-icon"></div>
                  </div>
                ) : undefined}
                <div className="product-description">{product.description}</div>

                {product.ingredients ||
                product.servingsPerContainer ||
                product.servingSize ||
                product.calories ||
                product.totalFat ||
                product.saturatedFat ||
                product.transFat ||
                product.cholesterol ||
                product.sodium ||
                product.totalCarbohydrate ||
                product.dietaryFiber ||
                product.totalSugars ||
                product.addedSugars ||
                product.protein ||
                product.vitaminA ||
                product.vitaminC ||
                product.vitaminD ||
                product.calcium ||
                product.iron ||
                product.potassium ? (
                  <NutritionFacts product={product} />
                ) : undefined}
              </div>
            </div>
            <div className="pd-top-right">
              <div className="pd-top-right-content">
                <div className="product-detail-title">{product.title}</div>
                {product.servingSize ? (
                  <div className="product-detail-servingSize">
                    {product.servingSize}
                  </div>
                ) : undefined}
                <hr />
                <div className="product-detail-price">
                  ${formatAmount(product.price)}
                </div>
                <hr />

                {giftBoxes.includes(product.id) ? (
                  <div className="qty-control">
                    {product.inventory === 0 ? (
                      <p className="paragraph--error">Out of stock</p>
                    ) : (
                      <div className="pd-qty-control-container">
                        <div
                          className="pd-qty-control"
                          onClick={() =>
                            setQuantity((prev) => {
                              if (prev < 2) return prev;

                              return prev - 1;
                            })
                          }
                        >
                          <FontAwesomeIcon
                            icon={["fas", "minus"]}
                            size="xs"
                            color="gray"
                            style={{
                              cursor:
                                quantity === 1 ? "not-allowed" : undefined,
                              fontSize: "9px",
                            }}
                            className="qty-control-icon"
                          />
                        </div>
                        <div className="pd-qty-control-qty">
                          <p className="paragraph">{quantity}</p>
                        </div>
                        <div
                          className="pd-qty-control"
                          onClick={() =>
                            setQuantity((prev) => {
                              if (prev === product.inventory) return prev;

                              return prev + 1;
                            })
                          }
                        >
                          <FontAwesomeIcon
                            icon={["fas", "plus"]}
                            size="xs"
                            color="gray"
                            style={{
                              cursor:
                                quantity === product.inventory
                                  ? "not-allowed"
                                  : undefined,
                              fontSize: "9px",
                            }}
                            className="qty-control-icon"
                          />
                        </div>
                      </div>
                    )}
                    <hr />

                    <Button
                      disabled={product.inventory === 0 || addToCartLoading}
                      loading={addToCartLoading}
                      width="100%"
                      height="40px"
                      className="product-detail-btn"
                      onClick={
                        !paymentPlan
                          ? () => history.push("/select-plan")
                          : paymentPlan === "ONETIME" &&
                            !giftBoxes.includes(product.id)
                          ? cart && cart.length > 0
                            ? () => setOpenDialog(true)
                            : () => history.push("/select-plan")
                          : paymentPlan !== "ONETIME" &&
                            giftBoxes.includes(product.id)
                          ? cart && cart.length > 0
                            ? () => setOpenDialog(true)
                            : () => history.push("/gift-boxes")
                          : async () => {
                              const foundItem = cart
                                ? cart.find(
                                    (item) => item.product === product.id
                                  )
                                : undefined;

                              if (
                                foundItem &&
                                foundItem.quantity + quantity >
                                  product.inventory
                              ) {
                                const allowedQty =
                                  product.inventory - foundItem.quantity;
                                setQuantity(allowedQty === 0 ? 1 : allowedQty);
                                alert(
                                  `You already have "${foundItem.quantity} pcs" of this item in your cart, so maximum quantity allowed for this item is "${allowedQty} pcs".`
                                );
                                return;
                              }

                              const finished = await addToCart(
                                product.id,
                                quantity,
                                userUUID,
                                product.inventory
                              );

                              if (finished) {
                                setAddedCartItem({ product, quantity });
                                setQuantity(1);
                              }
                            }
                      }
                    >
                      {!paymentPlan ? (
                        "GET STARTED"
                      ) : paymentPlan === "ONETIME" &&
                        !giftBoxes.includes(product.id) ? (
                        "GET STARTED"
                      ) : paymentPlan !== "ONETIME" &&
                        giftBoxes.includes(product.id) ? (
                        "GET STARTED"
                      ) : (
                        <>
                          <FontAwesomeIcon
                            icon={["fas", "plus"]}
                            className="addtocart-plus"
                            style={{
                              cursor:
                                quantity === product.inventory
                                  ? "not-allowed"
                                  : undefined,
                            }}
                          />{" "}
                          Add to cart
                        </>
                      )}
                    </Button>
                  </div>
                ) : (
                  <div className="qty-control">
                    <Button
                      disabled={
                        (!cart
                          ? false
                          : calculateCartQuantity(cart) === itemLimit &&
                            itemLimit !== 0) ||
                        product.inventory === 0 ||
                        addToCartLoading
                      }
                      loading={addToCartLoading}
                      width="100%"
                      height="40px"
                      className="product-detail-btn"
                      onClick={
                        !paymentPlan
                          ? () => history.push("/select-plan")
                          : paymentPlan === "ONETIME" &&
                            !giftBoxes.includes(product.id)
                          ? cart && cart.length > 0
                            ? () => setOpenDialog(true)
                            : () => history.push("/select-plan")
                          : paymentPlan !== "ONETIME" &&
                            giftBoxes.includes(product.id)
                          ? cart && cart.length > 0
                            ? () => setOpenDialog(true)
                            : () => history.push("/gift-boxes")
                          : async () => {
                              const foundItem = cart
                                ? cart.find(
                                    (item) => item.product === product.id
                                  )
                                : undefined;

                              if (
                                foundItem &&
                                foundItem.quantity + quantity >
                                  product.inventory
                              ) {
                                const allowedQty =
                                  product.inventory - foundItem.quantity;
                                setQuantity(allowedQty === 0 ? 1 : allowedQty);
                                alert(
                                  `You already have "${foundItem.quantity} pcs" of this item in your cart, so maximum quantity allowed for this item is "${allowedQty} pcs".`
                                );
                                return;
                              }

                              const finished = await addToCart(
                                product.id,
                                quantity,
                                userUUID,
                                product.inventory
                              );

                              if (finished) {
                                setAddedCartItem({ product, quantity });
                                setQuantity(1);
                              }
                            }
                      }
                    >
                      {!paymentPlan ? (
                        "GET STARTED"
                      ) : paymentPlan === "ONETIME" &&
                        !giftBoxes.includes(product.id) ? (
                        "GET STARTED"
                      ) : paymentPlan !== "ONETIME" &&
                        giftBoxes.includes(product.id) ? (
                        "GET STARTED"
                      ) : (
                        <>
                          <FontAwesomeIcon
                            icon={["fas", "plus"]}
                            className="addtocart-plus"
                            style={{
                              cursor:
                                quantity === product.inventory
                                  ? "not-allowed"
                                  : undefined,
                            }}
                          />{" "}
                          Add to cart
                        </>
                      )}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {openDialog && paymentPlan === "ONETIME" && (
          <AlertDialog
            header="You Have Gift Boxes In Your Shopping Cart"
            message={`Would you like to continue with your Gift Box Purchase or Cancel and select a subscription plan?`}
            onConfirm={() => {
              cart.forEach((item) => {
                deleteItem(item);
              });

              setOpenDialog(false);

              if (paymentPlan !== "ONETIME" && giftBoxes.includes(product.id)) {
                history.push("/gift-boxes");
              } else {
                history.push("/select-plan");
              }
            }}
            confirmText="Select a subscription plan"
            cancelText="Continue with Gift Box"
            onCancel={() => {
              setOpenDialog(false);
            }}
            loading={loading}
            error={error}
          />
        )}
      </div>
    </>
  );
};

export default ProductDetail;
