import React, { useState } from "react";
import { useHistory } from "react-router-dom";

const HealthySnacksItem = ({ title, description, nospace }) => {
  const [overlay, setOverlay] = useState(false);

  const history = useHistory();

  return (
    <div
      onMouseEnter={() => setOverlay(true)}
      onMouseLeave={() => setOverlay(false)}
    >
      <div className="healthy-snack-container">
        <div className="healthy-snack-item">
          <div className="hsi-left">
            <div className={`hsi-img ${nospace}`}></div>
          </div>
          <div className="hsi-right">
            <div className="hsi-title">{title}</div>
            <div className="hsi-description">{description}</div>
          </div>
        </div>
      </div>
      {overlay ? (
        <div
          className="hsi-overlay"
          onClick={() => {
            history.push(`/collections/${nospace}`);
            return;
          }}
        >
          <div className="hsi-overlay-content">discover ></div>
        </div>
      ) : undefined}
    </div>
  );
};

export default HealthySnacksItem;
