import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MyCartItem from "../components/cart/MyCartItem";
import Spinner from "../components/Spinner";
import Button from "../components/Button";
import BackButton from "../components/BackButton";
import AlertDialog from "../components/dialogs/AlertDialog";
import { useCartContext } from "../state/cart-context";
import { usePaymentPlanContext } from "../state/payment-plan-context";
import { useCartLimitContext } from "../state/cart-limit-context";
import { useDialog } from "../hooks/useDialog";
import { useManageCart } from "../hooks/useManageCart";
import {
  calculateCartQuantity,
  calculateCartAmount,
  formatAmount,
} from "../helpers";

const MyCart = () => {
  const { cart } = useCartContext();
  const { paymentPlan } = usePaymentPlanContext();
  const { itemLimit } = useCartLimitContext();
  const { openDialog, setOpenDialog } = useDialog();
  const { removeCartItem, loading, error } = useManageCart();

  const [cartItemToDelete, setCartItemToDelete] = useState(null);
  const [dialogType, setDialogType] = useState(null);

  const history = useHistory();

  if (!cart) return <Spinner color="gray" height={50} width={50} />;

  if (cart && cart.length === 0)
    return (
      <h2 className="header--center">
        Your cart is empty, start{" "}
        <span
          className="header--orange header--link"
          onClick={() => history.push("/")}
        >
          shopping?
        </span>
      </h2>
    );

  return (
    <>
      <BackButton pathname={"/products"} />
      <div className="select-plan-steps">
        SELECT PLAN <FontAwesomeIcon icon={["fas", "caret-right"]} size="1x" />{" "}
        SELECT ITEMS <FontAwesomeIcon icon={["fas", "caret-right"]} size="1x" />{" "}
        <span> CHECKOUT </span>
      </div>
      <div className="page--my-cart">
        <div className="cart">
          <h2 className="header">Shopping cart</h2>

          <div className="cart-detail">
            {cart.map((item) => (
              <MyCartItem
                key={item.id}
                cartItem={item}
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                setCartItemToDelete={setCartItemToDelete}
              />
            ))}
          </div>
        </div>

        <div className="cart-summary" style={{ marginTop: "4rem" }}>
          <h3 className="header">Cart summary</h3>

          <div>
            <p className="paragraph">
              Quantity:{" "}
              <span className="paragraph paragraph--orange paragraph--focus">
                {calculateCartQuantity(cart)}
              </span>
            </p>

            <p className="paragraph">
              Amount:{" "}
              <span className="paragraph paragraph--orange paragraph--focus">
                ${formatAmount(calculateCartAmount(cart))}
              </span>
            </p>

            <div className="paragraph">
              Payment Plan:{" "}
              <span className="paragraph paragraph--orange paragraph--focus">
                {!paymentPlan ? (
                  <Spinner color="gray" height={10} width={10} />
                ) : paymentPlan === "ONETIME" ? (
                  paymentPlan
                ) : (
                  paymentPlan.type
                )}
              </span>
            </div>
          </div>

          <Button
            width="100%"
            className="btn--orange"
            style={{ margin: "1rem 0" }}
            onClick={() => {
              if (calculateCartQuantity(cart) > itemLimit) {
                setDialogType("cart-over-limit");
                setOpenDialog(true);
                return;
              } else if (calculateCartQuantity(cart) < itemLimit) {
                setDialogType("cart-below-limit");
                setOpenDialog(true);
                return;
              } else {
                history.push("/buy/select-address");
              }
            }}
          >
            Proceed to checkout
          </Button>
        </div>

        {openDialog && cartItemToDelete && (
          <AlertDialog
            header="Please confirm"
            message={`Are you sure you want to delete the "${cartItemToDelete.item.title}" from your cart?`}
            onCancel={() => {
              setCartItemToDelete(null);
              setOpenDialog(false);
            }}
            onConfirm={async () => {
              if (cartItemToDelete) {
                const finished = await removeCartItem(
                  cartItemToDelete.item.id,
                  cartItemToDelete.user
                );

                if (finished) {
                  setCartItemToDelete(null);
                  setOpenDialog(false);
                }
              }
            }}
            loading={loading}
            error={error}
          />
        )}

        {openDialog && dialogType === "cart-over-limit" && (
          <AlertDialog
            header="Please update cart quantity!"
            message={
              "You have exeeded the quantity allowed for your plan! Please remove some items to proceed."
            }
            onConfirm={() => {
              setDialogType(null);
              setOpenDialog(false);
            }}
            confirmText="Ok"
          />
        )}

        {openDialog && dialogType === "cart-below-limit" && (
          <AlertDialog
            header="Please update cart quantity!"
            message={
              "You have less items than the quantity allowed for your plan! Please add more items to proceed."
            }
            onConfirm={() => {
              setDialogType(null);
              setOpenDialog(false);
            }}
            confirmText="Ok"
          />
        )}
      </div>
    </>
  );
};

export default MyCart;
