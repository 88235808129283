import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useForm } from "react-hook-form";

import Spinner from "../components/Spinner";
import Button from "../components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PageHeader from "../components/PageHeader";
import CheckoutSteps from "../components/CheckoutSteps";
import OrderConfirmation from "../components/dialogs/OrderConfirmation";
import { usePaymentPlanContext } from "../state/payment-plan-context";
import { useCartLimitContext } from "../state/cart-limit-context";
import { useCartContext } from "../state/cart-context";
import { useDialog } from "../hooks/useDialog";
import { useFetchCards } from "../hooks/useFetchCards";
import { useAuthContext } from "../state/auth-context";
import { useAuthenticate } from "../hooks/useAuthenticate";
import { useManageShippingAddress } from "../hooks/useManageShippingAddress";
import { useCheckout } from "../hooks/useCheckout";
import { useManageSubscription } from "../hooks/useManageSubscription";
import { useOrderDataContext } from "../state/order-data-context";
import {
  calculateCartAmount,
  calculateCartQuantity,
  formatAmount,
  subCoupons,
  gbCoupons,
} from "../helpers";
import CheckoutCartItem from "../components/cart/CheckoutCartItem";

const Checkout = () => {
  let intervalId;

  const { itemLimit, setItemLimit } = useCartLimitContext();
  const { paymentPlan } = usePaymentPlanContext();

  const { cart } = useCartContext();
  const { cancel, cancelLoading, cancelError } = useManageSubscription();
  const {
    signup,
    loading: signupLoading,
    error: signupError,
    socialLogin,
  } = useAuthenticate();
  const {
    authState: { userInfo },
  } = useAuthContext();
  const { completePayment, createStripeCustomerId, subscribe, loading, error } =
    useCheckout();
  const {
    addNewAddress,
    loading: addressLoading,
    error: addressError,
  } = useManageShippingAddress();
  const { orderData, setOrderData } = useOrderDataContext();

  const { register, errors, handleSubmit, reset } = useForm();
  const { openDialog, setOpenDialog } = useDialog();

  const [orderSummary, setOrderSummary] = useState();
  const [useCard, setUseCard] = useState({ type: "new" });
  const [disabled, setDisabled] = useState(true);
  const [newCardError, setNewCardError] = useState("");
  const [openSetDefault, setOpenSetDefault] = useState(false);
  const [cardToDelete, setCardToDelete] = useState(null);
  const [dialogType, setDialogType] = useState(null);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [addAddress, setAddAddress] = useState(false);
  const [couponCode, setCouponCode] = useState(null);
  const [appliedCoupon, setAppliedCoupon] = useState("");
  const [discountToDisplay, setDiscountToDisplay] = useState(0);
  const [couponError, setCouponError] = useState("");
  const [defaultCoupon, setDefaultCoupon] = useState("");

  const [subscriptionToUpdate, setSubscriptionToUpdate] = useState(null);
  const [orderIdToUpdate, setOrderIdToUpdate] = useState(null);

  const [signupData, setSignupData] = useState({});
  const [address, setAddress] = useState({});

  const {
    userCards,
    stripeCustomer,
    setUserCards,
    loading: fetchCardsLoading,
    error: fetchCardsError,
  } = useFetchCards(userInfo);

  const history = useHistory();
  const btnRef = useRef(null);
  const elements = useElements();
  const stripe = useStripe();

  useEffect(() => {
    const localSelecteditemLimit = JSON.parse(
      window.localStorage.getItem("selecteditemLimit")
    );

    if (itemLimit === localSelecteditemLimit) return;

    setItemLimit(localSelecteditemLimit);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (cart && cart.length > 0)
      setOrderSummary({
        paymentPlan,
        quantity: calculateCartQuantity(cart),
        amount: calculateCartAmount(cart),
        orderItems: cart,
      });
  }, [cart, paymentPlan]);

  useEffect(() => {
    if (!itemLimit) return;

    if (itemLimit === 5) {
      return;
    } else if (itemLimit === 10) {
      setCouponCode("Ms0negKf");
      setDefaultCoupon('Ms0negKf')
      setDiscountToDisplay(1000);
    } else if (itemLimit === 15) {
      setCouponCode("QULbKMBb");
      setDefaultCoupon("QULbKMBb");
      setDiscountToDisplay(1500);
    }
  }, [itemLimit]);

  const handleCardChange = (e) => {
    setDisabled(e.empty || !e.complete);
    setNewCardError(e.error ? e.error.message : "");

    if (e.complete) setNewCardError("");
  };

  useEffect(() => {
    if (window.sessionStorage.getItem("orderIdToUpdate")) {
      const orderToUpdate = JSON.parse(
        window.sessionStorage.getItem("orderIdToUpdate")
      );

      const subToUpdate = JSON.parse(
        window.sessionStorage.getItem("subscriptionId")
      );

      setOrderIdToUpdate(orderToUpdate);
      setSubscriptionToUpdate(subToUpdate);
    }
  }, []);

  useEffect(() => {
    if (addAddress) {
      delete address.password;

      if (document.querySelector("#emailInput")) {
        document.querySelector("#emailInput").value = "";
      }
      setAddAddress(false);
      handleAddNewAddress();
    }
  }, [userInfo]);

  // ---------------onChange

  // Signup
  const emailValue = (e) => {
    setSignupData({
      ...signupData,
      email: e.target.value,
    });

    setOrderData({
      ...orderData,
      email: e.target.value,
    });
  };

  const passwordValue = (e) => {
    setSignupData({
      ...signupData,
      password: e.target.value,
    });
  };

  const fnameValue = (e) => {
    setSignupData({
      ...signupData,
      username: e.target.value,
    });

    // address

    setAddress({
      ...address,
      firstname: e.target.value,
    });

    setOrderData({
      ...orderData,
      firstname: e.target.value,
    });
  };

  const lnameValue = (e) => {
    setAddress({
      ...address,
      lastname: e.target.value,
    });

    setOrderData({
      ...orderData,
      lastname: e.target.value,
    });
  };

  const address1Value = (e) => {
    setAddress({
      ...address,
      address1: e.target.value,
    });
  };

  const address2Value = (e) => {
    setAddress({
      ...address,
      address2: e.target.value,
    });
  };

  const cityValue = (e) => {
    setAddress({
      ...address,
      city: e.target.value,
    });
  };

  const stateValue = (e) => {
    setAddress({
      ...address,
      state: e.target.value,
    });
  };

  const zipcodeValue = (e) => {
    setAddress({
      ...address,
      zipcode: e.target.value,
    });

    setOrderData({
      ...orderData,
      zipcode: e.target.value,
    });
  };

  const phoneValue = (e) => {
    setAddress({
      ...address,
      phone: e.target.value,
    });

    setOrderData({
      ...orderData,
      phone: e.target.value,
    });
  };

  //

  const handleCouponChange = (e) => {
    setAppliedCoupon(e.target.value);
    console.log(appliedCoupon)
    console.log('target', e.target.value)
  };

  // -------------------

  const handleApplyCoupon = () => {

    console.log(appliedCoupon)


    //if onetime or sub
    //if the helper array includes the code
    // return error if not in array or mismatch transaction type


    if (paymentPlan === "ONETIME") {

      if (gbCoupons.includes(appliedCoupon)) {

        //coupons .....

        if (appliedCoupon === "getgb20") {
          setCouponCode("getgb20");
          setDiscountToDisplay("2000");
          setCouponError("");
        }


      } else {
        setCouponError("Invalid Coupon Code!");
      }



    } else {

      if (subCoupons.includes(appliedCoupon)) {

        if (appliedCoupon === "getsub50") {
          setCouponCode("getsub50");
          setDiscountToDisplay("5000");
          setCouponError("");
        }



      } else {
        setCouponCode(defaultCoupon)
        setCouponError("Invalid Coupon Code!");
      }



    }





  };

  const handleCompletePayment = handleSubmit(async (data) => {
    if (!elements || !orderSummary || !stripe || !userInfo || !address) return;

    const { amount, quantity, orderItems } = orderSummary;

    const newOrder = {
      items: orderItems.map(({ quantity, user, item }) => ({
        quantity,
        user,
        item,
      })),
      amount,
      totalQuantity: quantity,
      shippingAddress: address,
      user: { id: userInfo.id, name: userInfo.username },
    };

    if (useCard.type === "new") {
      const cardElement = elements.getElement(CardElement);
      if (!cardElement) return;

      if (typeof data.save === "boolean") {
        const createPaymentIntentData = {
          amount: orderSummary.amount,
        };

        const payment_method = {
          card: cardElement,
          billing_details: { name: data.cardName },
        };

        if (data.save) {
          if (!userInfo.stripeCustomerId) {
            const stripeCustomerId = await createStripeCustomerId();

            createPaymentIntentData.customer = stripeCustomerId;
          } else {
            createPaymentIntentData.customer = userInfo.stripeCustomerId;
          }
        }
        const finished = await completePayment(
          { createPaymentIntentData, stripe, payment_method },
          {
            save: data.save,
            setDefault: data.setDefault,
            customerId: createPaymentIntentData.customer,
          },
          newOrder,
          orderItems
        );

        if (finished) {
          setOpenConfirmation(false);
          reset();
          history.push("/thankyou");
        }
      }
    } else if (useCard.type === "saved" && useCard.payment_method) {
      const createPaymentIntentData = {
        amount: orderSummary.amount,
        customer: stripeCustomer?.id,
        payment_method: useCard.payment_method,
      };

      const payment_method = useCard.payment_method;

      const finished = await completePayment(
        { createPaymentIntentData, stripe, payment_method },
        {
          save: data.save,
          setDefault: data.setDefault,
          customerId: stripeCustomer?.id,
        },
        newOrder,
        orderItems
      );

      if (finished) {
        setOpenConfirmation(false);
        reset();
        history.push("/thankyou");
      }
    }
  });

  // ------- subscription

  const handleSubscribe = handleSubmit(async (data) => {
    if (!elements || !orderSummary || !stripe || !userInfo || !address) return;

    const { amount, quantity, orderItems, paymentPlan } = orderSummary;

    if (paymentPlan === "ONETIME") return;

    const interval = paymentPlan.interval;

    const coupon = couponCode;

    const newOrder = {
      items: orderItems.map(({ quantity, user, item }) => ({
        interval,
        quantity,
        user,
        item,
      })),
      amount,
      totalQuantity: quantity,
      shippingAddress: address,
      user: { id: userInfo.id, name: userInfo.username },
    };

    // 1. Prepare data for subscription
    let subscriptionData = {
      stripeId: "",
      priceId: paymentPlan.priceId || "",
      quantity,
      newOrder: { amount },
      interval,
      coupon,
    };

    if (!userInfo.stripeCustomerId) {
      // If user doesn't have a stripe customer id yet, create a new stripe customer.
      const stripeCustomerId = await createStripeCustomerId();

      if (!stripeCustomerId) return;

      subscriptionData.stripeId = stripeCustomerId;
    } else {
      // If user already has the stripe customer id, use the existing one
      subscriptionData.stripeId = userInfo.stripeCustomerId;
    }

    // 2. Confirm the payment
    if (useCard.type === "new") {
      // New Card
      const cardElement = elements.getElement(CardElement);
      if (!cardElement) return;

      // 1. Prepare a payment method to complete the payment
      const payment_method = {
        card: cardElement,
        billing_details: { name: data.cardName },
      };

      // 2. Complete the payment
      const finished = await subscribe(
        subscriptionData,
        { stripe, payment_method, newOrder: { amount } },
        {
          save: data.save,
          setDefault: data.setDefault,
        },
        newOrder,
        orderItems
      );

      if (finished) {
        setOpenConfirmation(false);
        reset();
        history.push("/thankyou");
      }
    } else if (useCard.type === "saved" && useCard.payment_method) {
      // Saved Card
      // 1. Prepare a payment method to complete the payment
      const payment_method = useCard.payment_method;

      // 2. Complete the payment
      const finished = await subscribe(
        subscriptionData,
        { stripe, payment_method, newOrder: { amount } },
        {
          save: data.save,
          setDefault: data.setDefault,
        },
        newOrder,
        orderItems,
        orderSummary.amount
      );

      if (finished) {
        setOpenConfirmation(false);
        reset();
        history.push("/thankyou");
      }
    }
  });

  // ------------- Update Subscription

  const handleUpdateSubscription = handleSubmit(async (data) => {
    if (!stripe || !userInfo || !address) return;

    const subscription = await cancel(subscriptionToUpdate, orderIdToUpdate);
    if (subscription) {
      setSubscriptionToUpdate(null);
      setOrderIdToUpdate(null);
      window.sessionStorage.removeItem("subscriptionId");
      window.sessionStorage.removeItem("orderIdToUpdate");
      handleSubscribe(data);
    }
  });

  const handleAddNewAddress = handleSubmit(async (address) => {
    if (!userInfo) return;

    setOrderData({
      ...orderData,
      email: userInfo.email,
    });

    const finished = await addNewAddress(address, userInfo);

    if (finished) {
      if (orderSummary?.paymentPlan === "ONETIME") {
        handleCompletePayment();
      } else {
        handleSubscribe();
      }
    }
  });

  const handleSignup = handleSubmit(async (signupData) => {
    if (userInfo) {
      handleAddNewAddress();
      return;
    } else {
      await signup(signupData);
    }
  });

  const handleOrderData = () => {
    setOrderData({
      ...orderData,
      orderTotal: orderSummary.amount,
      type: paymentPlan === "ONETIME" ? "GiftBox" : `${itemLimit}packs`,
      readyToSend: true,
    });
  };

  return (
    <>
      <PageHeader pageTitle={"Checkout"} />

      <div className="head-page-route">
        <Link to="/">
          <span>Home</span>
        </Link>{" "}
        <FontAwesomeIcon
          className="fa-icons"
          icon={["fas", "chevron-right"]}
          size="xs"
        />{" "}
        <Link to="/welcome">
          <span>Get Started</span>
        </Link>{" "}
        <FontAwesomeIcon
          className="fa-icons"
          icon={["fas", "chevron-right"]}
          size="xs"
        />{" "}
        Checkout
      </div>

      {itemLimit && paymentPlan && paymentPlan !== "ONETIME" ? (
        <CheckoutSteps pageTitle={"Checkout"} />
      ) : undefined}

      <div className="checkout-container-mobile">
        <div className="chp-right">
          <div className="chp-selected-plan">
            <div className="chp-plan-title">My Plan</div>
            <div className="chp-plan-name">
              {paymentPlan === "ONETIME" ? "One-Time" : `${itemLimit} Packs`}
            </div>
            <div className="chp-plan-interval">
              {paymentPlan === "ONETIME"
                ? "Giftbox!"
                : `${paymentPlan.interval}ly in a box!`}
            </div>

            {paymentPlan !== "ONETIME" ? <> <div className="chp-plan-name">Cancel Anytime</div>
            <div className="chp-plan-interval">
              You can cancel or modify your next order in your account
            </div>

            <div className="chp-plan-name">Next Shipment</div>
            <div className="chp-plan-interval">
              You will get notified by email before the next shipment
            </div> </> : undefined}


          </div>
          <div className="chp-order-review">
            <div className="chp-plan-title">Order Review</div>
            <div className="checkout-cart-items">
              {cart?.map((item) => (
                <CheckoutCartItem key={item.id} cartItem={item} />
              ))}
            </div>

            <div className="chp-subtotal">
              <div className="chp-subtotal-title">Subtotal</div>
              {!cart ? (
                false
              ) : (
                <div className="chpi-price">
                  ${formatAmount(calculateCartAmount(cart))}
                </div>
              )}
            </div>

            <div className="chp-shipping">
              <div className="chp-shipping-title">Shipping</div>
              <div className="chpi-price">Free</div>
            </div>

            {/* ------ coupon -------- */}

            <div className="chp-coupon">
              <div className="chp-shipping-title">Coupon</div>
              <div className="coupon-control">
                <input
                  type="text"
                  className="chp-coupon-input"
                  onChange={handleCouponChange}
                />

                <button
                  className="chp-coupon-apply-btn"
                  onClick={handleApplyCoupon}
                >
                  Apply
                </button>
              </div>
            </div>
            {couponError && <p className="coupon-error">{couponError}</p>}

            {/* ------ coupon -------- */}

            <div className="chp-discount">
              {discountToDisplay ? (
                <>
                  <div className="chp-discount-title">Discount</div>
                  <div className="chpi-price">
                    ${formatAmount(discountToDisplay)}
                  </div>
                </>
              ) : undefined}
            </div>

            <div className="chp-total">
              <div className="chp-total-title">Total</div>
              {discountToDisplay ? (
                !cart ? (
                  false
                ) : (
                  <div className="chpi-price">
                    $
                    {formatAmount(
                      calculateCartAmount(cart) - discountToDisplay
                    )}
                  </div>
                )
              ) : !cart ? (
                false
              ) : (
                <div className="chpi-price">
                  ${formatAmount(calculateCartAmount(cart))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="checkout-page-container">
        <div className="chp-left">
          <div className="chp-section">
            <div className="chp-payment-method">
              <div className="chp-section-title">payment Method</div>
              <div className="chp-payment-description">
                Please enter payment and shipping information for checkout. You
                can always edit your payment information and shipping details on
                file for future transactions.
              </div>

              <div className="cho-payment-form">
                <form
                  className="form"
                  onSubmit={
                    subscriptionToUpdate
                      ? handleUpdateSubscription
                      : orderSummary?.paymentPlan === "ONETIME"
                      ? handleCompletePayment
                      : handleSubscribe
                  }
                >
                  {fetchCardsLoading ? (
                    <Spinner color="gray" height={30} width={30} />
                  ) : (
                    userCards?.data &&
                    userCards.data.length > 0 &&
                    userCards.data.map((method) => (
                      <label
                        key={method.id}
                        className="card"
                        htmlFor={method.id}
                      >
                        <input
                          type="radio"
                          name="card"
                          value={method.id}
                          style={{ width: "10%" }}
                          checked={
                            useCard.type === "saved" &&
                            useCard.payment_method === method.id
                          }
                          onChange={() => {
                            setUseCard({
                              type: "saved",
                              payment_method: method.id,
                            });
                            setDisabled(false);
                            reset();
                          }}
                        />

                        <p
                          className="paragraph darkblue"
                          style={{ width: "40%" }}
                        >
                          **** **** **** {method.card?.last4}
                        </p>

                        <p className="paragraph" style={{ width: "10%" }}>
                          {method.card?.brand === "visa" ? (
                            <FontAwesomeIcon
                              icon={["fab", "cc-visa"]}
                              size="2x"
                              color="#206CAB"
                            />
                          ) : method.card?.brand === "mastercard" ? (
                            <FontAwesomeIcon
                              icon={["fab", "cc-mastercard"]}
                              size="2x"
                              color="#EB2230"
                            />
                          ) : method.card?.brand === "amex" ? (
                            <FontAwesomeIcon
                              icon={["fab", "cc-amex"]}
                              size="2x"
                              color="#099DD9"
                            />
                          ) : (
                            method.card?.brand
                          )}
                        </p>

                        <div style={{ width: "30%" }}>
                          {method.id ===
                          stripeCustomer?.invoice_settings
                            .default_payment_method ? (
                            <p className="paragraph--center paragraph--focus">
                              Default
                            </p>
                          ) : useCard.type === "saved" &&
                            useCard.payment_method === method.id ? (
                            <div>
                              <input
                                type="checkbox"
                                name="setDefault"
                                ref={register}
                              />
                              <label
                                htmlFor="setDefault"
                                className="set-default-card"
                              >
                                Set as default
                              </label>
                            </div>
                          ) : undefined}
                        </div>

                        <p
                          className="paragraph"
                          style={{ width: "10%", cursor: "pointer" }}
                          onClick={() => {
                            setCardToDelete(method);
                            setOpenDialog(true);
                            setDialogType("remove_card");
                          }}
                        >
                          <FontAwesomeIcon
                            icon={["fas", "trash-alt"]}
                            size="1x"
                            color="red"
                          />
                        </p>
                      </label>
                    ))
                  )}
                  <div className="form--new-card">
                    <label htmlFor="newCard" className="card card--new">
                      <input
                        type="radio"
                        name="card"
                        checked={useCard.type === "new"}
                        style={{ width: "10%" }}
                        onChange={() => {
                          setUseCard({ type: "new" });
                          setDisabled(true);
                          reset();
                        }}
                      />

                      <h4
                        className="paragraph paragraph--bold cc-radio-text darkblue"
                        style={{ width: "50%" }}
                      >
                        Credit Card
                      </h4>

                      <p className="paragraph" style={{ width: "50%" }}></p>

                      <div className="new-card__logo" style={{ width: "45%" }}>
                        <FontAwesomeIcon
                          className="cc-icons"
                          icon={["fab", "cc-visa"]}
                          size="2x"
                          style={{ margin: "0 0.5rem" }}
                          color="#206CAB"
                        />
                        <FontAwesomeIcon
                          className="cc-icons"
                          icon={["fab", "cc-mastercard"]}
                          size="2x"
                          style={{ margin: "0 0.5rem" }}
                          color="#EB2230"
                        />
                        <FontAwesomeIcon
                          className="cc-icons"
                          icon={["fab", "cc-amex"]}
                          size="2x"
                          style={{ margin: "0 0.5rem" }}
                          color="#099DD9"
                        />
                      </div>

                      <p className="paragraph" style={{ width: "10%" }}>
                        {" "}
                      </p>
                    </label>

                    {useCard.type === "new" && (
                      <div className="new-card__form">
                        <div className="form__input-container form__input-container--card">
                          <input
                            type="text"
                            className="input input--card-name"
                            name="cardName"
                            placeholder="Name on card"
                            ref={register({
                              required: "Card name is required.",
                            })}
                          />

                          {errors.cardName && (
                            <p className="paragraph paragraph--small paragraph--error">
                              {errors.cardName.message}
                            </p>
                          )}
                        </div>

                        <div className="form__input-container form__input-container--card">
                          <CardElement
                            options={{
                              style: {
                                base: { color: "blue", iconColor: "blue" },
                                invalid: { color: "red", iconColor: "red" },
                              },
                            }}
                            onChange={handleCardChange}
                          />

                          {newCardError && (
                            <p className="paragraph paragraph--error">
                              {newCardError}
                            </p>
                          )}
                        </div>

                        <div className="form__set-new-card">
                          <div className="form__input-container">
                            <input
                              type="checkbox"
                              name="save"
                              ref={register}
                              onClick={() => {
                                setOpenSetDefault((prev) => !prev);
                              }}
                            />
                            <label
                              htmlFor="saveCard"
                              className="paragraph darkblue"
                            >
                              Save this card
                            </label>
                          </div>

                          {openSetDefault && (
                            <div className="form__input-container">
                              <input
                                type="checkbox"
                                name="setDefault"
                                ref={register}
                              />
                              <label htmlFor="setDefault" className="paragraph">
                                Save as default
                              </label>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <button
                    ref={btnRef}
                    style={{ display: "none" }}
                    disabled={
                      !stripe ||
                      !useCard ||
                      disabled ||
                      loading ||
                      cancelLoading
                    }
                  ></button>
                </form>
              </div>
            </div>
          </div>

          <div className="chp-section">
            <div className="chp-address">
              <div className="chp-section-title">shipping address</div>
              <div className="chp-half-section">
                <div className="chp-half-item">
                  <label className="chp-label" htmlFor="username">
                    First Name: *
                  </label>
                  <input
                    // defaultValue={userInfo?.shippingAddresses[0].username}
                    onChange={fnameValue}
                    type="text"
                    className="chp-input chp-address-firstname"
                    placeholder="First Name"
                    name="username"
                    ref={register}
                    required
                  />
                </div>
                <div className="chp-half-item">
                  <label className="chp-label" htmlFor="userlname">
                    Last Name: *
                  </label>
                  <input
                    // defaultValue={userInfo?.shippingAddresses[0].userlname}
                    onChange={lnameValue}
                    type="text"
                    className="chp-input chp-address-lastname"
                    placeholder="Last Name"
                    name="userlname"
                    ref={register}
                    required
                  />
                </div>
              </div>
              <div>
                <label className="chp-label" htmlFor="address1">
                  Address 1: *
                </label>
                <input
                  // defaultValue={userInfo?.shippingAddresses[0].address1}
                  onChange={address1Value}
                  type="text"
                  placeholder="Street Address"
                  name="address1"
                  className="chp-input"
                  ref={register}
                  required
                />
                <label className="chp-label" htmlFor="address2">
                  Address 2:
                </label>
                <input
                  // defaultValue={userInfo?.shippingAddresses[0].address2}
                  onChange={address2Value}
                  type="text"
                  placeholder="Apartment, Unit, Suite, etc."
                  name="address2"
                  className="chp-input"
                  ref={register}
                />
                <div className="chp-half-section">
                  <div className="chp-half-item">
                    <label className="chp-label" htmlFor="city">
                      City: *
                    </label>
                    <input
                      // defaultValue={userInfo?.shippingAddresses[0].city}
                      onChange={cityValue}
                      type="text"
                      name="city"
                      className="chp-input"
                      ref={register}
                      required
                    />
                  </div>
                  <div className="chp-half-half-item">
                    <label className="chp-label" htmlFor="state">
                      State: *
                    </label>
                    <input
                      // defaultValue={userInfo?.shippingAddresses[0].state}
                      onChange={stateValue}
                      type="text"
                      name="state"
                      className="chp-input"
                      ref={register}
                      required
                    />
                  </div>
                  <div className="chp-half-half-item">
                    <label className="chp-label" htmlFor="zipcode">
                      Zipcode: *
                    </label>
                    <input
                      // defaultValue={userInfo?.shippingAddresses[0].zipcode}
                      onChange={zipcodeValue}
                      type="text"
                      name="zipcode"
                      className="chp-input"
                      ref={register}
                      required
                    />
                  </div>
                </div>
                <div className="chp-half-item">
                  <label className="chp-label" htmlFor="phone">
                    Phone: *
                  </label>
                  <input
                    // defaultValue={userInfo?.shippingAddresses[0].phone}
                    onChange={phoneValue}
                    type="text"
                    name="phone"
                    className="chp-input"
                    ref={register}
                    required
                  />
                </div>
              </div>
            </div>
          </div>

          {!userInfo ? (
            <div className="chp-section">
              <div className="chp-signup">
                <div className="chp-section-title">create an account</div>
                <div className="chp-half-section">
                  <div className="chp-half-item">
                    <label className="chp-label" htmlFor="email">
                      Email: *
                    </label>
                    <input
                      onChange={emailValue}
                      className="chp-input"
                      name="email"
                      id="emailInput"
                      placeholder="Your email"
                      error={errors.email?.message}
                      ref={register}
                    />
                  </div>

                  <div className="chp-half-item">
                    <label className="chp-label" htmlFor="password">
                      Password: *
                    </label>
                    <input
                      onChange={passwordValue}
                      className="chp-input"
                      id="passwordInput"
                      name="password"
                      type="password"
                      placeholder="Your password"
                      error={errors.password?.message}
                      ref={register}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : undefined}
        </div>
        <div className="chp-right checkout-container-desktop">
          <div className="chp-selected-plan">
            <div className="chp-plan-title">My Plan</div>
            <div className="chp-plan-name">
              {paymentPlan === "ONETIME" ? "One-Time" : `${itemLimit} Packs`}
            </div>
            <div className="chp-plan-interval">
              {paymentPlan === "ONETIME"
                ? "Giftbox!"
                : `${paymentPlan.interval}ly in a box!`}
            </div>

            {paymentPlan !== "ONETIME" ? <> <div className="chp-plan-name">Cancel Anytime</div>
            <div className="chp-plan-interval">
              You can cancel or modify your next order in your account
            </div>

            <div className="chp-plan-name">Next Shipment</div>
            <div className="chp-plan-interval">
              You will get notified by email before the next shipment
            </div> </> : undefined}

          </div>
          <div className="chp-order-review">
            <div className="chp-plan-title">Order Review</div>
            <div className="checkout-cart-items">
              {cart?.map((item) => (
                <CheckoutCartItem key={item.id} cartItem={item} />
              ))}
            </div>

            <div className="chp-subtotal">
              <div className="chp-subtotal-title">Subtotal</div>
              {!cart ? (
                false
              ) : (
                <div className="chpi-price">
                  ${formatAmount(calculateCartAmount(cart))}
                </div>
              )}
            </div>

            <div className="chp-shipping">
              <div className="chp-shipping-title">Shipping</div>
              <div className="chpi-price">Free</div>
            </div>

            {/* ------ coupon -------- */}
{/*
            <div className="chp-coupon">
              <div className="chp-shipping-title">Coupon</div>
              <div className="coupon-control">
                <input
                  type="text"
                  className="chp-coupon-input"
                  onChange={handleCouponChange}
                />

                <button
                  className="chp-coupon-apply-btn"
                  onClick={handleApplyCoupon}
                >
                  Apply
                </button>
              </div>
            </div>

            {couponError && <p className="coupon-error">{couponError}</p>} */}

            {/* ------ coupon -------- */}

            <div className="chp-discount">
              {discountToDisplay ? (
                <>
                  <div className="chp-discount-title">Discount</div>
                  <div className="chpi-price">
                    ${formatAmount(discountToDisplay)}
                  </div>
                </>
              ) : undefined}
            </div>

            <div className="chp-total">
              <div className="chp-total-title">Total</div>
              {discountToDisplay ? (
                !cart ? (
                  false
                ) : (
                  <div className="chpi-price">
                    $
                    {formatAmount(
                      calculateCartAmount(cart) - discountToDisplay
                    )}
                  </div>
                )
              ) : !cart ? (
                false
              ) : (
                <div className="chpi-price">
                  ${formatAmount(calculateCartAmount(cart))}
                </div>
              )}
            </div>

            <Button
              width={"100%"}
              height={"100%"}
              className="chp-btn"
              style={{ bottom: "40px" }}
              onClick={() => {
                setOpenConfirmation(true);
                setAddAddress(true);
                handleOrderData();
              }}
              disabled={
                (!userInfo && !signupData.email) ||
                (!userInfo && !signupData.password) ||
                loading ||
                signupLoading ||
                cancelLoading ||
                addressLoading ||
                !address.firstname ||
                !address.lastname ||
                !address.address1 ||
                !address.city ||
                !address.state ||
                !address.zipcode ||
                !address.phone
              }
              loading={
                loading || cancelLoading || signupLoading || addressLoading
              }
            >
              place order >
            </Button>
          </div>
        </div>
      </div>

      <div className="checkout-container-mobile">
        <Button
          width={"100%"}
          height={"100%"}
          className="chp-btn"
          style={{ bottom: "40px" }}
          onClick={() => {
            setOpenConfirmation(true);
            setAddAddress(true);
            handleOrderData();
          }}
          disabled={
            (!userInfo && !signupData.email) ||
            (!userInfo && !signupData.password) ||
            loading ||
            signupLoading ||
            cancelLoading ||
            addressLoading ||
            !address.firstname ||
            !address.lastname ||
            !address.address1 ||
            !address.city ||
            !address.state ||
            !address.zipcode ||
            !address.phone
          }
          loading={loading || cancelLoading || signupLoading || addressLoading}
        >
          place order >
        </Button>
      </div>

      {openConfirmation && (
        <OrderConfirmation
          setOpenConfirmation={setOpenConfirmation}
          handleSignup={handleSignup}
          handleUpdateSubscription={handleUpdateSubscription}
          address={address}
          loading={loading}
          cancelLoading={cancelLoading}
          signupLoading={signupLoading}
          addressLoading={addressLoading}
          discountToDisplay={discountToDisplay}
        />
      )}
    </>
  );
};

export default Checkout;
