import { useState, useEffect } from "react";

import { useAsyncCall } from "./useAsyncCall";
import { functions } from "../firebase/config";

export const useFetchCards = (userInfo) => {
  const { loading, setLoading, error, setError } = useAsyncCall();

  const [userCards, setUserCards] = useState(null);
  const [stripeCustomer, setStripeCustomer] = useState(null);

  useEffect(() => {
    if (!userInfo?.stripeCustomerId) return;

    let mounted = true;

    const fetchCards = async (customerId) => {
      try {
        setLoading(true);

        const listPaymentMethods = functions.httpsCallable(
          "listPaymentMethods"
        );

        const customerData = await listPaymentMethods({ customerId });

        if (mounted) {
          setUserCards(customerData.data.paymentMethods);
          setStripeCustomer(customerData.data.customer);
          setLoading(false);
        }
      } catch (err) {
        if (mounted) {
          setError("Sorry, something went wrong.");
          setLoading(false);
        }
      }
    };

    fetchCards(userInfo.stripeCustomerId);

    return () => {
      mounted = false
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setUserCards, setStripeCustomer, setLoading, setError]);

  return { userCards, stripeCustomer, setUserCards, loading, error };
};
