import React from 'react';

const HowItWorks = () => {
  return (
    <div className='howitworks-container'>
      <div className="hiw-content">
        <div className="hiw-title">
          How it works
        </div>
        <div className="hiw-items">
          <div className="hiw-item">
            <div className="hiw-item-img plan-box-icon"></div>
            <div className="hiw-item-title">Choose Your Plan</div>
            <div className="hiw-item-description">Choose a curated subscription size that works for you</div>
          </div>
          <div className="hiw-item">
            <div className="hiw-item-img snacks-icon"></div>
            <div className="hiw-item-title">
Select Your Snacks</div>
            <div className="hiw-item-description">Nutritious snacks made from premium quality ingredients</div>
          </div>
          <div className="hiw-item">
            <div className="hiw-item-img delivery-truck-icon"></div>
            <div className="hiw-item-title">Get Your Delivery</div>
            <div className="hiw-item-description">Delivered to your doorstep in a beautiful hand-crafted package</div>
          </div>
        </div>
      </div>


    </div>
  );
};

export default HowItWorks;
