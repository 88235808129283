import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import HealthySnackItem from "../HealthySnacksItem";
import { collections } from "../../helpers";

export default function MyCarousel() {
  return (
    <div className="home">
      <div className="carousel-title">Healthy Snacks</div>
      <Swiper
        loop={true}
        pagination={{
          clickable: true,
          observer: true,
          observeParents: true,
          parallax: true,
        }}
        navigation={true}
        draggable={true}
        breakpoints={{
          // Very Large Screen
          2050: {
            slidesPerView: 5,
            spaceBetween: 2,
          },
          1536: {
            slidesPerView: 4,
            spaceBetween: 2,
          },
          // Large Screen
          1024: {
            slidesPerView: 3,
            spaceBetween: 2,
          },
          // Medium Screen
          640: {
            slidesPerView: 2,
            spaceBetween: 2,
          },
          // Small Screen
          320: {
            slidesPerView: 1,
            spaceBetween: 2,
          },
        }}
      >
        {collections.map((collection) => (
          <SwiperSlide key={collection.title}>
            <HealthySnackItem
              key={collection.title}
              title={collection.title}
              description={collection.description}
              nospace={collection.nospace}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
