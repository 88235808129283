import React from "react";

import { formatAmount } from "../../helpers";

const CheckoutCartItem = ({ cartItem }) => {
  const {
    quantity,
    item: { title, price },
  } = cartItem;

  return (
    <div className="checkoutcartitem-container">
      <div className="chpi-content">
        <div className="chpi-title">{title}</div>
        <div className="chpi-qty">{quantity}X</div>
        <div className="chpi-price">${formatAmount(price)}</div>
      </div>
    </div>
  );
};

export default CheckoutCartItem;
