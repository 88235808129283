import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import MainNav from "./components/nav/MainNav";
import Footer from "./components/Footer";
import UserDropdown from "./components/nav/UserDropdown";
import CartDropDown from "./components/nav/CartDropDown";
import { useAuthContext, openUserDropdown } from "./state/auth-context";
import { useModalContext } from "./state/modal-context";
import ViewContextProvider from "./state/view-context";
import { useViewCartContext } from "./state/view-cart-context";
import { useMobileNavContext } from "./state/mobile-view-context";
// import PasswordProtect from "./components/PasswordProtect";
import MobileDropDown from "./components/nav/MobileDropDown";
import AnnouncementBar from "./components/AnnouncementBar";

const Layout = ({ children }) => {

  const {
    authState: { isUserDropdownOpen },
    authDispatch,
  } = useAuthContext();
  const { modal } = useModalContext();
  const { viewCart } = useViewCartContext();
  const { mobileNav } = useMobileNavContext();

  const location = useLocation();

  useEffect(() => {
    if (isUserDropdownOpen) authDispatch(openUserDropdown(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <div>
      {/* <PasswordProtect /> */}
      <ViewContextProvider>
        <AnnouncementBar />
        {viewCart && <CartDropDown />}
        <MainNav />
        {mobileNav && <MobileDropDown />}
        {isUserDropdownOpen && <UserDropdown />}
      </ViewContextProvider>
      <div className="page">{children}</div>

      {modal && modal}

      <Footer />
    </div>
  );
};

export default Layout;
