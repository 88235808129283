import React from "react";

const SubscriptionPlanItem = ({ plan }) => {
  return (
    <div>
      <div className="mp-mobile">
        <div className="manage-plan-content">
          <div className="mp-section-title">Plan Name</div>
          <div className="mp-section-content">{plan.totalQuantity} Packs</div>
          <div className="mp-section-title">Quantity</div>
          <div className="mp-section-content">
            {plan.totalQuantity} Items {plan.items[0].interval}ly
          </div>
          <div className="mp-section-title">Shipping Address</div>
          <div className="mp-section-content">
            <div className="mp-section-address-row">
              {plan.shippingAddress.firstname}, {plan.shippingAddress.lastname}
            </div>
            <div className="mp-section-address-row">
              {plan.shippingAddress.address1}
            </div>
            <div className="mp-section-address-row">
              {plan.shippingAddress.address2}
            </div>
            <div className="mp-section-address-row">
              {plan.shippingAddress.city}, {plan.shippingAddress.state}{" "}
              {plan.shippingAddress.zipcode}
            </div>
          </div>

          <div className="mp-section-title">Plan Status</div>
          <div className="mp-section-content">Active</div>
        </div>
      </div>
      <div className="mp-desktop">
        <div className="manage-plan-content">
          <div className="mp-left">
            <div className="mp-section-title">Plan Name</div>
            <div className="mp-section-content">{plan.totalQuantity} Packs</div>
            <div className="mp-section-title">Shipping Address</div>
            <div className="mp-section-content">
              <div className="mp-section-address-row">
                {plan.shippingAddress.firstname},{" "}
                {plan.shippingAddress.lastname}
              </div>
              <div className="mp-section-address-row">
                {plan.shippingAddress.address1}
              </div>
              <div className="mp-section-address-row">
                {plan.shippingAddress.address2}
              </div>
              <div className="mp-section-address-row">
                {plan.shippingAddress.city}, {plan.shippingAddress.state}{" "}
                {plan.shippingAddress.zipcode}
              </div>
            </div>
          </div>
          <div className="mp-right">
            <div className="mp-section-title">Quantity</div>
            <div className="mp-section-content">
              {plan.totalQuantity} Items {plan.items[0].interval}ly
            </div>
            <div className="mp-section-title">Plan Status</div>
            <div className="mp-section-content">Active</div>
          </div>
        </div>
      </div>

      <div className="devider"></div>
    </div>
  );
};

export default SubscriptionPlanItem;
