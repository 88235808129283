import React from "react";
import {Link} from 'react-router-dom'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PageNotFound = () => {
  return (
    <div className="not-found-page">
      <div className="head-page-route">
        <Link to="/">
          <span>Home</span>
        </Link>{" "}
        <FontAwesomeIcon
          className="fa-icons"
          icon={["fas", "chevron-right"]}
          size="xs"
        />{" "}
        PageNotFound{" "}
      </div>
      <h2>Page Not Found!</h2>
    </div>
  );
};

export default PageNotFound;
