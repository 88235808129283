import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import PageHeader from "../components/PageHeader";
import Spinner from "../components/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pagination from "../components/Pagination";
import User from "../components/manage-users/User";
import { useSearchContext } from "../state/search-context";
import { useFetchUsers } from "../hooks/useFetchUsers";
import { usePagination } from "../hooks/usePagination";

const usersPerPage = 10;

const ManageUsers = ({ userInfo }) => {
  const { users, userCounts, loading, error, queryMoreUsers } =
    useFetchUsers(userInfo);
  const { searchedItems } = useSearchContext();
  const { page, totalPages } = usePagination(
    userCounts,
    usersPerPage,
    undefined,
    searchedItems
  );

  const [usersByPage, setUsersByPage] = useState(users);
  const [paginatedSearchedItems, setPaginatedSearchedItems] =
    useState(searchedItems);

  useEffect(() => {
    const startIndex = usersPerPage * (page - 1);
    const endIndex = usersPerPage * page;

    if (searchedItems) {
      setPaginatedSearchedItems(searchedItems.slice(startIndex, endIndex));
      setUsersByPage([]);
    } else {
      if (!users) return;

      // Check if we need to query more users
      if (users.length < userCounts && users.length < usersPerPage * page) {
        queryMoreUsers();
        return;
      }

      setUsersByPage(users.slice(startIndex, endIndex));
      setPaginatedSearchedItems(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedItems, users, page, userCounts]);

  if (loading) return <Spinner color="grey" height={50} width={50} />;

  if (error) return <h2 className="header--center">{error}</h2>;

  if (!users || users.length === 0)
    return <h2 className="header--center">No users.</h2>;

  return (
    <>
      <PageHeader pageTitle={"Manage Users"} />
      <div className="head-page-route">
        <Link to="/">
          <span>Home</span>
        </Link>{" "}
        <FontAwesomeIcon
          className="fa-icons"
          icon={["fas", "chevron-right"]}
          size="xs"
        />{" "}
        Manage Users{" "}
      </div>

      <div className="page--manage-users">
        <div className="pagination-container">
          <Pagination page={page} totalPages={totalPages} />
        </div>
        <table className="table table--manage-users">
          <thead>
            <tr>
              {/* Header */}
              <th
                className="table-cell manage-users-name"
                style={{ width: "20%" }}
                rowSpan={2}
              >
                Name
              </th>
              <th className="table-cell" style={{ width: "25%" }} rowSpan={2}>
                Email
              </th>
              <th className="table-cell manage-users-date" rowSpan={2}>
                Created At
              </th>

              <th className="table-cell  manage-users-rols-head" colSpan={3}>
                Role
              </th>
            </tr>

            {/* Sub header */}
            <tr>
              <th className="table-cell manage-users-rols">
                <div className="user-rols-desktop">Client</div>
                <div className="user-rols-mobile">
                  <p>C</p>
                  <p>L</p>
                  <p>I</p>
                  <p>E</p>
                  <p>N</p>
                  <p>T</p>
                </div>
              </th>
              <th className="table-cell manage-users-rols">
                <div className="user-rols-desktop">Admin</div>
                <div className="user-rols-mobile">
                  <p>A</p>
                  <p>D</p>
                  <p>M</p>
                  <p>I</p>
                  <p>N</p>
                </div>
              </th>
              <th className="table-cell manage-users-rols">
                <div className="user-rols-desktop">Super</div>
                <div className="user-rols-mobile">
                  <p>S</p>
                  <p>U</p>
                  <p>P</p>
                  <p>E</p>
                  <p>R</p>
                </div>
              </th>
            </tr>
          </thead>

          <tbody>
            {paginatedSearchedItems ? (
              <>
                {paginatedSearchedItems.length < 1 ? (
                  <tr>
                    <td colSpan={6}>
                      <h2 className="header--center">No users found.</h2>
                    </td>
                  </tr>
                ) : (
                  paginatedSearchedItems.map((user) => (
                    <User key={user.id} user={user} admin={userInfo} />
                  ))
                )}
              </>
            ) : (
              usersByPage &&
              usersByPage.map((user) => (
                <User key={user.id} user={user} admin={userInfo} />
              ))
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ManageUsers;
