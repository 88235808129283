import React, { createContext, useContext, useState, useEffect } from "react";

import { useAsyncCall } from "../hooks/useAsyncCall";
import { productsRef, snapshotToDoc, productCountsRef } from "../firebase";

const ProductsStateContext = createContext(undefined);
const ProductsDispatchContext = createContext(undefined);

const limitQuery = 100;

const initialProducts = {
  All: [],
  Nuts: [],
  "Dried Fruits": [],
  Seeds: [],
  "Mixed Nuts": [],
  "Gift Boxes": [],
};

const initialProductCounts = {
  All: 0,
  Nuts: 0,
  "Dried Fruits": 0,
  Seeds: 0,
  "Mixed Nuts": 0,
  "Gift Boxes": 0,
};

const ProductsContextProvider = ({ children }) => {
  const { loading, setLoading, error, setError } = useAsyncCall();
  const [products, setProducts] = useState(initialProducts);
  const [productCounts, setProductCounts] = useState(initialProductCounts);
  const [lastDocument, setLastDocument] = useState();

  const queryMoreProducts = async () => {
    try {
      if (!lastDocument) return;

      setLoading(true);

      const snapshots = await productsRef
        .orderBy("createdAt", "desc")
        .startAfter(lastDocument)
        .limit(limitQuery)
        .get();

      const newQueries = snapshots.docs.map((snapshot) =>
        snapshotToDoc(snapshot)
      );

      const lastVisible = snapshots.docs[snapshots.docs.length - 1];
      setLastDocument(lastVisible);

      setProducts((prev) => {
        const updatedProducts = {};

        Object.keys(initialProducts).forEach((cat) => {
          const category = cat;

          category === "All"
            ? (updatedProducts.All = [...prev.All, ...newQueries])
            : (updatedProducts[category] = [
                ...prev[category],
                ...newQueries.filter((item) => item.category === category),
              ]);
        });

        return updatedProducts;
      });

      setLoading(false);
    } catch (err) {
      const { message } = err;

      setError(message);
      setLoading(false);
    }
  };

  // Fetch the products collection from firestore (first query)
  useEffect(() => {
    setLoading(true);

    const unsubscribe = productsRef
      .orderBy("createdAt", "desc")
      .limit(limitQuery)
      .onSnapshot({
        next: (snapshots) => {
          const allProducts = snapshots.docs.map((snapshot) =>
            snapshotToDoc(snapshot)
          );

          // snapshots.forEach((snapshot) => {
          //   const product = snapshotToDoc(snapshot);

          //   allProducts.push(product);
          // });

          const lastVisible = snapshots.docs[snapshots.docs.length - 1];
          setLastDocument(lastVisible);

          const updatedProducts = {};

          Object.keys(initialProducts).forEach((cat) => {
            const category = cat;

            category === "All"
              ? (updatedProducts.All = allProducts)
              : (updatedProducts[category] = allProducts.filter(
                  (item) => item.category === category
                ));
          });

          setProducts(updatedProducts);
          setLoading(false);
        },
        error: (err) => {
          setError(err.message);
          setLoading(false);
        },
      });

    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const unsubscribe = productCountsRef
      .doc("counts")
      .onSnapshot((snapshot) => {
        const countsData = snapshot.data();

        setProductCounts(countsData);
      });

    return () => unsubscribe();
  }, []);

  return (
    <ProductsStateContext.Provider
      value={{ products, productCounts, loading, error, queryMoreProducts }}
    >
      <ProductsDispatchContext.Provider value={{ setProducts }}>
        {children}
      </ProductsDispatchContext.Provider>
    </ProductsStateContext.Provider>
  );
};

export default ProductsContextProvider;

export const useProductsContext = () => {
  const productsState = useContext(ProductsStateContext);
  const productsDispatch = useContext(ProductsDispatchContext);

  if (productsState === undefined || productsDispatch === undefined)
    throw new Error(
      "useProductsContext must be used within ProductsContextProvider."
    );

  return { productsState, productsDispatch };
};
