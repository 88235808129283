import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ToggleSection = ({ title, children }) => {
  const [toggleOpen, setToggleOpen] = useState(false);

  return (
    <div className="toggle-section">
      <div className="togglt-top" onClick={() => setToggleOpen(!toggleOpen)}>
        <div className="toggle-title">{title}</div>
        <div className="toggle-icon">
          {toggleOpen ? (
            <FontAwesomeIcon
              className="fa-icons"
              icon={["fas", "chevron-up"]}
              size="xs"
            />
          ) : (
            <FontAwesomeIcon
              className="fa-icons"
              icon={["fas", "chevron-down"]}
              size="xs"
            />
          )}
        </div>
      </div>
      {toggleOpen ? <div className="toggle-bottom">{children}</div> : undefined}
    </div>
  );
};

export default ToggleSection;
