import React from "react";
import { useHistory } from "react-router-dom";

import Slider from '../components/Slider'
import Button from "../components/Button";

import ProductListing from "./ProductListing";
import HealthySnacksCarousel from "../components/carousel/HealthySnacksCarousel";
import TestimonialsCarousel from "../components/carousel/TestimonialsCarousel";
import HowItWorks from "../components/HowItWorks";


import { categories, products, product } from '../components/carousel/data'
import HomeHSCarousel from '../components/carousel/HomeHSCarousel'
import { Helmet, HelmetProvider } from "react-helmet-async";



const Index = () => {
  const history = useHistory();

  return (
    <>
    <HelmetProvider>
    <Helmet>
          <title>
          Nuts InBox - Healthy Snacks | Premium Quality Nuts & Dried Fruits
          </title>
          <meta
            name="description"
            content="Nutsinbox delivers hand-picked nuts, dried fruit, and gummies all made from premium ingredients and delivered straight to your front door."
          />
        </Helmet>
    </HelmetProvider>


      <Slider />

          {/* --------------------- How it works ------------------ */}

          <HowItWorks />


          {/* --------------------- Categories ------------------ */}

      <div className="product-category-section home-sections">
        <div className="product-category-headline">Products Range</div>
        <div className="product-category-items">
          <a href={`/products?cat=Nuts`} className="category-item">
            <div className="product-category-image nuts-category-item">
              <div className="category-title">nuts</div>
            </div>
          </a>
          <a href={`/products?cat=Dried%20Fruits`} className="category-item">
            <div className="product-category-image driedfruits-category-item">
              <div className="category-title">dried fruits</div>
            </div>
          </a>
          <a href={`/products?cat=Seeds`} className="category-item">
            <div className="product-category-image seeds-category-item">
              <div className="category-title">seeds</div>
            </div>
          </a>
          <a href={`/products?cat=Mixed%20Nuts`} className="category-item">
            <div className="product-category-image mixednuts-category-item">
              <div className="category-title">mixed nuts</div>
            </div>
          </a>
        </div>
      </div>


      {/* --------------------- New Carousel ------------------ */}

      <div className='home-page-carousel'>
        <HomeHSCarousel />
      </div>

      {/* -------------------- First mid section ------------- */}
      <div className="gift-boxes-section home-sections">
        <div className="gb-row">
          <div className="gb-row-img-container zero-right">
            <div className="gb-row-img first-mid-img"></div>
          </div>
          <div className="gb-row-text-container">
            <div className="home-section-nutsinbox-logo"></div>
            <div className="gb-row-title">
              Healthy Snacks On-Hand – All The Time!
            </div>
            <div className="gb-row-description">
              A subscription box keeps your cabinet stocked with nuts, dried
              fruit, and gummies. Keeping nourishing snacks available to you and
              your family at all times helps you easily embrace a healthier
              lifestyle.
              <br />
              <br />
              Made from all-natural ingredients and packed with nutritional
              value, our snacks keep your family healthy without the hassle of
              grocery store lines.
            </div>
            <button
              onClick={() => {
                history.push("/welcome");
              }}
              className="gs-btn gb-btn"
            >
              get started
            </button>
          </div>
        </div>
      </div>

      {/* -------------- Testimonial ---------- */}

      <div className="home-sections">
        <div className="hs-testimonial-background"></div>
        <div className="hs-testimonial">
          <TestimonialsCarousel />
        </div>
        <div className="hs-testimonial-background"></div>
      </div>



      {/* -------------Subscription section ---------- */}

      <div className="home-subscriptions-section">
        <div className="hss-content">
          <div className="select-plan-container">
            <div className="sp-head">
              <div className="sp-head-title">Subscription Packages</div>
              <div className="sp-head-description">
                Mix and match based on taste and dietary restrictions, choose
                your subscription, and receive convenient weekly deliveries to
                keep you and your family healthy.
              </div>
            </div>

            <div className="sp-plans">
              <div className="sp-item">
                <div className="sp-item-title">5 Packs</div>
                <div className="sp-item-img sub-1-img"></div>
                <div className="sp-item-description">
                  <ul>
                    <li>Flexible delivery plans</li>
                    <li>Always free shipping</li>
                  </ul>
                </div>
                <div className="sp-item-interval">
                  <button
                    onClick={() => {
                      history.push("/welcome");
                      return;
                    }}
                    className="hss-gs-btn"
                  >
                    get started
                  </button>
                </div>
              </div>
              <div className="sp-item">
                <div className="sp-item-title">10 Packs</div>
                <div className="sp-item-img sub-2-img"></div>
                <div className="sp-item-description">
                  <ul>
                    <li>Flexible delivery plans</li>
                    <li>Always free shipping</li>
                    <li>$10 off first box</li>
                  </ul>
                </div>
                <div className="sp-item-interval">
                  <button
                    onClick={() => {
                      history.push("/welcome");
                      return;
                    }}
                    className="hss-gs-btn"
                  >
                    get started
                  </button>
                </div>
              </div>
              <div className="sp-item">
                <div className="sp-item-title">15 Packs</div>
                <div className="sp-item-img sub-3-img"></div>
                <div className="sp-item-description">
                  <ul>
                    <li>Flexible delivery plans</li>
                    <li>Always free shipping</li>
                    <li>$20 off first box</li>
                  </ul>
                </div>
                <div className="sp-item-interval">
                  <button
                    onClick={() => {
                      history.push("/welcome");
                      return;
                    }}
                    className="hss-gs-btn"
                  >
                    get started
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* -------------------- GiftBoxes section ------------- */}
      <div className="gift-boxes-section home-sections">
        <div className="gb-row">
          <div className="gb-row-img-container zero-right  gb-second-img-top">
            <div className="hs-gb-row-img gb-first-img"></div>
          </div>
          <div className="gb-row-text-container">
            <div className="gb-row-title">
              Premium Goodies In Beautifully Designed Packages
            </div>
            <div className="gb-row-description">
              Forget the boxed chocolate – give the gift of nuts!
              <br />
              <br />
              Delivered to your doorstep in a beautiful hand-crafted package,
              our nut boxes make the perfect gift for any occasion. Mix and
              match your selections to suit anyone’s taste or dietary
              restrictions and give them the gift of goodies that will nourish
              their body - <em>and</em> taste delicious.
            </div>
            <button
              onClick={() => {
                history.push("/gift-boxes");
              }}
              className="gs-btn gb-btn"
            >
              explore gift boxes
            </button>
          </div>
          <div className="gb-row-img-container zero-right  gb-second-img-bottom">
            <div className="hs-gb-row-img gb-first-img"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
