import React from 'react';

const TestimonialItem = ({testimonial, name}) => {
  return (
    <div>
      <div className="hst-content">
        <div className="hst-testimonial">
          {testimonial}
        </div>
        <div className="hst-name">{name}</div>
      </div>
      <div className="hst-content-mobile">
        <div className="hst-testimonial">
          {testimonial}
        </div>
        <div className="hst-name">{name}</div>
      </div>
    </div>
  );
};

export default TestimonialItem;
