import SwiperCore, { Pagination, Navigation, Thumbs } from 'swiper'

import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/pagination/pagination.min.css'



import "./App.css";
import { BrowserRouter } from "react-router-dom";

import Layout from "./Layout";
import Routes from "./routes/Routes";
import AuthContextProvider from "./state/auth-context";
import ModalContextProvider from "./state/modal-context";
import ProductsContextProvider from "./state/products-context";
import CartContextProvider from "./state/cart-context";
import SearchContextProvider from "./state/search-context";
import ViewCartContextProvider from "./state/view-cart-context";
import MobileNavContextProvider from "./state/mobile-view-context";
import CartLimitContextProvider from "./state/cart-limit-context";
import UserUUIDContextProvider from "./state/user-id-context";
import WelcomeLeadContextProvider from "./state/welcome-lead-context";
import PaymentPlanContextProvider from "./state/payment-plan-context";
// import PasswordContextProvider from './state/password-context'
import OrderDataContextProvider from './state/order-data-context'
import BlogsContextProvider from './state/blogs-context'

import "./App.css";
import "./fontawesome";



// Install Pagination and Navigation modules
SwiperCore.use([Navigation, Pagination, Thumbs])



function App() {
  return (
    // <PasswordContextProvider>
      <AuthContextProvider>
        <UserUUIDContextProvider>
          <SearchContextProvider>
            <WelcomeLeadContextProvider>
              <PaymentPlanContextProvider>
                <ViewCartContextProvider>
                  <MobileNavContextProvider>
                    <CartLimitContextProvider>
                      <ModalContextProvider>
                        <ProductsContextProvider>
                          <CartContextProvider>
                            <OrderDataContextProvider>
                              <BlogsContextProvider>
                              <BrowserRouter>
                                <Layout>
                                  <Routes />
                                </Layout>
                              </BrowserRouter>
                              </BlogsContextProvider>
                            </OrderDataContextProvider>
                          </CartContextProvider>
                        </ProductsContextProvider>
                      </ModalContextProvider>
                    </CartLimitContextProvider>
                  </MobileNavContextProvider>
                </ViewCartContextProvider>
              </PaymentPlanContextProvider>
            </WelcomeLeadContextProvider>
          </SearchContextProvider>
        </UserUUIDContextProvider>
      </AuthContextProvider>
    // </PasswordContextProvider>
  );
}

export default App;
