import { useAuthContext, openUserDropdown } from "../state/auth-context.jsx";
import { useAsyncCall } from "./useAsyncCall";
import { auth, functions, firebase } from "../firebase/config";

export const useAuthenticate = () => {
  const {
    authState: { isUserDropdownOpen },
    authDispatch,
  } = useAuthContext();
  const {
    loading,
    setLoading,
    error,
    setError,
    successMsg,
    setSuccessMsg,
  } = useAsyncCall();

  const signup = async (data) => {
    setLoading(true);
    const { username, email, password } = data;
    try {
      const response = await auth.createUserWithEmailAndPassword(
        email,
        password
      );

      if (!response) {
        setError("Sorry, something went wrong.");
        setLoading(false);
        return;
      }

      await auth.currentUser?.updateProfile({
        displayName: username,
      });

      const onSignup = functions.httpsCallable("onSignup");

      const data = await onSignup({ username });

      setLoading(false);

      return data;
    } catch (err) {
      const { message } = err;

      setError(message);
      setLoading(false);
    }
  };

  const signout = () => {
    auth
      .signOut()
      .then(() => {
        if (isUserDropdownOpen) authDispatch(openUserDropdown(false));
      })
      .catch((err) => alert("Sorry, something went wrong."));
  };

  const signin = async (data) => {
    setLoading(true);
    const { email, password } = data;
    try {
      const response = await auth.signInWithEmailAndPassword(email, password);

      if (!response) {
        setError("Sorry, something went wrong.");
        setLoading(false);
        return;
      }

      setLoading(false);

      return response;
    } catch (err) {
      const { message } = err;

      setError(message);
      setLoading(false);
    }
  };

  const resetPassword = (data) => {
    setLoading(true);
    auth
      .sendPasswordResetEmail(data.email)
      .then(() => {
        setSuccessMsg("Please check your email to reset your password.");
        setLoading(false);
      })
      .catch((err) => {
        const { message } = err;

        setError(message);
        setLoading(false);
      });
  };

  const socialLogin = async (provider) => {
    try {
      setLoading(true);

      const providerObj =
        provider === "google"
          ? new firebase.auth.GoogleAuthProvider()
          : null;

      if(!provider) return

      const response = await auth.signInWithPopup(providerObj)

      if (!response) {
        setError("Sorry, something went wrong.");
        setLoading(false);
        return;
      }


      const onSignup = functions.httpsCallable("onSignup");

      const data = await onSignup({ username: response.user?.displayName });

      setLoading(false);

      return data;

    } catch (err) {
      const { message } = err;

      setError(message);
      setLoading(false);
    }
  };

  return { signup, signin, signout, loading, error, resetPassword, successMsg, socialLogin };
};
